import React, {Fragment} from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import PaymentForm from './paymentForm';
import EditIcon from '@material-ui/icons/Create';
import TrashIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import formatCurrency from '../utils/formatCurrency';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

function PayWith(props){
    console.log(' totals list',props);
    const {methods, store_credit, handleChange, disabled, handleStoreCreditApply, handleUseCreditInputChange, storeCreditInputAmount, 
        setCardEditorOpen, cardEditorOpen, handleAddCard, handleRemoveCard} = props;
    var selected;
    methods.credit_cards.map(m=>m.selected?selected=m.token:'');

    return(
        <Fragment>
            <Typography variant='subtitle1'>Pay With <ButtonBase onClick={setCardEditorOpen.bind(true)}><EditIcon fontSize='small'/></ButtonBase></Typography>
            <FormControl component="fieldset">
                {methods.credit_cards.length>0
                ?
                    <RadioGroup
                    aria-label="Credit Card"
                    name="card"
                    value={selected}
                    onChange={handleChange}
                    >
                        {methods.credit_cards.map(m=>(
                            <FormControlLabel
                            key={m.token}
                            value={m.token}
                            control={<Radio disabled={disabled}/>}
                            label={
                                <Fragment>{m.type} ending in {m.last_four}<br/>
                                    {/*<Typography variant='caption'>
                                    ending in {m.last_four}
                            </Typography>*/}
                                </Fragment>
                            }
                            style={(selected==m.token ? {} : {opacity:'.25'})}
                            />

                        ))}
                    </RadioGroup>
                :
                    <Fragment>No cards yet. <ButtonBase onClick={setCardEditorOpen.bind(true)}><AddIcon fontSize='small'/></ButtonBase></Fragment>
                }
            </FormControl>
            {Number(store_credit) > 0 &&
                <Fragment>
                    <hr style={{border:'1px solid #eee'}} />
                    <Typography variant='caption'>Use Store Credit Balance {formatCurrency(store_credit)}:</Typography>
                    <Grid container>
                        <Grid item xs={10}>
                            <TextField
                            variant='filled'
                            label='Amount To Use'
                            value={storeCreditInputAmount}
                            onChange={e=>handleUseCreditInputChange(e.target.value)}
                            disabled={disabled}
                            type='number'
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            />
                        </Grid>
                        <Grid item xs={2} style={{textAlign:'right',paddingTop:'10px'}}>
                            <Button
                            onClick={handleStoreCreditApply}
                            disabled={disabled}
                            >Apply</Button>
                        </Grid>
                    </Grid>
                </Fragment>
            }

            <Dialog onClose={setCardEditorOpen.bind(false)} aria-labelledby="simple-dialog-title" open={cardEditorOpen}>
                <DialogTitle id="form-dialog-title">Add/Remove Credit Card</DialogTitle>
                <DialogContent>
                    {methods.credit_cards.length>0
                    ?
                        <Fragment>
                            <Typography variant='subtitle1'>Current Cards</Typography>
                            <List disablePadding>
                            {methods.credit_cards.map(m=>(
                                <ListItem key={m.token} divider dense>
                                    <Typography>{m.type+' ending in '+m.last_four}</Typography> <Button onClick={e=>handleRemoveCard(m.token)}><TrashIcon fontSize='small'/></Button>
                                </ListItem>
                            ))}
                            </List>
                        </Fragment>
                    :
                        <Fragment/>
                    }
                    <hr/>
                    <Typography variant='subtitle1'>Add New Card</Typography>
                    <PaymentForm showPromoCode={false} handleSubmit={handleAddCard} submit='Add'/>
                    <hr/>
                    <div style={{textAlign:'right'}}>
                        <Button onClick={e=>setCardEditorOpen(false)}>All Done</Button>
                    </div>
                </DialogContent>
            </Dialog>
        </Fragment>
        : ""
    )
}

export default PayWith;