import React from 'react'
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

function DefaultProductOptions(props){
    console.log('options',props.options);
    const {options, handleSelectChange, handleCheckboxChange, handleTextChange, values} = props;
  
    return(
      <Grid container spacing={16}>     
        {options && options.map((option)=>{

          //only show the enabled values
          if(option.type=='values'){
            option.values = option.values.filter(ov=>ov.enabled);
          }
          var cssDisplay = option.display ? 'inline' : 'none';
          
          return (
            <Grid item xs={12} style={{display: cssDisplay}}>
            {option.type=='values' ?
              <FormControl fullWidth>
                <InputLabel htmlFor={option.name+'_helper'} style={{margin:'5px 0px 0px 5px'}}>{option.name_pretty}</InputLabel>
                <Select
                autoWidth={true}
                value={values[option.name]}                         
                onChange={handleSelectChange}              
                input={<FilledInput name={option.name} id={option.name+'_helper'} />}
                disabled={option.static}
                >
                  {option.values.map(value=><MenuItem value={value.name}>{value.name_pretty}</MenuItem>)}
                </Select>
                {/*<FormHelperText>Some important helper text</FormHelperText>*/ false}
              </FormControl>
            :
              <TextField          
              label={option.name_pretty}            
              margin="normal"
              variant="filled"
              fullWidth              
              name={option.name}
              type={option.name=='amount'?'number':'text'}
              inputProps={(option.name=='amount' ? {min:1} : {})}
              onChange={handleTextChange}              
              value={values[option.name]}
              disabled={option.static}
              />
            }
            <br/>
            </Grid>
          )
        })}
        
      </Grid>
    )
  }

  export default DefaultProductOptions;