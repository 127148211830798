import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';


function ClientEmailForm(props){

    const {handleApply, savedEmail} = props;        
    const [email, setEmail] = useState(savedEmail); 

    function handler(e){        
        e.preventDefault();
        console.log('stopped default',e,email);
        handleApply(email)
        .then(res=>{
        })
    }

    useEffect(() => {
        console.log('propsEmail',savedEmail);
        setEmail(savedEmail);
    },[savedEmail]);

    return (
        
        <form onSubmit={handler}>           
            <Grid container>
                <Grid item xs={10}>
                    <TextField 
                    variant='filled'
                    label="Client Email"
                    name='email'
                    value={email}
                    fullWidth
                    onChange={e=>setEmail(e.target.value)}
                    />
                </Grid>
                <Grid item xs={2} style={{textAlign:'right', paddingTop:'10px'}}>
                    <Button type='submit' disabled={email==savedEmail}>Update</Button> 
                </Grid>
            </Grid>
        </form>
       
    );

}

export default ClientEmailForm;