import React, { Fragment, useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import PrettyLink from '../components/prettyLink';
import CartWidget from '../components/cartWidget';
import Cookies from 'js-cookie';
import Style from 'style-it';
import unserialize from '../utils/Unserialize';


function HeaderNav(props) {
    const { comments } = props;
    const linkStyle = {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '14px',
        color: '#9b9c9d',
        letterSpacing: '.1em',
        lineHeight: '30px',
        marginLeft: '25px',
        textDecoration: 'none',
    }

    //get kiss login status
    const kiss_cookie_name = (process.env.REACT_APP_KISS_COOKIE ? process.env.REACT_APP_KISS_COOKIE : 'cisession');
    var cookie = Cookies.get(kiss_cookie_name);
    if (cookie) {
        var logged_in_kiss = unserialize(cookie).logged_in;
        var kiss_admin = unserialize(cookie).is_admin === '1';
        var kiss_d49 = unserialize(cookie).is_d49 === '1';
        //console.log('cookie contents',unserialize(cookie));
    }

    //console.log('cookie',kiss_cookie_name);


    const [loggedIn, setLoggedIn] = useState(logged_in_kiss ? true : false);
    const [kissAdmin, setKissAdmin] = useState(kiss_admin ? true : false);
    const [kissD49, setKissD49] = useState(kiss_d49 ? true : false);

    //console.log('in headernav. looking for design auth cookie',kiss_cookie_name, cookie, logged_in_kiss);
    const [showProfileMenu, setShowProfileMenu] = useState(false);

    //get store login token
    const [storeToken, setStoreToken] = useState(Cookies.get((process.env.REACT_APP_STORE_USER_TOKEN ? process.env.REACT_APP_STORE_USER_TOKEN : 'store_user_token')) ? true : false);

    return (
        <Fragment>
            <div style={{ textAlign: 'right', marginTop: '10px' }}>
                {loggedIn ?
                    <Style>
                        {`     
                    .profileMenu{
                        float:right;
                        margin-left:15px;
                    }
                    .profileMenu:hover .navMenu{
                        display:block;
                    }         
                   .navMenu{
                        display: none;
                        float: right;
                        width: 115px;
                        margin-right: -50px;
                        margin-bottom: -200px;
                        margin-top: 5px;
                        margin-left: -115px;
                        -moz-box-shadow: 0px 1px 5px #eee;
                        -webkit-box-shadow: 0px 1px 3px #eee;
                        box-shadow: 0px 2px 10px #eee;
                        background: #fff;
                        padding: 5px 10px 10px 0px;
                        position: relative;
                        z-index: 10000;
                        text-align:left;
                    }

                    .navMenu a{
                        text-align:left;
                        float:left;
                    }
                    
                 `}


                        <div>
                            <div className='profileMenu'>
                                <a href={(process.env.REACT_APP_PROFILE_URL ? process.env.REACT_APP_PROFILE_URL : 'http://localhost/mykiss/profile')} ><img src="/images/user_profile.png" alt="" style={{ width: '16px', marginTop: '14px' }}></img></a>

                                <div className='navMenu' >


                                    <PrettyLink href={(process.env.REACT_APP_DASHBOARD_URL ? process.env.REACT_APP_DASHBOARD_URL : 'http://localhost/mykiss')} style={linkStyle}>My Kiss</PrettyLink><br />
                                    {kissAdmin && <Fragment><PrettyLink href={(process.env.REACT_APP_DASHBOARD_URL ? process.env.REACT_APP_DASHBOARD_URL : 'http://localhost/mykiss') + '/admin'} style={linkStyle}>Admin</PrettyLink><br /></Fragment>}
                                    {kissD49 && <Fragment><PrettyLink href={(process.env.REACT_APP_DASHBOARD_URL ? process.env.REACT_APP_DASHBOARD_URL : 'http://localhost/mykiss') + '/designers'} style={linkStyle}>D49</PrettyLink><br /></Fragment>}
                                    <PrettyLink href={(process.env.REACT_APP_LOGOUT_URL ? process.env.REACT_APP_LOGOUT_URL : 'http://localhost/mykiss/login/logout')} style={linkStyle}>Log Out</PrettyLink>
                                </div>

                            </div>

                            <CartWidget />
                        </div>



                    </Style>
                    :

                    <Typography>
                        <PrettyLink href={(process.env.REACT_APP_LOGIN_URL ? process.env.REACT_APP_LOGIN_URL : 'http://localhost/mykiss/login/')} style={linkStyle}>Log In</PrettyLink>
                        <PrettyLink href={(process.env.REACT_APP_SIGNUP_URL ? process.env.REACT_APP_SIGNUP_URL : 'http://localhost/mykiss/auth/login/')} style={{
                            border: '1px solid #cabfab',
                            padding: '8px 8px 8px 8px',
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: '13px',
                            color: '#585858',
                            letterSpacing: '.1em',
                            lineHeight: '30px',
                            marginLeft: '25px',
                            marginRight: (storeToken ? '10px' : '0px')
                        }}>Get Started</PrettyLink>

                        {storeToken &&
                            <CartWidget />
                        }
                    </Typography>
                }
            </div>
            <div style={{ textAlign: 'right', marginTop: '50px', marginBottom: '0px', clear: 'both' }}>
                <Typography>
                    <Style>
                        {`                  
                            .navChild{
                                display: none;
                                width: 180px;
                                margin-right: 170px;
                                margin-top:20px;
                                margin-bottom: -200px;
                                float: right;
                                position: relative;
                                background: #fff;
                                z-index: 3000;
                                opacity: .80;
                                padding: 10px 15px 10px 15px;
                                list-style: none;
                                text-align:left;
                            }
                            .navChild a{
                                color: #9b9c9d;
                                text-align:left;
                                text-decoration:none;
                            }
                            .navChild a:hover{
                                color: #383633;
                            }
                            .navParent:hover .navChild{
                                display:block;
                                
                            }
                            .mainNav:hover{
                                color: #383633;
                            }
                        `}

                        <div>
                            <PrettyLink className='mainNav' href={(process.env.REACT_APP_PROCESS_URL ? process.env.REACT_APP_PROCESS_URL : 'http://localhost/process')} style={linkStyle}>The Process</PrettyLink>
                            <span className='navParent'>
                                <PrettyLink className='mainNav' href="#" style={linkStyle}>The Collection</PrettyLink>
                                <ul className="navChild">
                                    <li><a href={(process.env.REACT_APP_ROOT_URL ? process.env.REACT_APP_ROOT_URL : 'http://localhost/collection') + "heirloom-albums"} className="mont">Heirloom Albums</a></li>
                                    <li><a href={(process.env.REACT_APP_ROOT_URL ? process.env.REACT_APP_ROOT_URL : 'http://localhost/collection') + "press-printed"} className="mont">Press Printed Books</a></li>
                                    <li><a href={(process.env.REACT_APP_ROOT_URL ? process.env.REACT_APP_ROOT_URL : 'http://localhost/collection') + "our-boxes"} className="mont">Boxes</a></li>
                                    {/* <li><a href={(process.env.REACT_APP_PROCESS_URL ? process.env.REACT_APP_PROCESS_URL : 'http://localhost/collection') + "/proof-boxes"} className="mont">Proof Boxes</a></li> */}
                                    {/* <li><a href={(process.env.REACT_APP_COLLECTION_URL ? process.env.REACT_APP_COLLECTION_URL : 'http://localhost/collection') + "/boutique-gift-wrap"} className="mont">Boutique Packaging</a></li> */}
                                    <li><a href={(process.env.REACT_APP_ROOT_URL ? process.env.REACT_APP_ROOT_URL : 'http://localhost/collection') + "styling-accessories"} className="mont">Styling Accessories</a></li>
                                    <li><a href={(process.env.REACT_APP_ROOT_URL ? process.env.REACT_APP_ROOT_URL : 'http://localhost/') + "debossing-guide"} className="mont">Debossing Guide</a></li>
                                    <li><a href={(process.env.REACT_APP_ROOT_URL ? process.env.REACT_APP_ROOT_URL : 'http://localhost/') + "engraving-guide"} className="mont">Engraving Guide</a></li>
                                    <li><a href={(process.env.REACT_APP_ROOT_URL ? process.env.REACT_APP_ROOT_URL : 'http://localhost/') + "design-49"} className="mont">$49 Album Design</a></li>
                                </ul>
                            </span>
                            <PrettyLink className='mainNav' href="https://www.facebook.com/groups/KISSbooks/" style={linkStyle}>The Community</PrettyLink>
                            <PrettyLink className='mainNav' to='/products' style={linkStyle}>Shop</PrettyLink>
                        </div>
                    </Style>
                </Typography>
            </div>
        </Fragment>
    )
}

export default HeaderNav;

