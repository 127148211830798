import React, { Fragment} from 'react'
import TextField from '@material-ui/core/TextField';

function CommentsForm(props){
    const {comments, handleChange} = props;
  
    return(
        <Fragment>
            <TextField label='Special Instructions' multiline rows={3} variant={'filled'} fullWidth onChange={handleChange}>{comments}</TextField>
        </Fragment>
    )
}

export default CommentsForm;