import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import PaymentForm from './paymentForm';

const infoStyle = {
    color: "red",
    fontSize: '13px',
    lineHeight: '21px',
    marginTop: '10px',
    marginBottom: '10px'
};

function CheckoutPayment(props) {
    const { handleSubmit } = props;

    const [countryOptions, setCountryOptions] = useState(false);
    const steps = ['Shipping Address', 'Payment Method', 'Review Order'];
    const [activeStep, setActiveStep] = useState(1);

    return (
        <div>
            <div style={infoStyle} >We have recently enhanced our payment process in order to improve our services. As a result, we kindly request that you provide your billing details as part of the upgraded payment process.</div>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Grid container justify='center'>
                <Grid item sm={6} xs={12}>
                    <Typography variant='h6' gutterBottom>Payment Method</Typography>

                    <Typography>Credit Card</Typography>
                    <PaymentForm showPromoCode={false} handleSubmit={handleSubmit} cancel='Back' submit='Next' />
                </Grid>
            </Grid>

        </div>
    )
}

export default CheckoutPayment;