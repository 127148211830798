import React from 'react'
//import styled from 'styled-components'
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DataSource from '../utils/DataSource';
import Header from '../components/header';
import BreadCrumbs from '../components/breadCrumbs';
import ErrorHandler from '../utils/ErrorHandler';
import SiteLive from '../utils/siteLive';
import ReactPixel from 'react-facebook-pixel';
import Tooltip from "@material-ui/core/Tooltip";

const styles = theme => ({});


class ProductCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: false,
      options:{},
      price:'',
      submitting:false,
      current_image:0,
      show_login:false,
    };
  }

  fetchProducts(){
    return new Promise(function(resolve,reject){
      const cat = String(this.props.match.params.category);
      
      console.log('params',cat);
      //Get the product
      DataSource.get('/products/categories/'+cat)
      .then(function(res){
        console.log('product RES',res.data);
        resolve(res.data);
      }.bind(this)) 
      .catch(function(err){
        console.log('ERR',err);
        ErrorHandler(err,this.props);
        reject(err);
      }.bind(this))
    }.bind(this));
  }



  componentDidMount() {

    //check for site live
    SiteLive();

    //fb pixel
    if(process.env.REACT_APP_FB_PIXEL_ID){
      ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID);
      ReactPixel.pageView(); // For tracking page view
    }

    this.fetchProducts()
    .then(function(res){
      console.log('loaded',res);      

      this.setState({
        products:res,
      });

    }.bind(this));
  }

  render () {
    if(!this.state.products){
      return null;
    }
   

    return (
      <div>
        <Header location={this.props.location}/>
        <BreadCrumbs>{this.state.products[0].category.name_pretty}</BreadCrumbs>
        
        

        <Grid container spacing={16} alignItems='center' alignContent='center' justify='center'>
        {this.state.products.map(p=>(
            
          <Grid item sm={3} xs={6} style={{textAlign:'center'}}>
            <Button onClick={e=>this.props.history.push('/product/'+p.name)} disabled={p.display_sold}>
              <Tooltip
                  placement='bottom'
                  interactive
                  leaveDelay={2}
                  title={p.description_pretty}
                  enterDelay={300}
              >
                <img src={p.images.product_page.length>0 ? p.images.product_page[0].url : '/images/product_default.jpg'} width='100%' style={p.display_sold == true ? {opacity: "0.3"} : {}}/>
              </Tooltip>
            </Button>                                      
            <Typography variant='caption'>{p.name_pretty}</Typography>
            <Typography variant='caption'>{p.display_sold == true ? 'SOLD OUT' : ''}</Typography>
          
          </Grid>                                        
        ))}
        </Grid> 
    
         
      </div>       
    )
  }
}


export default withStyles(styles)(withSnackbar(ProductCategory));