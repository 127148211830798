import React from 'react'
import Typography from '@material-ui/core/Typography';

import Header from '../components/header';
import BreadCrumbs from '../components/breadCrumbs';

class ErrorNotFound extends React.Component {
  componentDidMount() {
    //set page title
    document.title = 'Kiss Shop - Down for Maintenance';
  }

    render () {
      return (
        <div style={{width:'960px'}}>
          <Header location={false}/>
          <BreadCrumbs>Down for Maintenance</BreadCrumbs>
          <Typography variant='h6'>Down for Maintenance</Typography>
          <Typography>Sorry but the Kiss Shop is currently down for maintenance. Please try again later.</Typography>
        </div>
  
       
      )
    }
  }

  export default ErrorNotFound;