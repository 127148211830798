import React, { Fragment, useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import toHuman from '../utils/toHuman';
import ErrorHandler from '../utils/ErrorHandler';
import Switch from '@material-ui/core/Switch';
import PrettyLink from '../components/prettyLink';
import FormControl from '@material-ui/core/FormControl';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';
import ZoomIn from '@material-ui/icons/ZoomIn';
import DataSource from '../utils/DataSource';
import CircularProgress from '@material-ui/core/CircularProgress';

const CancelToken = DataSource.CancelToken;
const source = CancelToken.source();

function BoxCustomize(props){

    const {
        config, 
        fonts,
        positions,
        options_customization,
        type, 
        open, 
        handleClose, 
        handleSubmit,    
        handleImagesChanged,   
        customize_box_errors,
        enqueueSnackbar,
        handleAllowedToAddChange
    } = props;

    const toSnakeCase = str =>
    str &&
    str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(x => x.toLowerCase())
        .join('_');

    console.log('begin config',config);
    

    const defaults = {
        cover_color : false,
        top_customization: false,//bool
        top_method: (config.locations.lid_top.engraving ? 'engraving' : 'debossing'),
        top_type: 'text',
        top_color: 'gold',
        top_file: '',
        top_font: 'arial_bold',
        top_line_1: '',
        top_line_2: '',
        top_position: 'middle_center',
        top_size: '5x1',       
        underside_customization: false,//bool
        underside_method: (config.locations.lid_underside.engraving ? 'engraving' : 'debossing'),
        underside_type: 'logo',        
        underside_color: 'gold',        
        underside_position: 'middle_center',
        underside_version: '',
    }

    //if options are already set, then iterate through and overrule the defaults
    if(props.options){
        console.log('customizer options',props.options);
        Object.keys(props.options).map(key=>{
            defaults[key] = props.options[key];
        });
    }
    console.log('defaults',defaults);

    const [top_customization, set_top_customization] = useState(defaults.top_customization);

    //debossing or engraving
    const [top_method, set_top_method] = useState(defaults.top_method);

    //text or file
    const [top_type, set_top_type] = useState(defaults.top_type);
    const [top_color, set_top_color] = useState(defaults.top_color);
    const [top_file, set_top_file] = useState(defaults.top_file);    
    const [top_font, set_top_font] = useState(defaults.top_font);
    const [top_line_1, set_top_line_1] = useState(defaults.top_line_1);
    const [top_line_2, set_top_line_2] = useState(defaults.top_line_2);
    const [top_position, set_top_position] = useState(defaults.top_position);
    const [top_size, set_top_size] = useState(defaults.top_size);
    const [top_print_file, set_top_print_file] = useState(defaults.top_print_file);    
    
    const [underside_customization, set_underside_customization] = useState(defaults.underside_customization);

    //debossing or engraving
    const [underside_method, set_underside_method] = useState(defaults.underside_method);
 
    //logo 
    const [underside_type, set_underside_type] = useState(defaults.underside_type);
    const [underside_color, set_underside_color] = useState(defaults.underside_color);
    const [underside_position, set_underside_position] = useState(defaults.underside_position); 
    const [underside_file, set_underside_file] = useState(defaults.underside_file);
    const [underside_size, set_underside_size] = useState(defaults.underside_size);
    const [underside_print_file, set_underside_print_file] = useState(defaults.underside_print_file);

    const [lid_zoomed, set_lid_zoomed] = useState(false);
    const [lid_underside_zoomed, set_lid_underside_zoomed] = useState(false);

    const [loading_top_image, set_loading_top_image] = useState(false);
    const [loading_bottom_image, set_loading_bottom_image] = useState(false);

    const [box_image, set_box_image] = useState(false);
    const [box_image_path, set_box_image_path] = useState(false);
    const [box_underside_image, set_box_underside_image] = useState(false);
    const [box_underside_image_path, set_box_underside_image_path] = useState(false);
    const [box_image_size, set_box_image_size] = useState(false);
    const [box_underside_image_size, set_box_underside_image_size] = useState(false);

    const [hash, set_hash] = useState(false);
    const [underside_hash, set_underside_hash] = useState(false);
    const [path, set_path] = useState('');

    //gets set based on what is saved in kiss
    const [underside_path, set_underside_path] = useState(false);
    const [underside_version, set_underside_version] = useState(false);

    const [top_file_input_key, set_top_file_input_key] = useState('');
    
    const [requests, set_requests] = useState(['top','underside']);
    const [top_file_upload_url, set_top_file_upload_url] = useState('/products/upload_file/3000/3000');
    const [underside_file_upload_url, set_underside_file_upload_url] = useState('/products/upload_file/3000/3000');
    
    const [engraving_info_popup, set_engraving_info_popup] = useState(false);
    const [debossing_info_popup, set_debossing_info_popup] = useState(false);

    const [lid_zoomed_image, set_lid_zoomed_image] = useState('');
    const [lid_underside_zoomed_image, set_lid_underside_zoomed_image] = useState('');

    const [show_logo_stamp_popup, set_show_logo_stamp_popup] = useState(false);
    
    

    function toggle_lid_zoom(){
        

        if(!lid_zoomed){
            //reset the image
            set_lid_zoomed_image('');

            //load the image
            zoom('top');
        }

        set_lid_zoomed(!lid_zoomed);
    }

    function toggle_lid_underside_zoom(){
        

        if(!lid_underside_zoomed){
            //reset the image
            set_lid_underside_zoomed_image('');

            //load the image
            zoom('underside');
        }

        set_lid_underside_zoomed(!lid_underside_zoomed);
    }

    function toggle_top(e){
        console.log('toggle top');
        set_top_customization(e.target.checked);
    }

    function toggle_underside(e){

        //is there a logo stamp on file? (found out from kiss during load)
        if(!underside_path && config.locations.lid_underside.debossing){
            
            //stop the toggle
            e.preventDefault();

            //show popup
            set_show_logo_stamp_popup(true);
        }else{
            console.log('toggle underside');       
            set_underside_customization(e.target.checked);
        }
        
    }

    function refreshIcon(location){        
                        
        //add a new 'locaiton' entry to the queue
        console.log('adding location',location,' to requests', requests);
        var copy = requests.slice();
        copy.push(location)
        console.log('adding request', copy);
        set_requests(copy); 
        //set_requests([...requests, location]); 
        //console.log('added', requests);
        
    }

    function zoom(location){
        console.log('in zoom',location);
       
        //define product options to send
        if(location=='top'){
            
            var options = {  
                id: type, //walnut_box, etc     
                enabled :  top_customization,
                location,
                top_type, //text, file
                type : top_method,//debossing, engraving
                color: defaults.cover_color, //for linen boxes
                debossing_foil : top_color, //text color
                font_id : top_font, 
                line_1 : top_line_1, 
                line_2: top_line_2, 
                position : top_position, 
                hash,
                mode : 'zoom'
            }
        }else if(location=='underside'){
            
            var options = {            
                id: type,    
                enabled :  underside_customization,
                location,      
                underside_type : underside_type, 
                type : underside_method,
                color: defaults.cover_color, 
                debossing_foil : underside_color,
                position : underside_position, 
                hash : underside_hash,
                mode : 'zoom'
            }
        }else{
            return false;
        }

        //request
        DataSource.post('/products/refresh_icon/'+type, options)
        .then(function(res){
            console.log('loaded zoomed icon. res',res);
         
            if(location=='top'){
                set_lid_zoomed_image(res.data.image);   
            }else{
                set_lid_underside_zoomed_image(res.data.image); 
            }
        }.bind(this)) 
        .catch(function(err){
            console.log('ERR',err);
            //ErrorHandler(err, {enqueueSnackbar})
            enqueueSnackbar(err.response.data.message);
        }.bind(this))
    }

    function send_icon_request(location){

        console.log('in send_icon_request',location,underside_path);
        //cancel any existing requests
        source.cancel('');


        //define product options to send
        if(location=='top'){
            
            var options = {  
                id: type, //walnut_box, etc     
                enabled :  top_customization,
                location,
                top_type, //text, file
                type : top_method,//debossing, engraving
                color: defaults.cover_color, //for linen boxes
                debossing_foil : top_color, //text color
                font_id : top_font, 
                line_1 : top_line_1, 
                line_2: top_line_2, 
                position : top_position, 
                hash//used if top_type == file
            }

            //show spinner
            set_loading_top_image(true);

            //block the adding of the product to cart while we are loading
            handleAllowedToAddChange(false);

        }else if(location=='underside'){
            
            var options = {            
                id: type,    
                enabled :  underside_customization,
                location,      
                underside_type : underside_type, 
                type : underside_method,
                color: defaults.cover_color, 
                debossing_foil : underside_color,
                position : underside_position, 
                hash : underside_hash,//used if underside_type == file
                path: underside_path,
            }

            //show spinner
            set_loading_bottom_image(true);
        }else{
            return false;
        }

        //add in this so we can cancel requests when needed
        options.cancelToken = source.token;

        

        //request
        DataSource.post('/products/refresh_icon/'+type, options)
        .then(function(res){
            console.log('loaded product icon. res',res);
            //set_box_image(res.data.url);
            console.log('thurs refreshed icon. location',location);
            if(location=='top'){
                set_box_image(res.data.image);                
                set_box_image_path(res.data.icon_path);
                set_loading_top_image(false);
                set_top_print_file(res.data.print_file ? res.data.print_file : path);
                console.log('setting top_print_file',res.data.print_file,'path',path);

                handleAllowedToAddChange(true);
            }else{
                set_box_underside_image(res.data.image);                
                set_box_underside_image_path(res.data.icon_path);
                set_loading_bottom_image(false);
                set_underside_print_file(res.data.print_file ? res.data.print_file : underside_path);
            }
            
        }.bind(this)) 
        .catch(function(err){
            console.log('ERR',err);
            //ErrorHandler(err, {enqueueSnackbar})
            enqueueSnackbar(err.response.data.message);
            handleAllowedToAddChange(true);
        }.bind(this))
    }

    function handle_top_file(e){
        console.log('handle_top_file',e.target.files);

        var formData = new FormData();
        formData.append("file", e.target.files[0]);
        DataSource.post(top_file_upload_url, formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        })
        .then((r)=>{
            console.log('done uploding top file. setting hash to:',r.data.hash,'setting path to:',r.data.path);

            //set the hash and path
            set_hash(r.data.hash);  
            set_path(r.data.path);  

            set_box_image_size(top_size);
                   
        })
        .catch((err)=>{
            console.log('ERR',err);
            //ErrorHandler(e, {enqueueSnackbar})
            enqueueSnackbar(err.response.data.message);

        });

    }

    function handle_underside_file(e){
        console.log('handle_underside_file',e.target.files);

        var formData = new FormData();
        formData.append("file", e.target.files[0]);
        DataSource.post(underside_file_upload_url, formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        })
        .then((r)=>{
            console.log('done uploding underside file. setting underside_hash to:',r.data.hash,'setting underside_path to:',r.data.path);

            //set the hash
            set_underside_hash(r.data.hash); 
            set_underside_path(r.data.path);    
            
            set_box_underside_image_size(underside_size);
        })
        .catch(err=>{
            console.log('ERR',err.response.data.message,props,enqueueSnackbar,{enqueueSnackbar});
            //ErrorHandler(e, {enqueueSnackbar})
            enqueueSnackbar(err.response.data.message);
        });

    }


   

    function toggle_engraving_info_popup(){
        set_engraving_info_popup(!engraving_info_popup);
    }
    
    
    //whenever these options are updated, refresh product icon
    useEffect(() => {
        console.log('refreshing top');
        refreshIcon('top');

        //Check for debossing so we can change the upload url
        console.log('in effect',top_customization,top_type,top_size);
        if(top_method=='debossing' && top_type=='file'){
            if(top_size=='5x1'){
                set_top_file_upload_url('/products/upload_file/1500/300');
            }else if(top_size=='5x2'){
                set_top_file_upload_url('/products/upload_file/1500/600');
            }else if(top_size=='5x4'){
                set_top_file_upload_url('/products/upload_file/1500/1200');
            }
        }
    }, [top_customization, top_method, top_type, top_color, top_file, top_font, top_line_1, top_line_2, top_position, top_size, hash]);

    useEffect(() => {
        console.log('refreshing underside');
        refreshIcon('underside');        

    }, [underside_customization, underside_method, underside_type, underside_color, underside_position, underside_hash]);

    useEffect(() => {
        console.log('refreshing both icons');
        set_requests(['top','underside']); 

    }, [type]);

    //When cover color is updated
    useEffect(() => {
        set_requests(['top','underside']);

    }, [defaults.cover_color]);

    //When top debossing size changes
    useEffect(() => {
        if(box_image_size!=top_size){
            //remove the image (if any)
            set_hash('');  
            set_path('');  
            //this causes the file input to reload
            set_top_file_input_key(Math.random().toString(36));
        }

    }, [top_size]);


    //when file hash for top or bottom changes, or the box top icon
    useEffect(() => {
        var out = {};

        out = {
            icon:{
                path: box_image_path
            },
            other:{}
        };
        
        if(top_customization || underside_customization){            

            if(top_customization){

                out.other.top = {};
                out.other.top[top_method] = top_print_file;                
            }
    
            if(underside_customization){
                out.other.underside = {};
                out.other.underside[underside_method] = underside_print_file;
            }
    
        } 
        

        console.log('handleImagesChanged',{hash, underside_hash, path, underside_path, box_image_path, top_print_file, underside_print_file},'and now out=',out);
        handleImagesChanged(out);

    }, [hash, underside_hash, path, underside_path, box_image_path, top_print_file, underside_print_file]);
    

    //run each time requests changes
    useEffect(()=>{
        
        console.log('in load effect. requests',requests);
        //console.log('top_method',top_method);
        //console.log('top_type',top_type);
        //console.log('config.locations.lid_top.engraving',config.locations.lid_top.engraving);

        var types = ['top','underside'];

        //show loading spinner?
        types.map(type=>{            
            if(requests.filter(r=>r==type).length > 0){
                console.log('thurs requests exist for ',type,requests.filter(r=>r==type).length)
                if(type=='top'){
                    set_loading_top_image(true);
                }else{
                    set_loading_bottom_image(true);
                }
            }
        })

        
        //set the 1-second preview renderer
        var interval_id = setInterval(function(){	            
            console.log('1 second. requests',requests);

            //handle the top and underside seprately
            types.map(type=>{


                //if only one of the type, then send
                if(requests.filter(r=>r==type).length == 1){
                
                    var location = type;
                    console.log('emptying requests, sending the icon request for',location);
                    //reset it so we can start polling again
                    set_requests(requests.filter(the_r=>the_r!=type));
                
                    //send the request
                    send_icon_request(location);
                    
                    
                //remove all but most recent
                }else if(requests.filter(r=>r==type).length > 1){
                    
                    console.log('stop all other '+requests.filter(r=>r==type).length+' requests',requests);
                    
                    //stop all other ones waiting 
                    var other =  requests.filter(the_r=>the_r!=type);
                    other.push(type);
                    set_requests(other);
                }	
            })
            				
        
        },1000);

        

        return ()=>clearInterval(interval_id);
    },[requests]);

    //do once, on page load
    useEffect(()=>{
        //block the adding of the product to cart while we are loading
        handleAllowedToAddChange(false);

        //ask kiss if they have ordered a logo stamp or not         
        DataSource.post('/user/has_debossing_stamp',{})
        .then(function(res){
            console.log('loaded has_debossing_stamp res.data',res.data);

            //have they purchased a logo stamp yet?
            if(res.data.has && res.data.path){
                console.log('has_debossing_stamp, setting underside path',res.data.path);
                set_underside_path(res.data.path);
                set_underside_version(res.data.version);
            }
            
        }.bind(this)) 
        .catch(function(err){
            console.log('ERR',err);
            //ErrorHandler(err, {enqueueSnackbar})
            enqueueSnackbar(err.response.data.message);
        }.bind(this))
    },[]);
    
    
    console.log('config A ',config);

    var debossing_colors = [];
    if (options_customization != undefined) {
        options_customization.map(o => {
            if (o.name = 'lid_top_color') {
                o.values.map((optionValue) => {
                    debossing_colors.push({
                        'name': optionValue.name_pretty
                    });
                });
            }
        });
    }

    if(!fonts){
        return null;
    }else{
        console.log('FOUND');
    }
    
    return (        
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth maxWidth={config.locations.lid_underside ? 'sm' : 'xs'}>
          <DialogTitle id="form-dialog-title">Customize Your Box</DialogTitle>
          <DialogContent> 
              <Grid container spacing={16}>
                {config.locations.lid_top && 
                    <Grid item xs={config.locations.lid_underside ? 6 : 12}>
                       
                        {loading_top_image
                        ?
                            <Grid container alignItems="center" justify="center">
                                <Grid item style={{height:'118px',marginTop:'74px'}}>
                                    <CircularProgress/>
                                </Grid>
                            </Grid>
                        :
                            <Fragment>
                                <div style={{marginTop:'20px', marginBottom:'-50px', marginLeft:'165px', float:'left', position:'relative', display:'block', clear:'both'}}>
                                    <IconButton onClick={e=>toggle_lid_zoom()}>
                                        <ZoomIn />
                                    </IconButton>
                                </div>
                                {box_image &&
                                    <img src={'data:image/jpg;base64,'+box_image/*config.images.top*/} style={{width:'180px',marginLeft:'35px'}} />
                                }
                            </Fragment>
                        }

                        <br/>                        
                        <FormControlLabel
                        control={
                            <Switch
                            checked={top_customization}
                            onChange={e=>toggle_top(e)}
                            value="true"
                            />
                        }
                        label="Lid Top"
                        />
                        <Typography>Add text or a file to the top of the lid. <PrettyLink href='https://support.kiss.us/hc/en-us/articles/360007069497?flash_digest=f785e00b4bb6c86c4ef4126f09f1c7d902bcf180'>More Info</PrettyLink></Typography>

                        {(config.locations.lid_top.engraving && config.locations.lid_top.debossing) &&      
                        
                            <FormControl disabled={!top_customization}>
                                {/*<InputLabel htmlFor="type-helper">Type</InputLabel> */}

                                <RadioGroup
                                    aria-label="Type"
                                    name="type"                                   
                                    value={top_method}
                                    onChange={e=>set_top_method(e.target.value)}
                                    row
                                >
                                    <FormControlLabel
                                    value="engraving"
                                    control={<Radio fontSize='small'/>}
                                    label="Engraving"
                                    labelPlacement="end"
                                    style={{width:'120px'}}
                                    
                                    />
                                    <FormControlLabel
                                    value="debossing"
                                    control={<Radio />}
                                    label="Debossing"
                                    labelPlacement="end"
                                    style={{width:'120px'}}
                                    />                                   
                                </RadioGroup>                      
                            </FormControl>
                        }

                        
                        {(
                            (top_method=='engraving' && config.locations.lid_top.engraving.text && config.locations.lid_top.engraving.file) || 
                            (top_method=='debossing' && config.locations.lid_top.debossing.text && config.locations.lid_top.debossing.file)) &&  
                            <FormControl disabled={!top_customization} style={{display:'block',clear:'both'}}>                               
                              
                                <RadioGroup
                                    aria-label="Type"
                                    name="type"                                   
                                    value={top_type}
                                    onChange={e=>set_top_type(e.target.value)}
                                    row
                                >
                                    <FormControlLabel
                                    value="text"
                                    control={<Radio />}
                                    label="Simple Text"
                                    labelPlacement="end"
                                    style={{width:'120px'}}
                                    />
                                    <FormControlLabel
                                    value="file"
                                    control={<Radio  />}
                                    label="Custom File"
                                    labelPlacement="end"
                                    style={{width:'120px'}}
                                    />                                   
                                </RadioGroup>                      
                            </FormControl>
                        }

                        {(top_method=='debossing' && config.locations.lid_top.debossing) &&                                                                
        
                            <FormControl  variant='filled' margin='normal' fullWidth disabled={!top_customization}>
                                <InputLabel htmlFor="size-helper">Size</InputLabel>
                                <Select
                                    value={top_size}
                                    onChange={e=>set_top_size(e.target.value)}
                                    input={<FilledInput id="size-helper" />}                                                
                                >
                                    <MenuItem value='5x1'>5"x1"</MenuItem>
                                    <MenuItem value='5x2'>5"x2"</MenuItem>
                                    <MenuItem value='5x4'>5"x4"</MenuItem>
                                </Select>                       
                            </FormControl>
                            
                        } 

                        {top_type=='text' &&
                            <Fragment>
                                <TextField                              
                                label="Line 1"                                           
                                fullWidth
                                margin="normal"  
                                variant='filled'  
                                onChange={e=>set_top_line_1(e.target.value)}                            
                                disabled={!top_customization}
                                />
                                <TextField                              
                                label="Line 2"                                           
                                fullWidth
                                margin="normal"    
                                variant='filled'
                                onChange={e=>set_top_line_2(e.target.value)}
                                disabled={!top_customization}
                                />

                                <FormControl fullWidth variant='filled' margin='normal' disabled={!top_customization}>
                                    <InputLabel htmlFor="font-helper">Font</InputLabel>
                                    <Select
                                        value={top_font}
                                        variant='filled'
                                        onChange={e=>set_top_font(e.target.value)}
                                        input={<FilledInput id="font-helper" />}
                                    >
                                        {fonts.map(font=><MenuItem style={{fontFamily:font}} value={toSnakeCase(font)}>{font}</MenuItem>)}
                                    </Select>                       
                                </FormControl>  
                                                   

                                <FormControl fullWidth variant='filled' margin='normal' disabled={!top_customization}>
                                    <InputLabel htmlFor="position-helper">Position</InputLabel>
                                    <Select
                                        value={top_position}                                        
                                        onChange={e=>set_top_position(e.target.value)}
                                        input={<FilledInput id="font-position" />}
                                    >
                                        {positions.map(position=><MenuItem value={position}>{toHuman(position)}</MenuItem>)}
                                    </Select>                       
                                </FormControl>
                               
                                                                             
                            </Fragment>
                        }

                        {top_type=='file' &&
                            <Fragment>
                                
                                
                                

                                <Button 
                                type='file' 
                                
                                containerElement='label'
                                label='Choose File'
                                >
                                    <input type="file" style={{fontSize:'10px'}} onChange={e=>handle_top_file(e)} key={top_file_input_key}/>
                                </Button>


                                {(top_method=='debossing' && config.locations.lid_top.debossing) &&                                    
                                        
                                    <FormControl fullWidth variant='filled' margin='normal'  disabled={!top_customization} >
                                        <InputLabel htmlFor="position-helper">Position</InputLabel>
                                        <Select
                                            value={top_position}
                                            onChange={e=>set_top_position(e.target.value)}
                                            input={<FilledInput id="position-helper" />}
                                        >
                                            {positions.map(position=><MenuItem value={position}>{toHuman(position)}</MenuItem>)}
                                        </Select>                       
                                    </FormControl>

                                } 

                                
                                         
                            </Fragment>
                        }

                        {(top_method=='debossing' && config.locations.lid_top.debossing) &&                                  
                                        
                            <FormControl fullWidth variant='filled' margin='normal' disabled={!top_customization}>
                                <InputLabel htmlFor="color-helper">Color</InputLabel>
                                <Select
                                    value={top_color}
                                    variant='filled'
                                    onChange={e=>set_top_color(e.target.value)}
                                    input={<FilledInput id="color-helper"  />}
                                >
                                    {debossing_colors.map(color=><MenuItem value={toSnakeCase(color.name)}>{color.name}</MenuItem>)}
                                </Select>                       
                            </FormControl>

                        }
                    </Grid>
                   
                }







                {config.locations.lid_underside && 
                    <Grid item xs={config.locations.lid_top ? 6 : 12}>
                                                
                        {loading_bottom_image
                        ?
                            <Grid container alignItems="center" justify="center">
                                <Grid item style={{height:'118px',marginTop:'74px'}}>
                                    <CircularProgress/>
                                </Grid>
                            </Grid>
                        :
                            <Fragment>
                                <div style={{marginTop:'20px', marginBottom:'-50px', marginLeft:'165px', float:'left', position:'relative', display:'block', clear:'both'}}>
                                    <IconButton onClick={e=>toggle_lid_underside_zoom()}>
                                        <ZoomIn />
                                    </IconButton>
                                </div>
                                {box_underside_image &&
                                    <img src={'data:image/jpg;base64,'+box_underside_image} style={{width:'180px',marginLeft:'35px'}} />
                                }
                                </Fragment>
                        }
                        <br/>
                        <FormControlLabel
                        control={
                            <Switch
                            checked={underside_customization}
                            onChange={e=>toggle_underside(e)}
                            value="true"
                            />
                        }
                        label="Lid Underside" 
                        />
                        <Typography>Add your logo to the underside of the lid. <PrettyLink href='https://support.kiss.us/hc/en-us/articles/360007069497?flash_digest=f785e00b4bb6c86c4ef4126f09f1c7d902bcf180'>More Info</PrettyLink></Typography>

                        {(config.locations.lid_underside.engraving && config.locations.lid_underside.debossing) &&      
                        
                            <FormControl disabled={!underside_customization}>
                                {/*<InputLabel htmlFor="type-helper">Type</InputLabel> */}

                                <RadioGroup
                                    aria-label="Type"
                                    name="type"                                   
                                    value={underside_method}
                                    onChange={e=>set_underside_method(e.target.value)}
                                    row
                                >
                                    <FormControlLabel
                                    value="engraving"
                                    control={<Radio  fontSize='small'/>}
                                    label="Engraving"
                                    labelPlacement="end"
                                    style={{width:'140px'}}
                                    
                                    />
                                    <FormControlLabel
                                    value="debossing"
                                    control={<Radio />}
                                    label="Debossing"
                                    labelPlacement="end"
                                    style={{width:'140px'}}
                                    />                                   
                                </RadioGroup>                      
                            </FormControl>
                        }
                       
                        {(underside_type=='logo' && underside_method=='debossing') &&
                            <Fragment>  

                                {(underside_method=='debossing' && config.locations.lid_underside.debossing) &&                                    
                                        
                                    <Fragment>
                                        <FormControl fullWidth variant='filled' margin='normal' disabled={!underside_customization}>
                                            <InputLabel htmlFor="position-helperc">Position</InputLabel>
                                            <Select
                                                value={underside_position}
                                                onChange={e=>set_underside_position(e.target.value)}
                                                input={<FilledInput id="position-helperc" />}
                                            >
                                                {positions.map(position=><MenuItem value={position}>{toHuman(position)}</MenuItem>)}
                                            </Select>                       
                                        </FormControl>                            
                                            
                                        <FormControl fullWidth variant='filled' margin='normal' disabled={!underside_customization}>
                                            <InputLabel htmlFor="color-helperb">Color</InputLabel>
                                            <Select
                                                value={underside_color}
                                                variant='filled'
                                                onChange={e=>set_underside_color(e.target.value)}
                                                input={<FilledInput id="color-helperb"  />}
                                            >
                                                {debossing_colors.map(color=><MenuItem value={toSnakeCase(color.name)}>{color.name}</MenuItem>)}
                                            </Select>                       
                                        </FormControl>
                                    </Fragment>
                                } 

                            </Fragment>
                        }

                        {(underside_type=='logo' && underside_method=='engraving') &&
                            <Fragment>
                               
                                {(underside_method=='engraving' && config.locations.lid_underside.engraving) && 
                                    <Fragment>                                        
                                        <Button 
                                        type='file'                                 
                                        containerElement='label'
                                        label='Choose File'
                                        disabled={!underside_customization}
                                        >
                                            <input type="file"  style={{fontSize:'10px'}} onChange={e=>handle_underside_file(e)}/>
                                        </Button>

                                     

                                    </Fragment>
                                } 
                                
                                         
                            </Fragment>
                        }
                    </Grid>  
                }
                </Grid>  
                <br/>
                {/* top_customization!='none' &&
                    <Grid container justify='flex-end' spacing={16}>
                        <Grid item>                        
                           <Typography>Lid Top {top_type.replace(top_type[0], top_type[0].toUpperCase())+' '+top_method.replace(top_method[0], top_method[0].toUpperCase())}:</Typography>
                        </Grid>             
                        <Grid item>                        
                            <Typography>$79.00</Typography>                     
                        </Grid>
                    </Grid>
                }
                { underside_customization!='none' &&
                    <Grid container justify='flex-end' spacing={16}>
                        <Grid item>                        
                           <Typography>Lid Underside {underside_type.replace(underside_type[0], underside_type[0].toUpperCase())+' '+underside_method.replace(underside_method[0], underside_method[0].toUpperCase())}:</Typography>
                        </Grid>             
                        <Grid item>                        
                            <Typography>$79.00</Typography>                     
                        </Grid>
                    </Grid>
                    */
                }
                

            </DialogContent>                   
            <DialogActions>
                {customize_box_errors.length>0 &&
                <Fragment>
                    {customize_box_errors.map(error=>(
                        <div style={{border:'1px solid #ff0000',padding:'10px',margin:'5px'}}>
                            <Typography style={{color:'#ff0000'}}>{error}</Typography>
                        </div>
                    ))}
                </Fragment>
                }

                <Button onClick={handleClose} color="secondary" type='cancel'>
                Nevermind
                </Button>
                <Button onClick={e=>{
                    var options = {};
                    if(config.locations.lid_top){
                        options = Object.assign({
                            lid_top_customization : (top_customization ? top_method : 'none'),
                            lid_top_font: top_font, 
                            lid_top_line_1: top_line_1,
                            lid_top_line_2: top_line_2, 
                            lid_top_position: top_position,                            
                        }, options);

                        if(options.lid_top_customization=='both' || options.lid_top_customization=='debossing'){
                            options.lid_top_color = top_color;
                            options.lid_top_size = top_size; 
                        }

                        if(config.locations.lid_top.engraving.file || config.locations.lid_top.debossing.file){
                            console.log('adding lid top file to options');
                            options.lid_top_file = path;
                        }
                    }

                    if(config.locations.lid_underside){
                        options = Object.assign({
                            lid_underside_customization: (underside_customization ? underside_method : 'none'),              
                        }, options);

                        if(options.lid_underside_customization=='both' || options.lid_underside_customization=='debossing'){
                            options = Object.assign({                               
                                lid_underside_color: underside_color,                 
                                lid_underside_position: underside_position, 
                                lid_underside_version: underside_version,                   
                            }, options);
                        }

                        if(config.locations.lid_underside.engraving.logo || config.locations.lid_underside.debossing.logo){
                            console.log('adding lid underside file to options');
                            options.lid_underside_file = underside_path;
                        }
                    }

                    handleSubmit(options);
                }
                } type='submit' variant='contained'  style={{marginRight:'20px',marginBottom:'20px'}} disabled={loading_top_image || loading_bottom_image}>
                Apply
                </Button>
          </DialogActions>


            <Dialog open={lid_zoomed} onClose={e=>toggle_lid_zoom()} maxWidth='lg' fullWidth>
                <DialogContent>
                    {lid_zoomed_image
                    ?
                    <img src={'data:image/jpg;base64,'+lid_zoomed_image} style={{width:'100%'}}/>  
                    :
                    <Grid container alignItems="center" justify="center">
                        <Grid item style={{height:'118px',marginTop:'74px'}}>
                            <CircularProgress/>
                        </Grid>
                    </Grid>
                    }
                </DialogContent>
            </Dialog>

            <Dialog open={lid_underside_zoomed} onClose={e=>toggle_lid_underside_zoom()} maxWidth='lg' fullWidth>
                <DialogContent>
                    {lid_underside_zoomed_image
                    ?
                    <img src={'data:image/jpg;base64,'+lid_underside_zoomed_image} style={{width:'100%'}}/>  
                    :
                    <Grid container alignItems="center" justify="center">
                        <Grid item style={{height:'118px',marginTop:'74px'}}>
                            <CircularProgress/>
                        </Grid>
                    </Grid>
                    }
                </DialogContent>
            </Dialog>

            <Dialog open={engraving_info_popup} onClose={e=>toggle_engraving_info_popup()}>
                <DialogContent>
                    <Typography>
                        The text / design to be engraved must be solid black on a transparent background and positioned / sized as you want. 3000x3000 pixel file.
                    </Typography>
                </DialogContent>
            </Dialog>

            <Dialog open={show_logo_stamp_popup} onClose={e=>set_show_logo_stamp_popup(false)}>
                <DialogContent>
                    <Typography>
                    Please order your debossing plate logo first.<br/>Once the debossing plate has been purchased you can add it to your products.
                    </Typography>
                </DialogContent>
            </Dialog>

            

          
        </Dialog>  

        
    );

}


export default BoxCustomize;