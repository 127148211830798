import React, { Fragment } from 'react';

function AddressDisplay(props){
    const {address} = props;
        
    return (
        <Fragment>
            {address.name && 
                <Fragment>
                    {address.name}<br/>
                </Fragment>
            }
            {address.business && 
                <Fragment>
                    {address.business}<br/>
                </Fragment>
            }
            {address.address}<br/>
            {address.address2 && 
                <Fragment>
                    {address.address2}<br/>
                </Fragment>
            }
            {address.address_2 && 
                <Fragment>
                    {address.address_2}<br/>
                </Fragment>
            }
            {address.city+', '+address.state+' '+address.zip}<br/>
            {address.country}
        </Fragment>       
    )
}

export default AddressDisplay;