import React, { Fragment} from 'react'
import { withGoogleMap, withScriptjs, GoogleMap, Marker, Polyline } from "react-google-maps"

const ShippingMap = withScriptjs(withGoogleMap((props) =>
  
  <GoogleMap
  defaultZoom={3}
  defaultCenter={props.coords[0] ? props.coords[0] : props.dest}
  >
    {props.coords.map((coord,index)=><Marker icon={(index==0?{url:'http://assets.kiss.us/store/images/kiss_pin.svg',scaledSize:{width:100,height:50,widthUnit:'px',heightUnit:'px'}}:'')} position={{ lat: coord.lat, lng: coord.lng }} />)}
    {props.coords.map((coord,index)=>
      <Fragment>
      {index>0 && 
          <Polyline path={[{lat: props.coords[index-1].lat, lng: props.coords[index-1].lng}, {lat: props.coords[index].lat, lng: props.coords[index].lng}]}/>
      }    
      </Fragment>
    )}
    <Marker label='Destination' position={{ lat: props.dest.lat, lng: props.dest.lng }} />
  </GoogleMap>
))

export default ShippingMap;