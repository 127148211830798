import DataSource from '../utils/DataSource';

function check(history){
    
   
    //Get the product
    DataSource.get('/config/site_live')
    .then(function(res){
        console.log('site_live RES',res.data.options.live);

        //if not live, then redirect to a page
        if(!res.data.options.live){
            window.location = '/site_down';

        }
        
    }.bind(this)) 
    .catch(function(err){
        console.log('site_live ERR',err);
      
    }.bind(this))   
}


export default check;