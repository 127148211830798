import React from 'react';
import Typography from '@material-ui/core/Typography';

import Entities from 'html-entities'; 
const entities =  new Entities.XmlEntities();

function OrderComments(props){
    const {comments} = props;
    if(!comments){
        return null;
    }
    return (
        <div>            
            <Typography variant='h6'>Comments/Special Requests</Typography>
            <Typography>
                {entities.decode(comments)}
            </Typography>
        </div>       
    )
}

export default OrderComments;