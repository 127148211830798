import React from 'react';
import Typography from '@material-ui/core/Typography';
import Address from '../components/addressDisplay';

function OrderShippingAddress(props){
    const {address} = props;
        
    return (
        <div>            
            <Typography variant='h6'>Shipping Address</Typography>
            <Typography>
                <Address address={address} />
            </Typography>            
        </div>       
    )
}

export default OrderShippingAddress;