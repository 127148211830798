import React, {useState, Fragment, useEffect} from 'react'
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import formatCurrency from '../utils/formatCurrency';
import CircularProgress from '@material-ui/core/CircularProgress';

function TotalsDisplay(props){
    console.log(' totals list',props);
    const {totals, loading, payment_methods} = props;

    const [card, setCard] = useState(false);      

    //run every time the totals or payment_methods changes
    useEffect(() => {
      console.log('running',payment_methods);
      if(payment_methods){
        var cc = payment_methods.credit_cards.find(c=>c.selected);
        if(cc){
            setCard(cc);
            console.log('set card',card);
        }else{
            setCard(false);
        }
      }

    }, [totals,payment_methods]); 

  
    console.log('totals',totals);
    if(loading){
      return (<CircularProgress/>);
    }
  
    return(
        <Table style={{opacity: (props.updatingCart ? '.25' : '1')}} >             
            <TableBody>
              {false/*Object.keys(totals).map(t=>(
                <TableRow key={t}>
                  <TableCell component="th" scope="row">
                    {totals[t].name}
                  </TableCell>
                  <TableCell align="right">{formatCurrency(totals[t].total)}</TableCell>
                </TableRow>
              ))*/}
              {totals.products &&
              <TableRow key='products'>
                <TableCell component="th" scope="row"><Typography variant='caption'>Items</Typography></TableCell>
                <TableCell align="right"><Typography variant='caption'>{formatCurrency(totals.products.total)}</Typography></TableCell>
              </TableRow>
              }
              {(totals.store_credit_additional_bonus && totals.store_credit_additional_bonus.total != 0) &&
                <TableRow key='bonus_kiss_credit'>
                    <TableCell component="th" scope="row"><Typography variant='caption'>Bonus Kiss Credit</Typography></TableCell>
                    <TableCell align="right"><Typography variant='caption'>{formatCurrency(totals.store_credit_additional_bonus.total)}</Typography></TableCell>
                </TableRow>
              }
              {totals.shipping &&
              <TableRow key='shipping'>
                <TableCell component="th" scope="row"><Typography variant='caption'>Shipping{totals.shipping.name ? ' - '+totals.shipping.name : ''}</Typography></TableCell>
                <TableCell align="right"><Typography variant='caption'>{totals.shipping.total ? formatCurrency(totals.shipping.total) : '- - -'}</Typography></TableCell>
              </TableRow>
              }
              {totals.handling &&
              <TableRow key='handling'>
                <TableCell component="th" scope="row"><Typography variant='caption'>Handling{totals.handling.name ? ' - '+totals.handling.name : ''}</Typography></TableCell>
                <TableCell align="right"><Typography variant='caption'>{totals.handling.total ? formatCurrency(totals.handling.total) : '- - -'}</Typography></TableCell>
              </TableRow>
              }
              {totals.sales_tax &&
              <TableRow key='taxes'>
                <TableCell component="th" scope="row"><Typography variant='caption'>Sales Tax{totals.sales_tax.name ? ' - '+totals.sales_tax.name : ''}</Typography></TableCell>
                <TableCell align="right"><Typography variant='caption'>{totals.sales_tax.total ? formatCurrency(totals.sales_tax.total) : '- - -'}</Typography></TableCell>
              </TableRow>
              }
              {/*<TableRow key='subtotal'>
                <TableCell component="th" scope="row"><Typography variant='caption'>Sub-Total</Typography></TableCell>
                <TableCell align="right"><Typography variant='caption'>{formatCurrency(totals.subtotal.total)}</Typography></TableCell>
            </TableRow>*/}
              {totals.discounts &&
                <Fragment>
                  {totals.discounts.length > 0 && 
                  <Fragment>
                  {totals.discounts.map((t)=>(
                    <TableRow key='subtotal'>
                      <TableCell component="th" scope="row"><div style={{margin:'0 0 0 10px'}}><em><Typography variant='caption'>{t.name}</Typography></em></div></TableCell>
                      <TableCell align="right"><Typography variant='caption'>{formatCurrency(t.total*-1)}</Typography></TableCell>
                    </TableRow>
                  ))}
                  </Fragment>  
                  }
                </Fragment>
              }
              {totals.subtotal &&
              <TableRow key='total'>
                <TableCell component="th" scope="row"><Typography variant='subtitle2'>Order Total</Typography></TableCell>
                <TableCell align="right"><Typography variant='subtitle2'>{totals.subtotal ? formatCurrency(totals.subtotal.total) : '- - -'}</Typography></TableCell>
              </TableRow>
              }

              {/* {(totals.payments && totals.payments.length > 0) && 
              <Fragment>
                  {totals.payments.map((t)=>(
                    <Fragment>
                      {(t.name=='Store Credit' && Number(t.total) > 0) &&
                        <TableRow key='subtotal'>
                          <TableCell component="th" scope="row"><div style={{margin:'0 0 0 10px'}}><em><Typography variant='caption'>{t.name}</Typography></em></div></TableCell>
                          <TableCell align="right"><Typography variant='caption'>{formatCurrency(t.total*-1)}</Typography></TableCell>
                        </TableRow>
                      }
                    </Fragment>
                  ))}
              </Fragment>  
              } */}
              {totals?.payments?.length && !totals?.store_credit
                ? totals.payments.reduce((arr, { name, total}) => {
                  const balance = Number(total) || 0
                  if (name === 'Store Credit' && balance > 0) {
                    arr.push(
                      <TableRow key="subtotal">
                        <TableCell component="th" scope="row">
                          <div style={{ margin: "0 0 0 10px" }}>
                            <em>
                              <Typography variant="caption">Store Credit</Typography>
                            </em>
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption">
                            {formatCurrency(balance * -1)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  }
                  return arr
                }, []) :
                (
                  totals?.store_credit?.total &&
                    <TableRow key="subtotal">
                      <TableCell component="th" scope="row">
                        <div style={{ margin: "0 0 0 10px" }}>
                          <em>
                            <Typography variant="caption">Store Credit</Typography>
                          </em>
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="caption">
                          {formatCurrency(Number(totals?.store_credit?.total))}
                        </Typography>
                      </TableCell>
                  </TableRow>
                )  
              }
              {card ?
                  <Fragment>
                      {card.last_four != '0000' &&
                          <TableRow key='total_charged'>
                              <TableCell style={{paddingRight:'0px'}} component="th" scope="row"><Typography variant='subtitle1'>Total charged to {card.type} ending in {card.last_four}</Typography></TableCell>
                              <TableCell align="right"><Typography variant='subtitle1'>{totals.total ? formatCurrency(totals.total.total) : '- - -'}</Typography></TableCell>
                          </TableRow>
                      }
                  </Fragment>
              : totals.total ?
                      <TableRow key='total_charged'>
                          <TableCell style={{paddingRight: '0px'}} component="th" scope="row">
                              <Typography variant='subtitle1'>Amount Due</Typography>
                          </TableCell>
                          <TableCell align="right">
                              <Typography
                                  variant='subtitle1'>{formatCurrency(Number(totals?.total?.total))}</Typography>
                          </TableCell>
                      </TableRow>
                  :
                  <Fragment>
                  </Fragment>

              }
            </TableBody>
        </Table>
    )
}

export default TotalsDisplay;