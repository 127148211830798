import React from 'react';
import Typography from '@material-ui/core/Typography';

function OrderHandlingMethod(props){
    const {method} = props;
        
    return (
        <div>            
            <Typography variant='h6'>Handling Method</Typography>
            <Typography variant='caption'>{method.name_pretty}</Typography>
        </div>       
    )
}

export default OrderHandlingMethod; 