function handler(err,props){
    console.log('in func err',err,props);
    if(err.response){
        if(err.response.status==401){   
            props.enqueueSnackbar("You are not logged in.",{variant:'error'});

            //redirect
            window.location = (process.env.REACT_APP_LOGIN_URL ? process.env.REACT_APP_LOGIN_URL : '');
        }else if(err.response.status==500){
            console.log('err.response',err.response);
            if(err.response.data && err.response.data.description){
                props.enqueueSnackbar("Error: "+err.response.data.description,{variant:'error'});
            }else{
                props.enqueueSnackbar("The system experienced an unexpected error. Please reload your browser and try again.",{variant:'error'});
            }
        }else{
            props.enqueueSnackbar("The system experienced an unknown error. Please reload your browser and try again.",{variant:'error'});
        }
    }else{
        props.enqueueSnackbar("The system experienced an unknown error. Please reload your browser and try again.",{variant:'error'});
    }
}
export default handler; 