import React from 'react'
import Typography from '@material-ui/core/Typography';

import Header from '../components/header';
import BreadCrumbs from '../components/breadCrumbs';

class ErrorNotFound extends React.Component {
  componentDidMount() {
    //set page title
    document.title = 'Kiss Shop - Not Found';
  }

    render () {
      return (
        <div style={{width:'960px'}}>
          <Header location={false}/>
          <BreadCrumbs>Not Found</BreadCrumbs>
          <Typography variant='h6'>Error 404</Typography>
          <Typography>Oops, we couldn't find what you were looking for.</Typography>
        </div>
  
       
      )
    }
  }

  export default ErrorNotFound;