import React, { Fragment} from 'react'
import { withStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DataSource from '../utils/DataSource';
import formatDate from '../utils/formatDate';
import BreadCrumbs from '../components/breadCrumbs';
import ShippingAddress from '../components/orderShippingAddress';
import ShippingMethod from '../components/orderShippingMethod';
import HandlingMethod from '../components/orderHandlingMethod';
import PaymentMethod from '../components/orderPaymentMethod';
import Totals from '../components/totalsDisplay';
import Products from '../components/orderProducts';
import Comments from '../components/orderComments';
import Header from '../components/header';
import SiteLive from '../utils/siteLive';
import ReactPixel from 'react-facebook-pixel';

const styles = theme => ({});

class OrderConfirmation extends React.Component {

  constructor(props) {
    super(props);
    console.log('orderconfirmation props',props);   
  }
  
  state = {
    order:false,     
  };

  componentDidMount() {   

    //check for site live
    SiteLive();

    //fb pixel
    if(process.env.REACT_APP_FB_PIXEL_ID){
      ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID);
      ReactPixel.pageView(); // For tracking page view
    }

    const path_nodes = this.props.location.pathname.split('/');
    const final = path_nodes[path_nodes.length-2];

    //pull from api 
    DataSource.get('/orders/'+this.props.match.params.id+'?full=true')
    .then(function(res){
        console.log('RES',res);
        this.setState({order: res.data});

        //set page title
        document.title = 'Kiss Shop - Order #'+res.data.order_id;

    }.bind(this)) 
    .catch(function(err){
        console.log('ERR',err);
    })
  }
    
  render () {
    const {order} = this.state;
    if(!order){
      return null;
    }
    
    const totals = [];
    order.totals.map(t=>totals[t.type]={total:t.amount});
    if(order.discounts && order.discounts.length > 0){
      totals.discounts = order.discounts.map(d=>{return {total:d.amount, name:d.name}});
      console.log('order.totals.discounts',order.totals.discounts);
    }
    console.log('parsed totals=',totals);

    var freeSampleProducts = [];
    order.products.map((product)=>{
      if (product.type == 'free_sample_swatches') {
        freeSampleProducts.push(product);
      }
    });
    var onlyFreeSampleSwatches = (order.products.length == freeSampleProducts.length)

    return (
        <div>
          
            <Fragment>
              <Header location={this.props.location}/>        
              <BreadCrumbs>Order #{order.order_id}</BreadCrumbs>
            </Fragment>
          
         
          <Typography variant='h6'>Order Confirmation</Typography>
          
          <Grid container>
            <Grid item sm={6} xs={12}>
            <Typography>Ordered {formatDate(order.date_created)} | Order #{order.order_id}</Typography>
            </Grid>
            <Grid item sm={6} xs={12} style={{textAlign:'right'}}>
              <Button component={Link} variant='outlined' to={'/confirmation_print/'+this.props.match.params.id}>View Printable Invoice</Button> <Button component={Link} variant='outlined' to={'/track/'+order.id}>Track Order</Button>
            </Grid>
          </Grid>      

        
          {/* Place for notifications */}
        
          {(order) ? 
            
          <Grid container spacing={32} style={{marginTop:'10px'}}>
            <Grid item xs={6}>
              <ShippingAddress address={order.shipping_address} />
            </Grid>
            <Grid item xs={6}>
              {order.balance_events &&
              <PaymentMethod 
              methods={order.balance_events.filter(be=>be.type=='payment').map(p=>p.meta.type)}
              display={!onlyFreeSampleSwatches}
              />
              }
            </Grid>

            <Grid item xs={6}>
              {order.shipping_method &&
              <ShippingMethod method={order.shipping_method}/>
              }
            </Grid>
            <Grid item xs={6}>
              {order.handling_method &&
              <HandlingMethod method={order.handling_method} />
              }
            </Grid>

            <Grid item xs={12}>
              <Comments comments={order.comments}/>
            </Grid>
            
            <Grid item sm={6} xs={12}>                
              <Products products={order.products}/>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Totals totals={totals} />
            </Grid>      
          </Grid>
          :
          <CircularProgress/>
        } 
      </div>      
    )
  }
}

export default withStyles(styles)(OrderConfirmation);