import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
} from "@material-ui/core";

const DialogFreeSwatches = ({
  showModal,
  handleShowModal,
  handleBuy,
  children,
  title = "Find Something You Like?",
  submitBtnText = "Get All 50+ Swatches Now",
}) => {
  const closeModal = () => handleShowModal(false);
  const handleClick = () => {
    closeModal();
    handleBuy();
  };
  return (
    <Dialog
      open={showModal}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
      onClose={closeModal}
    >
      <DialogContent>
        <Grid container spacing={32}>
          <Grid item xs={12}>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{children}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleClick} color="primary" type="submit">
          {submitBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DialogFreeSwatches;
