import React, { Fragment} from 'react';
import Button from '@material-ui/core/Button';

function MainImage(props){
    const {image} = props;
  
    return(
      <Fragment>    
        <img src={image ? image.url : '/images/product_default.jpg'} width='100%'/>
      </Fragment>
    )
  }
  function Thumbnails(props){
    const {current, handleClick, images} = props;
    console.log('thumbnails. images',images);
    return(
      <Fragment>   
        {images &&
          <Fragment>
            {images.map(function(i, num){
              return <Button style={{width:'75px',marginLeft:'2px',border:(current==num?'1px solid #cabfab':'1px solid #fff')}} onClick={e=>handleClick(num)} ><img src={i.url} width='100%'/></Button>
            })}
          </Fragment>
        }  
      </Fragment>
    )
  }
  function ImageViewer(props){
    const {current, handleClick, images} = props;
  
    return(
      <Fragment>
        <MainImage image={images[current]}/>
        <Thumbnails images={images} current={current} handleClick={handleClick} />
      </Fragment>
  
    )
  }

  export default ImageViewer;