import React from "react";
import Cookies from "js-cookie";
import DataSource from "../utils/DataSource";
import ErrorHandler from "../utils/ErrorHandler";

class UpscopeShare extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (Cookies.get((process.env.REACT_APP_STORE_USER_TOKEN ? process.env.REACT_APP_STORE_USER_TOKEN : 'store_user_token'))) {
            this.fetchUser()
                .then(function (res) {
                    window.Upscope('updateConnection', {
                        // Set the user ID below. If you don't have one, set to undefined.
                        uniqueId: res.id,
                        // Set the user name or email below (e.g. ["John Smith", "john.smith@acme.com"]).
                        identities: [res.email]
                    });
                    console.log("implement upscope");
                }.bind(this))
        }
    }

    fetchUser() {
        return new Promise(function (resolve, reject) {
            DataSource.get('/user/')
                .then(function (res) {
                    resolve(res.data);
                }.bind(this))
                .catch(function (err) {
                    ErrorHandler(err, this.props);
                    reject(err);
                }.bind(this))
        }.bind(this));
    }

    render() {
        return (
            <div>
            </div>
        )
    }
}

export default UpscopeShare;
