import React, { Fragment} from 'react';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

export default function SimpleMediaQuery(props) {
    const {content} = props;

    //is large?
    const matches = useMediaQuery('(min-width:600px)');    
    const style = (matches ? {width:'8.5in','marginLeft':'75px'} : {});
    
    
    return <div style={style}>{content}</div>;
    //<span>{`(min-width:600px) matches: ${matches}`}</span>;
}