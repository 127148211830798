import React, { useState, Fragment, useEffect } from 'react';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FilledInput from '@material-ui/core/FilledInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import { Typography } from '@material-ui/core';
import LargeOption from './LargeOption.js';
import SmallOption from './SmallOption.js';
import companyTheme from '../../theme/companyBaseTheme';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function ProductOptions(props){

    //handlers
    const {handleApply, handleCancel} = props;

    //state hooks, using the props as the defaults and control-values
    const [product, setProduct] = useState(props.product ? props.product : false);

    let stateHooks={
        setProduct,
    }

    function handleSelectChange(event){
        var name = event.target.name;   
        let setter = 'set'+name.charAt(0).toUpperCase() + name.slice(1);
        stateHooks[setter](event.target.value);
    };

    function handleSubmit(e){
        handleApply({
            product,
        })
    }

    useEffect(()=>{
    },[product])


    return (       
    
        <Dialog open={true} maxWidth='sm' fullWidth onClose={handleCancel}>
            <DialogTitle id="form-dialog-title">Product Options</DialogTitle>
            <DialogContent>
                <FormControl variant='filled' margin='normal' fullWidth>
                    <InputLabel>Products</InputLabel>
                    <Select
                    value={product}
                    onChange={handleSelectChange}
                    input={<FilledInput name="product" labelId='product'/>}
                    >                          
                        <MenuItem value='kiss_book'>Heirloom Album</MenuItem>
                        <MenuItem value='press_printed_book'>Press Printed Book</MenuItem>
                    </Select>
                </FormControl><br/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} >
                Cancel
                </Button>
                <Button onClick={handleSubmit} disabled={!product} variant='contained'>
                Apply
                </Button>
            </DialogActions>
        </Dialog>       
       
        );
    
    }
    
    export default ProductOptions;