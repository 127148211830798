import React, { useState, Fragment } from 'react'
//import styled from 'styled-components'
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Select,MenuItem,InputLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import PrettyLink from '../components/prettyLink';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import BoxCustomize from '../components/boxCustomize';
import BreadCrumbs from '../components/breadCrumbs';
import DataSource from '../utils/DataSource';
import ButtonBase from '@material-ui/core/ButtonBase';
import Header from '../components/header';
import box_customize from '../config/box_customizations.json';
import box_fonts from '../config/box_fonts.json';
import box_positions from '../config/box_positions.json';
import formatCurrency from '../utils/formatCurrency';
import SiteLive from '../utils/siteLive';
import ReactPixel from 'react-facebook-pixel';

const box_customize_config = box_customize;
const box_fonts_config = box_fonts;
const box_positions_config = box_positions;
var clamshell_linen_colors = [];

const selected = {
    opacity: 1
}
const deselected = {
    opacity: .25
}

const styles = theme => ({
    dialog_submit: {
        marginRight: '20px',
        marginBottom: '20px'
    }
});



class AlbumAddons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            album_data: false,
            options: {},
            price: '',
            pricing: {},
            isSubmitting: false,
            prompt: false,
            images: [],
            customize_box_errors: [],
            options_customization: {},
            product_type: 'Kiss Book',
            product_name: '',
            disabled_addons_pb: [],
            disabled_addons_lb: [],
            clamshell_linen_colors: [],
        };



    }

    getBoxProduct(box, album_product) {
        console.log(box);
        
        if (box.type == 'walnut_box') {
            var product = {
                name: 'walnut_box',
                name_pretty: 'Walnut Box',
                type: '5c6d98aaa722a76f4357f375',
                quantity: album_product.quantity,
                attached_to: album_product._id,
                images: box.images,
                options: {
                    ...box.options, ...{
                        size: album_product.options.size,
                        sample: album_product.options.is_sample,
                    }
                }
            }
        } else if (box.type == 'pp_box_w') {
            var product = {
                name: 'pp_box',
                name_pretty: 'Presentation Box',
                type: '6545179df0d5f90012fb97de',
                quantity: album_product.quantity,
                attached_to: album_product._id,
                images: box.images,
                options: {
                    ...box.options, ...{
                        size: album_product.options.size,
                        color: 'white',
                    }
                }
            }
        } else if(box.type == 'pp_box_b') {
            var product = {
                name: 'pp_box',
                name_pretty: 'Presentation Box',
                type: '6545179df0d5f90012fb97de',
                quantity: album_product.quantity,
                attached_to: album_product._id,
                images: box.images,
                options: {
                    ...box.options, ...{
                        size: album_product.options.size,
                        color: 'black',
                    }
                }
            }
        } else if(box.type == 'pp_box_g') {
            var product = {
                name: 'pp_box',
                name_pretty: 'Presentation Box',
                type: '6545179df0d5f90012fb97de',
                quantity: album_product.quantity,
                attached_to: album_product._id,
                images: box.images,
                options: {
                    ...box.options, ...{
                        size: album_product.options.size,
                        color: 'grey',
                    }
                }
            }
        } else if (box.type == 'presentation_box') {
            var product = {
                name: 'presentation_box',
                name_pretty: 'Presentation Box',
                type: '5c6d9716a722a76f4357f371',
                quantity: album_product.quantity,
                attached_to: album_product._id,
                images: box.images,
                options: {
                    ...box.options, ...{
                        album_type: album_product.options.cover_type,
                        page_thickness: album_product.options.page_thickness,
                        size: album_product.options.size,
                        spreads: (album_product.options.spreads < 16 ? '10_15_spreads' : '16_40_spreads'),
                        sample: album_product.options.is_sample,
                    }
                }

            }
        }
        else if (box.type == 'linen_box') {
            if (!box.options.lid_top_customization) {
                box.options.lid_top_customization = 'none';
            }
            var product = {
                name: 'linen_box',
                name_pretty: 'Linen Box',
                type: '5c6d9f40a722a76f4357f377',
                quantity: album_product.quantity,
                attached_to: album_product._id,
                images: box.images,
                options: {
                    ...box.options, ...{
                        cover_color: album_product.options.cover_color,
                        page_thickness: album_product.options.page_thickness,
                        sample: album_product.options.is_sample,
                        size: album_product.options.size,
                        spreads: (album_product.options.spreads < 16 ? '10_15_spreads' : '16_40_spreads'),
                    }
                }
            }

            //spreads
            if (album_product.options.spreads <= 18) {
                product.options.spreads = '10_19_spreads';
            } else if (album_product.options.spreads >= 19 && album_product.options.spreads <= 28) {
                product.options.spreads = '19_29_spreads';
            } else if (album_product.options.spreads >= 29 && album_product.options.spreads <= 40) {
                product.options.spreads = '29_40_spreads';
            } 
        }
        else if (box.type == 'linen_presentation_box') {
            if (!box.options.lid_top_customization) {
                box.options.lid_top_customization = 'none';
            }
            var product = {
                name: 'linen_presentation_box',
                name_pretty: 'Linen Presentation Box',
                type: '65451b28e0fc6b00277b6aad',
                quantity: album_product.quantity,
                attached_to: album_product._id,
                images: box.images,
                options: {
                    ...box.options, ...{
                        cover_color: album_product.options.cover_color,
                        page_thickness: album_product.options.page_thickness,
                        sample: album_product.options.is_sample,
                        size: album_product.options.size,
                        spreads: (album_product.options.spreads < 21 ? '10_20_spreads' : '21_40_spreads'),
                    }
                }
            }

            //spreads
            if (album_product.options.spreads <= 18) {
                product.options.spreads = '10_19_spreads';
            } else if (album_product.options.spreads >= 19 && album_product.options.spreads <= 28) {
                product.options.spreads = '19_29_spreads';
            } else if (album_product.options.spreads >= 29 && album_product.options.spreads <= 40) {
                product.options.spreads = '29_40_spreads';
            } 
        }
        else if (box.type == 'clamshell_linen_box') {
            if (!box.options.lid_top_customization) {
                box.options.lid_top_customization = 'none';
            }
            var product = {
                name: 'clamshell_linen_box',
                name_pretty: 'Clamshell Linen Box',
                type: '654530e6f0d5f90012fbdacd',
                quantity: album_product.quantity,
                attached_to: album_product._id,
                images: box.images,
                options: {
                    ...box.options, ...{
                        page_thickness: album_product.options.page_thickness,
                        sample: album_product.options.is_sample,
                        size: album_product.options.size,
                        spreads: (album_product.options.spreads < 21 ? '10_20_spreads' : '21_40_spreads'),
                    },
                    cover_color: box.options.cover_color || album_product.options.cover_color,
                }
            }

            //spreads
            if (album_product.options.spreads <= 18) {
                product.options.spreads = '10_19_spreads';
            } else if (album_product.options.spreads >= 19 && album_product.options.spreads <= 28) {
                product.options.spreads = '19_29_spreads';
            } else if (album_product.options.spreads >= 29 && album_product.options.spreads <= 40) {
                product.options.spreads = '29_40_spreads';
            } 
        }
        return product;
    }

    handleSubmit() {
        var to_add = [];
        //loop through and see if we have any addons
        this.state.album_products.map(album_product => {
            album_product.addons.map(addon => {
                if (addon.type == 'slipcover') {
                    var product = {
                        name: 'slip_cover',
                        name_pretty: 'Slip Cover',
                        type: '5c6da908a722a76f4357f383',
                        quantity: album_product.quantity,
                        attached_to: album_product._id,
                        options: {
                            size: album_product.options.size,
                        }
                    }
                } else if (addon.type == 'eucalyptus') {
                    var product = {
                        name: 'eucalyptus',
                        name_pretty: 'Eucalyptus Gift Wrap',
                        type: '5c33c25db0a815626785d734',
                        quantity: album_product.quantity,
                        attached_to: album_product._id,
                        options: {
                            collection: 'eucalyptus',
                            card_text: (addon.state.checked ? addon.options.card_text : ''),
                            card: (addon.state.checked ? 'thank_you' : 'none'),
                        }
                    }
                } else if (addon.type == 'polkadot') {
                    var product = {
                        name: 'polkadot',
                        name_pretty: 'Polka Dot Gift Wrap',
                        type: '5c33c25db0a815626785d734',
                        quantity: album_product.quantity,
                        attached_to: album_product._id,
                        options: {
                            collection: 'polkadot',
                            card_text: (addon.state.checked ? addon.options.card_text : ''),
                            card: (addon.state.checked ? 'thank_you' : 'none'),
                        }
                    }
                } else if (addon.type == 'classic') {
                    var product = {
                        name: 'classic',
                        name_pretty: 'Classic Gift Wrap',
                        type: '5c33c25db0a815626785d734',
                        quantity: album_product.quantity,
                        attached_to: album_product._id,
                        options: {
                            collection: 'classic',
                            card_text: (addon.state.checked ? addon.options.card_text : ''),
                            card: (addon.state.checked ? 'thank_you' : 'none'),
                        }
                    }
                } else if (addon.type == 'green_holiday') {
                    // var product = {
                    //     name: 'green_holiday',
                    //     name_pretty: 'Green Holiday Gift Wrap',
                    //     type: '5c33c25db0a815626785d734',
                    //     quantity: album_product.quantity,
                    //     attached_to: album_product._id,
                    //     options: {
                    //         collection: 'green_holiday',
                    //         card_text: (addon.state.checked ? addon.options.card_text : ''),
                    //         card: (addon.state.checked ? 'thank_you' : 'none'),
                    //     }
                    // }
                } else if (addon.type == 'red_and_white_holiday') {
                    // var product = {
                    //     name: 'red_and_white_holiday',
                    //     name_pretty: 'Red And White Holiday Gift Wrap',
                    //     type: '5c33c25db0a815626785d734',
                    //     quantity: album_product.quantity,
                    //     attached_to: album_product._id,
                    //     options: {
                    //         collection: 'red_and_white_holiday',
                    //         card_text: (addon.state.checked ? addon.options.card_text : ''),
                    //         card: (addon.state.checked ? 'thank_you' : 'none'),
                    //     }
                    // }
                }

                if (product) {
                    //add to list
                    product.name = product.name_pretty;
                    if (!product.images) {
                        product.images = {};
                    }
                    to_add.push(product);
                } else {
                    //
                    console.log('error! could not find product', album_product);
                    this.props.enqueueSnackbar('Oops! There has been an error with adding to your cart. Please reload your browser and retry.', { variant: 'error' });
                    return false;
                }
            })

            album_product.boxes.map(box => {

                var product = this.getBoxProduct(box, album_product);
                console.log('product', product, box, album_product);

                if (product) {

                    //add to list
                    product.name = product.name_pretty;
                    if (!product.images) {
                        product.images = {};
                    }

                    to_add.push(product);
                } else {
                    //
                    console.log('error! could not find product', album_product);
                    this.props.enqueueSnackbar('Oops! There has been an error with adding to your cart. Please reload your browser and retry.', { variant: 'error' });
                    return false;
                }
            })
        })


        this.setState({ isSubmitting: true });

        //add each to cart
        //   var added_count = 0;
        //   new Promise(function(resolve,reject){
        //       to_add.map(p=>{
        //           DataSource.post('/cart/products/', p)
        //           .then(function(res){
        //
        //               //check the added amount
        //               if(++added_count == to_add.length){
        //                   resolve();
        //               }
        //
        //           }.bind(this))
        //           .catch(function(err){
        //               console.log('ERR',err);
        //               reject(err);
        //           }.bind(this))
        //       })
        //   })
        //   .then(res=>{
        //
        //       //Prompt whether to return to dashboard or check out
        //       this.setState({'prompt':true});
        //
        //
        //   })
        //   .catch(err=>this.props.enqueueSnackbar('Could not connect to server - check connection or login status',{variant:'error'}));
        //
        // }



        DataSource.post('/cart/products/addons', { products: to_add })
            .then(function (res) {
                //Prompt whether to return to dashboard or check out
                this.setState({ 'prompt': true });
            }.bind(this))
            .catch(function (err) {
                console.log('ERR', err);
                this.props.enqueueSnackbar('Could not connect to server - check connection or login status', { variant: 'error' })
            }.bind(this))
    }

    handleAddonClick(num, type) {
        var copies = this.state.album_products.slice();
        console.log(copies);

        if (type == 'slipcover') {
            if (copies[num].addons.filter(item => item.type == 'slipcover').length) {
                //remove it
                copies[num].addons = copies[num].addons.filter(item => item.type != 'slipcover');
            } else {
                //add it
                copies[num].addons.push({ type, state: {}, options: {} });
            }
            //update state
            this.setState({ album_products: copies });
        } else {
            //must be wrapping paper, only one can be applied
            if (copies[num].addons.filter(item => item.type == type).length) {
                //remove it
                copies[num].addons = copies[num].addons.filter(item => item.type != type);
            } else {
                //deselect other wrapping
                copies[num].addons = copies[num].addons.filter(item => item.type == 'slipcover');

                //add it
                copies[num].addons.push({ type, state: {}, options: {} });
            }

            console.log(copies);
            //update state
            this.setState({ album_products: copies });
        }

    }

    

    handleBoxClick(num, type) {
        var copies = this.state.album_products.slice();

        //must be box, only one can be applied
        if (copies[num].boxes.filter(item => item.type == type).length) {
            //empty
            copies[num].boxes = [];
        } else {
            //empty
            copies[num].boxes = [];

            console.log( type );

            if( type == 'pp_box_w' || type == 'pp_box_b' || type == 'pp_box_g' ) {
                if( type == 'pp_box_w' ) {
                    copies[num].boxes.push({ type, state: {}, options: {'color': 'white'} });
                }
                if( type == 'pp_box_b' ) {
                    copies[num].boxes.push({ type, state: {}, options: {'color': 'black'} });
                }
                if( type == 'pp_box_g' ) {
                    copies[num].boxes.push({ type, state: {}, options: {'color': 'grey'} });
                }
            } else {
                //add it
                copies[num].boxes.push({ type, state: {}, options: {} });
            }
            
        }
        //update state
        this.setState({ album_products: copies });

    }

    handleClamshellLinenBoxCoverColorChange(num, e) {

        console.log(this.state.album_products);
        
        var copies = this.state.album_products.slice();
        copies[num].boxes[0].options.cover_color = e.target.value;
        this.setState({ album_products: copies });

    }

    handleThankYouCardClick(num) {
        var copies = this.state.album_products.slice();
        copies[num].addons.map(item => {
            if (item.type == 'eucalyptus' || item.type == 'polkadot' || item.type == 'classic' || item.type == 'green_holiday' || item.type == 'red_and_white_holiday') {
                item.state.checked = !item.state.checked;
            }
        });
        //update state
        this.setState({ album_products: copies });

    }

    handleThankYouCardInput(num, e) {


        let the_text = e.target.value;

        //keep at 165 characters max
        if (the_text.length >= 165) {
            //set to minimum length       
            the_text = the_text.substring(0, 165);
        }

        var copies = this.state.album_products.slice();
        copies[num].addons = copies[num].addons.map(item => {
            if (item.type == 'eucalyptus' || item.type == 'polkadot' || item.type == 'classic' || item.type == 'green_holiday' || item.type == 'red_and_white_holiday') {
                item.options.card_text = the_text;
                item.options.card = 'thank_you';
            }
            return item;
        });
        //update state
        this.setState({ album_products: copies });
    }

    handleBoxCustomizeClick(num, e) {
        var copies = this.state.album_products.slice();
        var options_customization = [];
        switch (copies[num].boxes[0].type) {
            case 'presentation_box': 
                options_customization = copies[num].options_customization.presentation_box;
                break;
            case 'linen_box': 
                options_customization = copies[num].options_customization.linen_box;
                break;
            case 'walnut_box': 
                options_customization = copies[num].options_customization.walnut_box;
                break;
            case 'linen_presentation_box': 
                options_customization = copies[num].options_customization.linen_presentation_box;
                break;
            case 'clamshell_linen_box': 
                options_customization = copies[num].options_customization.clamshell_linen_box;
                break;
        }


        //show dialog
        this.setState({ customize_box_type: copies[num].boxes[0].type });
        this.setState({ customize_box_options: { cover_color: copies[num].options.cover_color } });
        this.setState({ customize_box_copy_num: num });
        this.setState({ customize_box_open: true });
        this.setState({ options_customization: options_customization });


    }



    handleBoxCustomizeClose() {
        this.setState({ customize_box_open: false });
    }

    handleBoxCustomizeSubmit(vals) {

        //clone state
        var copies = this.state.album_products.slice();

        var album_product = copies[this.state.customize_box_copy_num];

        //get the box we're applying to
        var box = album_product.boxes[0];

        //add the options received from the dialog
        box.options = vals;

        //add any images saved
        box.images = this.state.images;

        //reset this temp storage of images
        this.setState({ images: {} });

        //get the fill save-ready model
        var box_product = this.getBoxProduct(box, album_product);

        //update price
        DataSource.post('/products/calculate_product/' + box_product.type, box_product.options)
            .then(function (res) {

                //update the pricing model
                album_product.pricing[box_product.name] = res.data;

                //update state with new pricing and close dialog
                this.setState({
                    album_products: copies,
                    customize_box_open: false,
                });


            }.bind(this))
            .catch(function (err) {
                console.log('ERR', err);

            }.bind(this));

    }

    handleImagesChanged(images) {
        this.setState({ images });
    }

    handleSkip() {
        //this.props.history.push("/mykiss/home/?message=Successfully Added To Cart", {});
        //Prompt whether to return to dashboard or check out
        this.setState({ 'prompt': true });
    }

    checkForDisabled(item, addon) {
        if (addon == 'presentation_box') {
            return this.state.disabled_addons_pb ? this.state.disabled_addons_pb.includes(String(item.options.size)) : false

        }
        if (addon == 'linen_box') {
            return this.state.disabled_addons_lb ? this.state.disabled_addons_lb.includes(String(item.options.size)) : false

        }

        if (addon == 'linen_presentation_box') {
            return this.state.disabled_addons_lb ? this.state.disabled_addons_lb.includes(String(item.options.size)) : false

        }

        if (addon == 'clamshell_linen_box') {
            return this.state.disabled_addons_lb ? this.state.disabled_addons_lb.includes(String(item.options.size)) : false

        }

        if (addon == 'pp_box') {
            return this.state.disabled_addons_lb ? this.state.disabled_addons_lb.includes(String(item.options.size)) : false

        }
    }

    componentDidMount() {
        //set page title
        document.title = 'Kiss Shop - Album Addons';

        //check for site live
        SiteLive();

        //fb pixel
        if (process.env.REACT_APP_FB_PIXEL_ID) {
            ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID);
            ReactPixel.pageView(); // For tracking page view
        }

        var updated = this.props.history.location.state.added_products.slice();
        var product_type = this.props.history.location.state.product_type;
        if (product_type != undefined) {
            this.setState({ product_type });
        }
        var product_name = this.props.history.location.state.product_name;
        if (product_name != undefined) {
            this.setState({ product_name });
        }

        updated = updated.map(u => {
            u.addons = [];
            u.boxes = [];
            u.pricing = {
                'slipcover': {},
                'linen_box': {},
                'walnut_box': {},
                'presentation_box': {},
                'eucalyptus': {},
                'polkadot': {},
                'classic': {},
                'green_holiday': {},
                'red_and_white_holiday': {},
                'pp_box': {}
            };
            u.options_customization = {
                'slipcover': {},
                'linen_box': [],
                'linen_presentation_box': [],
                'clamshell_linen_box': [],
                'walnut_box': [],
                'presentation_box': [],
                'eucalyptus': {},
                'polkadot': {},
                'classic': {},
                'green_holiday': {},
                'red_and_white_holiday': {},
                'pp_box': {}
            };

            return u;
        });
        this.setState({ album_products: updated });

        //get prices for everything
        updated.map((book_copy, num) => {

            //slipcover
            DataSource.post('/products/calculate_product/5c6da908a722a76f4357f383', { size: book_copy.options.size })
                .then(function (res) {
                    var clone = this.state.album_products.slice();

                    clone[num].pricing.slipcover = res.data;
                    this.setState({ album_products: clone });

                }.bind(this))
                .catch(function (err) {
                    console.log('ERR', err);

                }.bind(this))

            //linen_box
            // DataSource.post('/products/calculate_product/5c6d9f40a722a76f4357f377', this.getBoxProduct({ type: 'linen_box', options: { lid_top_customization: 'none', lid_type_size: '5x1' } }, book_copy).options)
            //     .then(function (res) {
            //         var clone = this.state.album_products.slice();

            //         clone[num].pricing.linen_box = res.data;
            //         this.setState({ album_products: clone });

            //     }.bind(this))
            //     .catch(function (err) {
            //         console.log('ERR', err);

            //     }.bind(this))

            // linen presentation box
            DataSource.post('/products/calculate_product/65451b28e0fc6b00277b6aad', this.getBoxProduct({ type: 'linen_presentation_box', options: { lid_top_customization: 'none', lid_type_size: '5x1', cover_color: 'oatmeal' } }, book_copy).options)
                .then(function (res) {
                    var clone = this.state.album_products.slice();

                    clone[num].pricing.linen_presentation_box = res.data;
                    this.setState({ album_products: clone });

                }.bind(this))
                .catch(function (err) {
                    console.log('ERR', err);

                }.bind(this))

            // Clamshell Linen Box
            DataSource.post('/products/calculate_product/654530e6f0d5f90012fbdacd', this.getBoxProduct({ type: 'clamshell_linen_box', options: { lid_top_customization: 'none', lid_type_size: '5x1' } }, book_copy).options)
                .then(function (res) {
                    var clone = this.state.album_products.slice();

                    clone[num].pricing.clamshell_linen_box = res.data;
                    this.setState({ album_products: clone });

                }.bind(this))
                .catch(function (err) {
                    console.log('ERR', err);

                }.bind(this))

            //walnut_box
            DataSource.post('/products/calculate_product/5c6d98aaa722a76f4357f375', this.getBoxProduct({type:'walnut_box'}, book_copy).options)
            .then(function(res){
                var clone = this.state.album_products.slice();

                clone[num].pricing.walnut_box = res.data;
                this.setState({album_products: clone});

            }.bind(this)) 
            .catch(function(err){
                console.log('ERR',err);

            }.bind(this))

            //presentation_box
            DataSource.post('/products/calculate_product/5c6d9716a722a76f4357f371', this.getBoxProduct({ type: 'presentation_box' }, book_copy).options)
                .then(function (res) {
                    var clone = this.state.album_products.slice();

                    clone[num].pricing.presentation_box = res.data;
                    this.setState({ album_products: clone });

                }.bind(this))
                .catch(function (err) {
                    console.log('ERR', err);

                }.bind(this))

            //pp_box
            DataSource.post('/products/calculate_product/6545179df0d5f90012fb97de', this.getBoxProduct({type:'pp_box_w'}, book_copy).options)
            .then(function(res){
                var clone = this.state.album_products.slice();

                clone[num].pricing.pp_box = res.data;
                this.setState({album_products: clone});

            }.bind(this)) 
            .catch(function(err){
                console.log('ERR',err);

            }.bind(this))

            //eucalyptus gift wrap
            // DataSource.post('/products/calculate_product/5c33c25db0a815626785d734', {collection:'eucalyptus'})
            // .then(function(res){
            //
            //     var clone = this.state.album_products.slice();
            //     clone[num].pricing.eucalyptus = res.data;
            //     this.setState({album_products: clone});
            //
            // }.bind(this))
            // .catch(function(err){
            //     console.log('ERR',err);
            //
            // }.bind(this))

            //polkadot gift wrap
            DataSource.post('/products/calculate_product/5c33c25db0a815626785d734', { collection: 'polkadot' })
                .then(function (res) {

                    var clone = this.state.album_products.slice();
                    clone[num].pricing.polkadot = res.data;
                    this.setState({ album_products: clone });

                }.bind(this))
                .catch(function (err) {
                    console.log('ERR', err);

                }.bind(this))

            //classic gift wrap
            DataSource.post('/products/calculate_product/5c33c25db0a815626785d734', { collection: 'classic' })
                .then(function (res) {

                    var clone = this.state.album_products.slice();
                    clone[num].pricing.classic = res.data;
                    this.setState({ album_products: clone });

                }.bind(this))
                .catch(function (err) {
                    console.log('ERR', err);

                }.bind(this))

            //green holiday gift wrap
            // DataSource.post('/products/calculate_product/5c33c25db0a815626785d734', { collection: 'green_holiday' })
            //     .then(function (res) {

            //         var clone = this.state.album_products.slice();
            //         clone[num].pricing.green_holiday = res.data;
            //         this.setState({ album_products: clone });

            //     }.bind(this))
            //     .catch(function (err) {
            //         console.log('ERR', err);

            //     }.bind(this))

            // Red and White Holiday Gift Wrap
            // DataSource.post('/products/calculate_product/5c33c25db0a815626785d734', { collection: 'red_and_white_holiday' })
            //     .then(function (res) {

            //         var clone = this.state.album_products.slice();
            //         clone[num].pricing.red_and_white_holiday = res.data;
            //         this.setState({ album_products: clone });

            //     }.bind(this))
            //     .catch(function (err) {
            //         console.log('ERR', err);

            //     }.bind(this))

            DataSource.get('/products/presentation_box')
                .then(function (res) {
                    var clone = this.state.album_products.slice();
                    var options_customization_match = box_customize_config['presentation_box'].display_options_customization_popup;
                    const options_to_show_customization = res.data.options.filter(o => options_customization_match.includes(o.name));
                    options_to_show_customization.map((option) => {
                        if (option.type == 'values') {
                            var oValues = option.values.filter((optionValue) => {
                                return optionValue.enabled;
                            });
                            option.values = oValues;
                        }
                    });
                    clone[num].options_customization.presentation_box = options_to_show_customization;
                    const d_option = res.data.options.find(i => i.name === 'size')
                    let disabled = d_option ? d_option.values.filter(s => s.enabled != true) : []

                    if (res.data?.blockedCombinations?.length) {
                        clone.map(album => {
                            const block = res.data.blockedCombinations.find(b => b.page_thickness === album?.options?.page_thickness && b.size === album?.options?.size)
                            if (block) {
                                const size_option = res.data.options.find(i => i.name === 'size')
                                let disabled_sizes = size_option ? size_option.values.filter(s => s.name === block.size) : []
                                disabled = [...disabled, ...disabled_sizes]
                            }
                        })
                    }

                    if (disabled.length > 0) {
                        this.setState({ disabled_addons_pb: [...disabled.map(i => i.name)] });
                    }
                    this.setState({ album_products: clone });

                }.bind(this))
                .catch(function (err) {
                }.bind(this))

            // DataSource.get('/products/linen_box')
            //     .then(function (res) {
            //         var clone = this.state.album_products.slice();
            //         var options_customization_match = box_customize_config['linen_box'].display_options_customization_popup;
            //         const options_to_show_customization = res.data.options.filter(o => options_customization_match.includes(o.name));
            //         options_to_show_customization.map((option) => {
            //             if (option.type == 'values') {
            //                 var oValues = option.values.filter((optionValue) => {
            //                     return optionValue.enabled;
            //                 });
            //                 option.values = oValues;
            //             }
            //         });
            //         clone[num].options_customization.linen_box = options_to_show_customization;
            //         const d_option = res.data.options.find(i => i.name === 'size')
            //         const disabled = d_option ? d_option.values.filter(s => s.enabled != true) : []
            //         if (disabled.length > 0) {
            //             this.setState({ disabled_addons_lb: [...disabled.map(i => i.name)] });
            //         }
            //         this.setState({ album_products: clone });

            //     }.bind(this))
            //     .catch(function (err) {
            //     }.bind(this))

            DataSource.get('/products/linen_presentation_box')
                .then(function (res) {
                    var clone = this.state.album_products.slice();
                    var options_customization_match = box_customize_config['linen_presentation_box'].display_options_customization_popup;
                    const options_to_show_customization = res.data.options.filter(o => options_customization_match.includes(o.name));
                    options_to_show_customization.map((option) => {
                        if (option.type == 'values') {
                            var oValues = option.values.filter((optionValue) => {
                                return optionValue.enabled;
                            });
                            option.values = oValues;
                        }
                    });
                    clone[num].options_customization.linen_presentation_box = options_to_show_customization;
                    const d_option = res.data.options.find(i => i.name === 'size')
                    const disabled = d_option ? d_option.values.filter(s => s.enabled != true) : []
                    if (disabled.length > 0) {
                        this.setState({ disabled_addons_lb: [...disabled.map(i => i.name)] });
                    }
                    this.setState({ album_products: clone });

                }.bind(this))
                .catch(function (err) {
                }.bind(this))

            DataSource.get('/products/clamshell_linen_box')
                .then(function (res) {

                    let values = [];
                    res.data.options.map((option) => {
                        if( option.name == 'cover_color' ) {
                            values = option.values.filter((optionValue) => {
                                if( optionValue.enabled ) {
                                    return optionValue;
                                }
                            });
                        }
                    });

                    clamshell_linen_colors = values.map((item) => {
                        return { value: item.name, label: item.name_pretty }
                    });

                    this.setState({ clamshell_linen_colors: clamshell_linen_colors });

                    var clone = this.state.album_products.slice();
                    var options_customization_match = box_customize_config['clamshell_linen_box'].display_options_customization_popup;
                    const options_to_show_customization = res.data.options.filter(o => options_customization_match.includes(o.name));
                    options_to_show_customization.map((option) => {
                        if (option.type == 'values') {
                            var oValues = option.values.filter((optionValue) => {
                                return optionValue.enabled;
                            });
                            option.values = oValues;
                        }
                    });
                    clone[num].options_customization.clamshell_linen_box = options_to_show_customization;
                    const d_option = res.data.options.find(i => i.name === 'size')
                    const disabled = d_option ? d_option.values.filter(s => s.enabled != true) : []
                    if (disabled.length > 0) {
                        this.setState({ disabled_addons_lb: [...disabled.map(i => i.name)] });
                    }
                    this.setState({ album_products: clone });

                }.bind(this))
                .catch(function (err) {
                }.bind(this))

            DataSource.get('/products/walnut_box')
                .then(function (res) {
                    var clone = this.state.album_products.slice();
                    var options_customization_match = box_customize_config['walnut_box'].display_options_customization_popup;
                    const options_to_show_customization = res.data.options.filter(o => options_customization_match.includes(o.name));
                    options_to_show_customization.map((option) => {
                        if (option.type == 'values') {
                            var oValues = option.values.filter((optionValue) => {
                                return optionValue.enabled;
                            });
                            option.values = oValues;
                        }
                    });
                    clone[num].options_customization.walnut_box = options_to_show_customization;
                    this.setState({ album_products: clone });

                }.bind(this))
                .catch(function (err) {
                }.bind(this))

            DataSource.get('/products/pp_box')
                .then(function (res) {
                    var clone = this.state.album_products.slice();
                    var options_customization_match = box_customize_config['pp_box'].display_options_customization_popup;
                    const options_to_show_customization = res.data.options.filter(o => options_customization_match.includes(o.name));
                    options_to_show_customization.map((option) => {
                        if (option.type == 'values') {
                            var oValues = option.values.filter((optionValue) => {
                                return optionValue.enabled;
                            });
                            option.values = oValues;
                        }
                    });
                    clone[num].options_customization.pp_box = options_to_show_customization;
                    this.setState({ album_products: clone });

                }.bind(this))
                .catch(function (err) {
                }.bind(this))

        })



    }

    render() {
        const { classes } = this.props;
        const copies = this.state.album_products;

        const clamshell_colors = this.state.clamshell_linen_colors;

        if (!copies) {
            return null;
        }

        const { quantity } = copies[0];//the same for all the books just added (bundled by the design)
        const { ref_design_name, ref_design_id, cover_color, cover_type } = copies[0].options;

        if (!ref_design_name) {
            return <div>Loading...</div>;
        }

        let description = '';
        switch (this.state.product_name) {
            case 'parent_set': {
                description = 'Choose Packaging For "' + ref_design_name + '" Parent Set.\n Thought just one displayed, two 8x8 albums will be added to your cart.'
                break;
            }
            case 'gift_set': {
                description = 'Choose Packaging For "' + ref_design_name + '" Gist Set.\n Thought just one displayed, four 4x4 albums will be added to your cart.';
                break;
            }
            case 'box_set': {
                description = 'A Walnut Box will automatically be added to your cart and displayed on the checkout page.\n You may add additional packaging options';
                break;
            }
            default: {
                description = 'Choose Packaging For "' + ref_design_name + '" Album' + copies.length > 1 ? 's' : '';
                break;
            }
        }

        return (
            <div>
                <Header location={this.props.location} />
                <BreadCrumbs>{this.state.product_type}</BreadCrumbs>
                <Typography variant='h5' gutterBottom={true}>{description}</Typography>
                {/*<div>
            <Typography>For "{ref_design_name}"</Typography>
            <Grid container>
                <Grid item>
                    <img src={'/images/kiss_book/cover_colors/brazil_nut.png'} style={{width:'100%'}}/>
                </Grid>
                <Grid item>
                    <Typography>Each Copy Features</Typography>
                    <Grid container>
                        <Grid item>
                            <Typography><strong>Cover:</strong><br/>
                            Type: {cover_type}<br/>
                            Color: {cover_color}<br/>
                            Inside Color: {inside_cover_color}<br/>
                            Text: <br/>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography><strong>Spreads: {spread_count}</strong><br/>
                            Paper: {paper_type}<br/>
                            Thickness: {spread_thickness}<br/>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
        <div>
            <Typography>Packaging Options</Typography>
            <Grid container>
                <Grid item xs={2}>
                    <Typography>Linen Box</Typography><br/>
                    <img src=''/>
                    <Typography variant='caption'>Hand-crafted, authentic linen. <PrettyLink href='http://kiss.us/collection/boxes/#details_linen' target='_blank'>More info</PrettyLink></Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography>Walnut Box</Typography><br/>
                    <img src='/images/box_walnut.png' style={{width:'80px'}}/>
                    <Typography variant='caption'>Hand-crafted, authentic walnut wood that is treated with a smooth finish. <PrettyLink href='http://kiss.us/collection/boxes/#details_walnut' target='_blank'>More info</PrettyLink></Typography>
                </Grid>
              
                <Grid item xs={2}>
                    <Typography>Presentation Box</Typography><br/>
                    <img src='/images/box_paper.png' style={{width:'80px'}}/>
                    <Typography variant='caption'>Simple, environmentally friendly recycled cardboard. <PrettyLink href='http://kiss.us/collection/boxes/#details_presentation' target='_blank'>More info</PrettyLink></Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography>Dust Cover</Typography><br/>
                    <img src='/images/slipcover.png'/>
                    <Typography variant='caption'>Hand-sewn linen fabric.</Typography>
                </Grid>
            </Grid>
        </div>*/}
                <div style={{ minWidth: '940px' }}>
                    <Table padding='dense'>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell align='center'>Dust Cover</TableCell>
                                <TableCell align='center'>Boxes</TableCell>
                                <TableCell align='center'>Gift Wrap</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {copies.map((item, num) => ( 
                                
                                <TableRow>
                                    { console.log( item, num ) }
                                    <TableCell padding='none' style={{ paddingTop: '20px' }}>{item.options.size + (item.options.is_sample == 'true' ? '" Sample' : '"')}</TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eee', paddingTop: '20px', paddingBottom: '20px' }}>
                                    {
                                        item.type != '5efc8e133f7c43740ce7c270' && 
                                        <ButtonBase onClick={e => this.handleAddonClick(num, 'slipcover')}>
                                            <Tooltip placement='top' title="Hand-sewn linen fabric.">
                                                <div style={(item.addons.filter(item => item.type == 'slipcover').length ? selected : deselected)}>
                                                    <img src='/images/slipcover.png' style={{ width: '80px' }} /><br />
                                                    <Typography variant='caption'>Dust Cover</Typography>
                                                    <br />
                                                    
                                                    <div>
                                                        <strong>{item.pricing.slipcover ? formatCurrency(item.pricing.slipcover.price - item.pricing.slipcover.discount) : ''}</strong>
                                                        {item.pricing.slipcover.discount > 0 &&
                                                            <s style={{ marginLeft: '5px', opacity: '.5' }}>{formatCurrency(item.pricing.slipcover.price)}</s>
                                                        }
                                                    </div>
                                                </div>
                                            </Tooltip>
                                        </ButtonBase>
                                    }
                                    </TableCell>
                                    
                                    <TableCell style={{ borderRight: '1px solid #eee', paddingTop: '20px', paddingBottom: '20px' }}>
                                        <Table padding='none'>
                                            <TableRow>
                                                {/* {(item.options.cover_type == 'linen' && item.options.size != '4') &&
                                                    <TableCell style={{ borderBottom: '0px', width: '100px' }}>
                                                        <ButtonBase onClick={e => this.handleBoxClick(num, 'linen_box')} disabled={this.checkForDisabled(item, 'linen_box')}>
                                                            <Tooltip placement='top'
                                                                interactive
                                                                leaveDelay={2}
                                                                title={<Fragment>
                                                                    Hand-crafted, authentic linen. <PrettyLink href='http://kiss.us/collection/boxes/#details_linen' target='_blank'>Click for more info.</PrettyLink>
                                                                </Fragment>}>

                                                                <div style={(item.boxes.filter(item => item.type == 'linen_box').length ? selected : deselected)}>
                                                                    <img src={'/images/kiss_book/cover_colors/' + cover_color + '.png'} style={{ width: '87px', paddingTop: '0px', paddingBottom: '8px' }} />
                                                                    <Typography variant='caption'>Linen</Typography>
                                                                    {/*<PrettyLink href='http://kiss.us/collection/boxes/#details_linen' target='_blank'>
                                                        <Tooltip placement='top' title="Hand0crafted, authentic linen. Click for more info.">
                                                            <InfoIcon fontSize='small'/>
                                                        </Tooltip>
                                                    </PrettyLink>}<br />
                                                                    <strong>{this.checkForDisabled(item, 'linen_box') ? 'SOLD OUT' : item.pricing.linen_box ? formatCurrency(item.pricing.linen_box.price - item.pricing.linen_box.discount) : ''}</strong>
                                                                    {item.pricing.linen_box.discount > 0 &&
                                                                        <s style={{ marginLeft: '5px', opacity: '.5' }}>{formatCurrency(item.pricing.linen_box.price)}</s>
                                                                    }
                                                                </div>
                                                            </Tooltip>
                                                        </ButtonBase>
                                                    </TableCell>
                                                 */}

                                                {(item.options.size != '4') && 
                                                    <TableCell style={{ borderBottom: '0px', width: '100px' }}>
                                                        <ButtonBase onClick={e => this.handleBoxClick(num, 'linen_presentation_box')} disabled={this.checkForDisabled(item, 'linen_presentation_box')}>
                                                            
                                                            <div style={(item.boxes.filter(item => item.type == 'linen_presentation_box').length ? selected : deselected)}>
                                                                <img src={'/images/kiss_book/cover_colors/oatmeal.png'} style={{ width: '87px', paddingTop: '0px', paddingBottom: '8px' }} />
                                                                <Typography variant='caption'>Linen Presentation Box</Typography>
                                                            
                                                                <strong>{this.checkForDisabled(item, 'linen_presentation_box') ? 'SOLD OUT' : item.pricing.linen_presentation_box ? formatCurrency(item.pricing.linen_presentation_box.price - item.pricing.linen_presentation_box.discount) : ''}</strong>
                                                               
                                                            </div>

                                                        </ButtonBase>
                                                    </TableCell>
                                                }

                                                {(item.options.size != '4') && 
                                                    <TableCell style={{ borderBottom: '0px', width: '100px' }}>
                                                        <ButtonBase onClick={e => this.handleBoxClick(num, 'clamshell_linen_box')} disabled={this.checkForDisabled(item, 'clamshell_linen_box')}>
                                                            
                                                            <div style={(item.boxes.filter(item => item.type == 'clamshell_linen_box').length ? selected : deselected)}>
                                                                <img src={'/images/kiss_book/cover_colors/' + cover_color + '.png'} style={{ width: '87px', paddingTop: '0px', paddingBottom: '8px' }} />
                                                                <Typography variant='caption'>Clamshell Linen Box</Typography>
                                                            
                                                                <strong>{this.checkForDisabled(item, 'clamshell_linen_box') ? 'SOLD OUT' : item.pricing.clamshell_linen_box ? formatCurrency(item.pricing.clamshell_linen_box.price - item.pricing.clamshell_linen_box.discount) : ''}</strong>
                                                                
                                                            </div>

                                                        </ButtonBase>
                                                    </TableCell>
                                                }

                                                {item.options.size != '4' &&
                                                    <TableCell style={{ borderBottom: '0px', width: '100px' }}>

                                                        <ButtonBase onClick={e => this.handleBoxClick(num, 'walnut_box')}>
                                                            <Tooltip placement='top'
                                                                interactive
                                                                leaveDelay={2}
                                                                title={<Fragment>
                                                                    Hand-crafted, authentic walnut wood that is treated with a smooth finish. <PrettyLink href='http://kiss.us/collection/boxes/#details_walnut' target='_blank'>Click for more info.</PrettyLink>
                                                                </Fragment>}>
                                                                <div style={(item.boxes.filter(item => item.type == 'walnut_box').length ? selected : deselected)}>

                                                                    <img src='/images/box_walnut.png' style={{ width: '80px' }} />
                                                                    <Typography variant='caption'>Walnut</Typography>
                                                                    <br />
                                                                    <strong>{item.pricing.walnut_box ? formatCurrency(item.pricing.walnut_box.price - item.pricing.walnut_box.discount) : ''}</strong>
                                                                    {item.pricing.walnut_box.discount > 0 &&
                                                                        <s style={{ marginLeft: '5px', opacity: '.5' }}>{formatCurrency(item.pricing.walnut_box.price)}</s>
                                                                    }
                                                                </div>
                                                            </Tooltip>
                                                        </ButtonBase>

                                                    </TableCell>
                                                }

                                                {item.options.size != '4' &&
                                                    <TableCell style={{ borderBottom: '0px', width: '100px' }}>

                                                        <ButtonBase onClick={e => this.handleBoxClick(num, 'pp_box_w')}>
                                                            <Tooltip placement='top'
                                                                interactive
                                                                leaveDelay={2}
                                                                title={<Fragment>
                                                                    Deliver and protect your heirloom albums with the Presentation Box. Made from 100% recycled paper, this box is a simple way to step up your presentation.
                                                                </Fragment>}>
                                                                <div style={(item.boxes.filter(item => item.type == 'pp_box_w').length ? selected : deselected)}>

                                                                    <img src='https://s3.amazonaws.com/assets.kiss.us/store/images/1699199406990FullSizeRender-800x600.jpg' style={{ width: '80px' }} />
                                                                    <Typography variant='caption'>Presentation Box (White)</Typography>
                                                                    <br />
                                                                    <strong>{item.pricing.pp_box ? formatCurrency(item.pricing.pp_box.price - item.pricing.pp_box.discount) : ''}</strong>
                                                                    {item.pricing.pp_box.discount > 0 &&
                                                                        <s style={{ marginLeft: '5px', opacity: '.5' }}>{formatCurrency(item.pricing.pp_box.price)}</s>
                                                                    }
                                                                </div>
                                                            </Tooltip>
                                                        </ButtonBase>

                                                    </TableCell>
                                                }
                                                
                                                {item.options.size != '4' &&
                                                    <TableCell style={{ borderBottom: '0px', width: '100px' }}>

                                                        <ButtonBase onClick={e => this.handleBoxClick(num, 'pp_box_b')}>
                                                            <Tooltip placement='top'
                                                                interactive
                                                                leaveDelay={2}
                                                                title={<Fragment>
                                                                    Deliver and protect your heirloom albums with the Presentation Box. Made from 100% recycled paper, this box is a simple way to step up your presentation.
                                                                </Fragment>}>
                                                                <div style={(item.boxes.filter(item => item.type == 'pp_box_b').length ? selected : deselected)}>

                                                                    <img src='https://s3.amazonaws.com/assets.kiss.us/store/images/1699199450153FullSizeRender-2-800x600.jpg' style={{ width: '80px' }} />
                                                                    <Typography variant='caption'>Presentation Box (Black)</Typography>
                                                                    <br />
                                                                    <strong>{item.pricing.pp_box ? formatCurrency(item.pricing.pp_box.price - item.pricing.pp_box.discount) : ''}</strong>
                                                                    {item.pricing.pp_box.discount > 0 &&
                                                                        <s style={{ marginLeft: '5px', opacity: '.5' }}>{formatCurrency(item.pricing.pp_box.price)}</s>
                                                                    }
                                                                </div>
                                                            </Tooltip>
                                                        </ButtonBase>

                                                    </TableCell>
                                                }
                                                

                                                {item.options.size != '4' &&
                                                    <TableCell style={{ borderBottom: '0px', width: '100px' }}>

                                                        <ButtonBase onClick={e => this.handleBoxClick(num, 'pp_box_g')}>
                                                            <Tooltip placement='top'
                                                                interactive
                                                                leaveDelay={2}
                                                                title={<Fragment>
                                                                    Deliver and protect your heirloom albums with the Presentation Box. Made from 100% recycled paper, this box is a simple way to step up your presentation.
                                                                </Fragment>}>
                                                                <div style={(item.boxes.filter(item => item.type == 'pp_box_g').length ? selected : deselected)}>

                                                                    <img src='https://s3.amazonaws.com/assets.kiss.us/store/images/1699199450152IMG_1541-800x600.jpg' style={{ width: '80px' }} />
                                                                    <Typography variant='caption'>Presentation Box (Grey)</Typography>
                                                                    <br />
                                                                    <strong>{item.pricing.pp_box ? formatCurrency(item.pricing.pp_box.price - item.pricing.pp_box.discount) : ''}</strong>
                                                                    {item.pricing.pp_box.discount > 0 &&
                                                                        <s style={{ marginLeft: '5px', opacity: '.5' }}>{formatCurrency(item.pricing.pp_box.price)}</s>
                                                                    }
                                                                </div>
                                                            </Tooltip>
                                                        </ButtonBase>

                                                    </TableCell>
                                                }
                                                
                                                
                                                {/* optionally, show the engraving and debossing when needed */}
                                            </TableRow>
                                        </Table>

                                        {(item.boxes.filter(item => item.type == 'presentation_box' || item.type == 'linen_presentation_box' || item.type == 'clamshell_linen_box' || item.type == 'walnut_box').length > 0) &&
                                            
                                            <Fragment>
                                                <br />
                                                {(item.boxes.filter(item => item.type == 'clamshell_linen_box').length > 0 ) && 
                                                    <FormControlLabel
                                                        label="Select Cover Color"
                                                        labelPlacement="start"
                                                        control={
                                                            <Select
                                                                value={ this.state.album_products[num].boxes[0].options.cover_color ? this.state.album_products[num].boxes[0].options.cover_color : this.state.album_products[num].options.cover_color } 
                                                                label="Select Cover Color" 
                                                                style={{marginLeft: '10px'}} 
                                                                onChange={this.handleClamshellLinenBoxCoverColorChange.bind(this, num)}  
                                                            >    
                                                                {
                                                                    clamshell_colors.map((item) => {
                                                                        
                                                                        return (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                                </Select>
                                                        }
                                                    />
                                            
                                                }

                                                <br />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox value="checkedH" />
                                                    }
                                                    label="Customize your box with text or an image"
                                                    onClick={this.handleBoxCustomizeClick.bind(this, num)}
                                                    checked={
                                                        (this.state.customize_box_open ||
                                                            (this.state.album_products[num].boxes[0].options.lid_top_customization && this.state.album_products[num].boxes[0].options.lid_top_customization != 'none') ||
                                                            (this.state.album_products[num].boxes[0].options.lid_underside_customization && this.state.album_products[num].boxes[0].options.lid_underside_customization != 'none') ? true : false)}
                                                />
                                            </Fragment>
                                        }
                                    </TableCell>






                                    <TableCell style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                        <Table padding='none'>
                                            <TableRow>
                                         
                                                <TableCell style={{ borderBottom: '0px', width: '100px', paddingLeft: '10px' }}>
                                                    <ButtonBase onClick={e => this.handleAddonClick(num, 'classic')}>
                                                        <Tooltip placement='top'
                                                            interactive
                                                            leaveDelay={2}
                                                            title={<Fragment>
                                                                A timeless look, perfect for every occasion. Thick, unwaxed Kraft paper made from 100% recycled materials in the USA. Finished with a black, grosgrain ribbon. <PrettyLink href='https://www.kiss.us/boutique-gift-wrap/#details_classic' target='_blank'>Click for more info.</PrettyLink>
                                                            </Fragment>}>
                                                            <div style={(item.addons.filter(item => item.type == 'classic').length ? selected : deselected)}>
                                                                <img src='/images/classic.jpg' style={{ width: '100px' }} />
                                                                <Typography variant='caption'>Classic</Typography>
                                                                {/*<PrettyLink href='http://kiss.us/collection/boutique-gift-wrap/#details_classic' target='_blank'>
                                                        <Tooltip placement='top' title="A timeless look, perfect for every occasion. Thick, unwaxed Kraft paper made from 100% recycled materials in the USA. Finished with a black, grosgrain ribbon. Click for more info.">
                                                            <InfoIcon fontSize='small'/>
                                                        </Tooltip>
                                                    </PrettyLink>*/}<br />
                                                                <strong>{item.pricing.classic ? formatCurrency(item.pricing.classic.price - item.pricing.classic.discount) : ''}</strong>
                                                                {item.pricing.classic.discount > 0 &&
                                                                    <s style={{ marginLeft: '5px', opacity: '.5' }}>{formatCurrency(item.pricing.classic.price)}</s>
                                                                }
                                                            </div>
                                                        </Tooltip>
                                                    </ButtonBase>
                                                </TableCell>
                                             
                                            </TableRow>
                                        </Table>

                                        <div style={{ marginTop: '20px' }}>
                                            {item.addons.filter(item => item.type == 'polkadot' || item.type == 'eucalyptus' || item.type == 'classic' || item.type == 'green_holiday' || item.type == 'red_and_white_holiday').map(addon => {
                                                return (
                                                    <div>
                                                        {/*<Typography variant='subtitle1'>Add a hand-written thank-you card?</Typography>
                                            <Typography variant='caption'>
                                                165 character max, leave blank for no card. <PrettyLink href='http://kiss.us/collection/boutique-gift-wrap/#details_thank_you' target='_blank'>More info</PrettyLink>
                                            </Typography>
                                        
                                            <TextField label='Thank-You Card Text' multiline rows={3} fullWidth variant='filled'></TextField>
                                                */}
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox checked={addon.state.checked === true} onClick={this.handleThankYouCardClick.bind(this, num)} />
                                                            }
                                                            label="Add a hand-written thank-you card?"
                                                        />

                                                        {addon.state.checked &&
                                                            <Fragment>
                                                                <Typography variant='caption'>
                                                                    165 character max, leave blank for no card. <PrettyLink href='http://kiss.us/collection/boutique-gift-wrap/#details_thank_you' target='_blank'>More info</PrettyLink>
                                                                </Typography>
                                                                <TextField label='Thank-You Card Text' value={addon.options.card_text} onChange={e => this.handleThankYouCardInput(num, e)} multiline rows={3} fullWidth variant='filled'></TextField>
                                                            </Fragment>
                                                        }
                                                    </div>
                                                )

                                            })}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}

                        </TableBody>
                    </Table>
                    { /*Optional Thank You Note If Needed*/}
                </div>

                <div style={{ marginTop: '20px' }}>
                    <Grid container>
                        <Grid item xs='6' />
                        <Grid item xs='6' style={{ textAlign: 'right' }}>
                            <br />
                            <Button type='cancel' onClick={this.handleSkip.bind(this)} style={{ opacity: '1', fontSize: '10px' }}>No, I don't want any add-ons</Button>
                            <Button
                                type='submit'
                                style={{ marginLeft: '10px' }}
                                variant="contained"
                                disabled={((copies.filter(i => i.addons.length > 0).length < 1 && copies.filter(i => i.boxes.length > 0).length < 1) || this.state.isSubmitting)}
                                onClick={this.handleSubmit.bind(this)}
                            >Add To Cart</Button>
                            {this.state.isSubmitting &&
                                <CircularProgress size={20} />
                            }
                        </Grid>
                    </Grid>
                </div>


                {(box_customize_config && box_fonts_config && box_positions_config && box_customize_config[this.state.customize_box_type]) &&
                    <BoxCustomize
                        open={this.state.customize_box_open}
                        config={box_customize_config[this.state.customize_box_type]}
                        fonts={box_fonts_config}
                        positions={box_positions_config}
                        type={this.state.customize_box_type}
                        handleClose={this.handleBoxCustomizeClose.bind(this)}
                        handleSubmit={this.handleBoxCustomizeSubmit.bind(this)}
                        handleImagesChanged={this.handleImagesChanged.bind(this)}
                        customize_box_errors={this.state.customize_box_errors}
                        options={this.state.customize_box_options ? this.state.customize_box_options : false}
                        options_customization={this.state.options_customization}
                        enqueueSnackbar={this.props.enqueueSnackbar}
                        handleAllowedToAddChange={() => { }}
                    />
                }


                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.prompt}
                >
                    <DialogTitle id="alert-dialog-title">Where to next?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            If you'd like to add more albums to your cart or are not ready to order yet then head back to the MyKiss Dashboard. If you are all ready to go then let's go to Checkout!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(e) => { window.location = process.env.REACT_APP_DASHBOARD_URL }} color="secondary" type='cancel'>
                            Back To MyKiss Dashboard
                        </Button>
                        <Button onClick={e => this.props.history.push('/checkout', {})} type='submit' variant='contained' className={classes.dialog_submit}>
                            Proceed To Checkout
                        </Button>
                    </DialogActions>

                </Dialog>


            </div>
        )
    }
}

export default withSnackbar(withStyles(styles)(AlbumAddons));