import React, { Fragment, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FilledInput from "@material-ui/core/FilledInput";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import formatCurrency from "../utils/formatCurrency";
import styled from "styled-components";

const Count = styled.span`
  padding: 0 20px 0 10px;
`;
function ProductList(props) {
  console.log(" product list", props);
  const { products, expansion } = props;

  const [sorted_products, set_sorted_products] = useState(
      detect_free_sample_swatches(products)
  );

  function sort_products(products, on, field) {
      let bundles = ['gift_set', 'parent_set', 'box_set'];
      let sorted = [];
      var productTypeName = '';
      let children = [...products].filter((p) => p[on]);
      let parents = [...products].filter((p) => !p[on]);
      for (let parentProduct of parents) {
          //add this one
          sorted.push(parentProduct);
          productTypeName = (on == 'attached_to') ? parentProduct.type.name : parentProduct.type;
          if (bundles.includes(productTypeName)) {
              // if bundle
              let attachedLevelFirsts  = products.filter((p) =>
                  p[on] && p[on] == parentProduct[field]
              );
              for (let attachedLevelFirst of attachedLevelFirsts) {
                  sorted.push(attachedLevelFirst);

                  let attachedLevelSeconds  = products.filter((p) =>
                      p[on] && p[on] == attachedLevelFirst[field]
                  );
                  for (let attachedLevelSecond of attachedLevelSeconds) {
                      sorted.push(attachedLevelSecond);
                  }
              }
          }
          else {
              //add any children
              let childrenOf = children.filter(
                  (c) => c[on] == parentProduct[field]
              );

              if (childrenOf) {
                  sorted = sorted.concat(childrenOf);
              }
          }
      }

      return sorted;
  }

  function showQuantitiesInBundle(product, on, field) {
      var productTypeName = '';
      let bundles = ['gift_set', 'parent_set', 'box_set'];
      if (product[on] != undefined) {
          let parentArray = products.filter((p) => p[field] == String(product[on]));
          if (parentArray.length > 0) {
              let parent = parentArray[0];
              if (parent != undefined) {
                  productTypeName = (on == 'attached_to') ? parent.type.name : parent.type;
                  if (bundles.includes(productTypeName)) {
                      return true;
                  }
                  if (parent[on] != undefined) {
                      let parentParentArray = products.filter((p) => p[field] == String(parent[on]));
                      if (parentParentArray.length > 0) {
                          let parent = parentParentArray[0];
                          productTypeName = (on == 'attached_to') ? parent.type.name : parent.type;
                          if (bundles.includes(productTypeName)) {
                              return true;
                          }
                      }
                  }
              }
          }
      }

      return false;
  }

    function showPrice(product) {
        let on = '';
        let field = '';
        let productTypeName = '';
        if (products.find(p=>p.attached_to)) {
          on = 'attached_to';
          field = '_id';
          productTypeName = product.type.name;
        }
        else {
          on = 'num_attached_to';
          field = 'num';
          productTypeName = product.type;
        }
        if (product[on] != undefined) {
            if (productTypeName == 'kiss_book') {
                return false;
            }
            else {
                let p_attached_array = products.filter((p) => p[field] == product[on]);
                if (p_attached_array.length>0) {
                    let p_attached = p_attached_array[0];
                    if (p_attached[on] != undefined) {
                        return true;
                    }
                }
            }
        }

        return true;
    }

  function showQuantitySelector(product) {
      if (product.attached_to != undefined) {
          let bundles = ['gift_set', 'parent_set', 'box_set'];
          let parentArray  = products.filter((p) => p._id == String(product.attached_to));
          if (parentArray.length > 0) {
              let parent = parentArray[0];
              if (parent != undefined) {
                  if (bundles.includes(parent.type.name)) {
                      return false;
                  }
                  if (parent.attached_to != undefined) {
                      let parentParentArray  = products.filter((p) => p._id == String(parent.attached_to));
                      if (parentParentArray.length > 0) {
                          let parent = parentParentArray[0];
                          if (bundles.includes(parent.type.name)) {
                              return false;
                          }
                      }
                  }
              }
          }
      }

      return true;
  }

    function detect_level(product) {
        let column = 'auto';
        let on = '';
        let field = '';
        if (products.find(p=>p.attached_to)) {
            on = 'attached_to';
            field = '_id';
        }
        else {
            on = 'num_attached_to';
            field = 'num';
        }

        if (product[on] != undefined) {
            let p_attached_array = products.filter((p) => p[field] == product[on]);
            if (p_attached_array.length>0) {
              let p_attached = p_attached_array[0];
              if (p_attached[on] == undefined) {
                  column = 2;
              } else {
                  column = 3;
              }
            }
            else {
              column = 3;
            }
        }

        return column;
    }

    function hideDescriptionOnBundleProducts(product) {
        let bundles = ['gift_set', 'parent_set', 'box_set'];
        let on = '';
        let field = '';
        let productTypeName = '';
        if (products.find(p=>p.attached_to)) {
            on = 'attached_to';
            field = '_id';
        }
        else {
            on = 'num_attached_to';
            field = 'num';
        }

        if (product[on] != undefined) {
            let p_attached_array = products.filter((p) => p[field] == product[on]);
            if (p_attached_array.length>0) {
                let p_attached = p_attached_array[0];
                productTypeName = (on == 'attached_to') ? p_attached.type.name : p_attached.type;
                if (bundles.includes(productTypeName)) {
                    return false;
                }
                if (p_attached[on] != undefined) {
                    p_attached_array = products.filter((p) => p[field] == p_attached[on]);
                    if (p_attached_array.length>0) {
                        p_attached = p_attached_array[0];
                        productTypeName = (on == 'attached_to') ? p_attached.type.name : p_attached.type;
                        if (bundles.includes(productTypeName)) {
                            return false;
                        }
                    }
                }
            }
        }

        return true;
    }

  // Detect free sample swatches and set quantity for them
  function detect_free_sample_swatches(products) {
    products.map((product) => {
      product.quantity_count =
          product.type.name == "free_sample_swatches" ? 2 : 20;
    });

    return products;
  }

    function AttachedFragment(props) {
        let attached_item_q = props.products.find( item => item._id === props.p.attached_to).quantity
        return <Fragment>
            { props.num!=0 && (props.p.quantity > attached_item_q ?
                <option value={props.num} selected={attached_item_q==props.num}>{props.num}</option> :
                <option value={props.num} selected={props.p.quantity==props.num}>{props.num}</option>)
            }
        </Fragment>
    }

    //organize by attachements (if any)
    useEffect(()=>{

        //which style of attachment?
        if(products.find(p=>p.attached_to)){
            set_sorted_products(sort_products(products,'attached_to','_id'));

        }else if(products.find(p=>p.num_attached_to)){
            set_sorted_products(sort_products(products,'num_attached_to','num'));
        }
    },[])

    const generateProductOptions = (product, withCaption = false) => {
      if (!product?.options_pretty) {
        return null;
      }
      const type = product?.type?.name || product?.type;
      const getItem = (i, value) => (
        <ListItem key={i} style={withCaption ? { padding: "0px" } : {}}>
          <Typography variant={withCaption ? "caption" : "'body1'"}>
            {value}
          </Typography>
        </ListItem>
      );
      return (
        <List disablePadding={withCaption}>
          {Object.entries(product.options_pretty).reduce(
            (options, [key, val], i) => {
              const option = `${key}: ${val}`;
              if (type === "free_sample_swatches") {
                if (val !== "default") {
                  options.push(getItem(i, option));
                }
              } else {
                options.push(getItem(i, option));
              }
              return options;
            },
            []
          )}
        </List>
      );
    };

  if (expansion) {
    return (
        <Fragment>
          {sorted_products.map((p, i) => (
              <ExpansionPanel key={i}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{p.name}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {generateProductOptions(p, false)}
                </ExpansionPanelDetails>
              </ExpansionPanel>
          ))}
        </Fragment>
    );
  } else {

    return (
        <List style={{ opacity: props.updatingCart ? ".25" : "1" }}>
          {sorted_products.map((p) => (

              <ListItem
                  key={p._id}
                  style={
                    p.attached_to || p.num_attached_to
                        ? {}
                        : { borderTop: "1px solid #ccc" }
                  }
              >

                <Grid container spacing={16}>
                    {(p.attached_to || p.num_attached_to) &&
                        <Grid item xs={detect_level(p)} />
                    }

                  {p.images && p.images.icon && (
                      <Grid item xs={3}>
                        <img
                            src={
                              p.images.icon.path
                                  ? (process.env.REACT_APP_API_URL
                                  ? process.env.REACT_APP_API_URL
                                  : "http://localhost:3000") +
                                  "/images/product/icon/" +
                                  p._id
                                  : p.images.icon.url
                            }
                            width="100%"
                        />
                      </Grid>
                  )}
                    <Grid item xs={p.attached_to || p.num_attached_to ? 3 : 6}>
                        <Typography variant="subtitle1">{p.name}</Typography>
                        {hideDescriptionOnBundleProducts(p) &&
                            generateProductOptions(p, true)
                        }
                    </Grid>

                    <Grid item xs={3} style={{textAlign: "right"}}>
                        <Typography variant="subtitle1">
                            {!props.show_actions || showQuantitiesInBundle(p, 'attached_to', '_id') &&
                                <Count>{p.quantity}x</Count>
                            }

                            {showQuantitiesInBundle(p, 'num_attached_to', 'num') &&
                                <Count>{p.quantity}x</Count>
                            }
                            {/*{(p.num_attached_to) && // todo remove*/}
                            {/*    <Count>{p.quantity}x</Count>*/}
                            {/*}*/}

                            {showPrice(p) &&
                                formatCurrency(p.total)
                            }
                        </Typography>
                        {props.show_actions &&
                            showQuantitySelector(p) && (
                                <Fragment>
                                    <Select
                                        variant="filled"
                                        label="Quantity"
                                        margin="normal"
                                        native
                                        inputProps={{
                                            name: "age",
                                            id: "age-native-simple",
                                        }}
                                        input={
                                            <FilledInput
                                                name="age"
                                                id="filled-age-native-simple"
                                                style={{width: "50px"}}
                                            />
                                        }
                                        onChange={(e) =>
                                            props.handleQuantityChange(p._id, e.target.value)
                                        }
                                        disabled={props.updatingCart}
                                    >
                                        <option value={0}>Remove</option>
                                        {!p.attached_to && Array.from(Array(p.quantity_count).keys()).map(num => (
                                            <Fragment>
                                                {num != 0 &&
                                                <option value={num} selected={p.quantity == num}>{num}</option>
                                                }
                                            </Fragment>
                                        ))}
                                        {p.attached_to && Array.from(Array(sorted_products.find(item => item._id === p.attached_to).quantity + 1).keys()).map(num => (
                                            <AttachedFragment num={num} p={p} products={sorted_products}></AttachedFragment>
                                        ))}
                                    </Select>
                                </Fragment>
                            )
                        }
                    </Grid>
                </Grid>
              </ListItem>
          ))}
        </List>
    );
  }
}

export default ProductList;