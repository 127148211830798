import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddressForm from '../components/addressForm';

function AddressEditor(props){

    const {address, addresses, type, open, handleClose, handleSubmit, handleShipAddressRemove} = props;
   
    return (        
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
          <DialogTitle id="form-dialog-title">Edit Shipping Address</DialogTitle>
          <DialogContent>   
            <AddressForm address={address} addresses={addresses} type={type} handleSubmit={handleSubmit} handleClose={handleClose} handleShipAddressRemove={handleShipAddressRemove} cancel={'Nevermind'} submit={'Save Changes'}/>
          </DialogContent>                   
        </Dialog>  
    );

}


export default AddressEditor;