import React from 'react'
//import styled from 'styled-components'
import { withSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import DataSource from '../utils/DataSource';
import Cookies from 'js-cookie';
import moment from 'moment';
import CheckoutShippingAddress from '../components/checkoutShippingAddress';
import CheckoutPayment from '../components/checkoutPayment';
import CheckoutReview from '../components/checkoutReview';
import Header from '../components/header';
import ErrorHandler from '../utils/ErrorHandler';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SiteLive from '../utils/siteLive';
import ReactPixel from 'react-facebook-pixel';

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: false,
      totals:false,
      loading_totals:true,
      storeCreditInputAmount:'',
      cardEditorOpen:false,
      requireSignature:false,
      uid:0,
      clientEmail:'',
      submitting:false,
      comments:'',
      sent_comments:'',
      show_usps_popup: false,
      show_agreement_popup: false,
      show_incorrect_address_popup: false,
      blockButton: false,
    };
  }

  handleSelectChange(ev){
    console.log('something',ev.target.value);
    const options = Object.assign({}, this.state.options);
    options[ev.target.name] = ev.target.value;
    this.setState({options});
    this.fetchProductPrice(this.state.product._id, options);
  }

  handleTextChange(ev){
    const options = Object.assign({}, this.state.options);
    options[ev.target.name] = ev.target.value;
    this.fetchProductPrice(this.state.product._id, options);
  }

  handleSwitchChange(ev){
    const options = Object.assign({}, this.state.options);
    options[ev.target.name] = ev.target.checked;
    this.fetchProductPrice(this.state.product._id, options);
  }

  handleCommentsChange(ev){
    this.setState({comments:ev.target.value});
  }

  sendComments(){
    if(this.state.comments!=this.state.sent_comments){

      //update the sent
      this.setState({sent_comments: this.state.comments});

      DataSource.put('/cart/comments', {
        comments: this.state.comments
      })
      .then(function(res){ 
        
      })
      .catch(function(err){
        console.log('ERR',err);
        ErrorHandler(err,this.props);      
      }.bind(this))
    }
    
  }

  

  handleShippingAddressSubmit(vals){
    console.log('submitting shipping address in parent Checkout comp.',vals);
    
    //loader 
    this.setState({
      is_saving: true,
    });

    //save in address book
    vals.save = true;

    //send to API
    DataSource.put('/cart/addresses/shipping', vals)
    .then(function(res){ 
      this.setState({
        shipping_address: res.data,
        is_saving: false,
      });

      //load EVERYTHING
      this.loadAll();

    }.bind(this)) 
    .catch(function(err){
      console.log('ERR',err);
      ErrorHandler(err,this.props);
      
    }.bind(this))
    
  }

  handlePaymentMethodSubmit(vals){    
    console.log('hit the parent handler. vals',vals);

    //loader 
    this.setState({
      is_saving: true,
      totals: false,
      products: false,
      shipping_methods: false,
      payment_methods: false,      
    });


    //send to API
    var p = new Promise((resolve,reject)=>{
      DataSource.post('/payment_methods/credit_card', {
        card_number: vals.number.replace(/\s/g,''),
        card_exp: moment(vals.exp,'MM / YY').format('MM/YY'),
        card_cvv: vals.cvv,
        address: vals.address
      })
      .then(function(res){

        var card_id = res.data;
        console.log('card_id',card_id,res.data);

        //set this as the new payment method
        DataSource.put('/cart/payment_method', {
          amount: 5,
          type: 'credit_card',
          token: card_id
        })
        .then(function(res){         

          //Get all ccs
          /*this.fetch('payment_methods')
          .then(function(res){
            console.log('loaded all methods',res);
            var selected = res.credit_cards.find(cc=>cc.selected);
            console.log('selected',selected);

            this.setState({
              payment_methods: res,   
              payment_method: selected,            
              is_saving: false,
            });

            resolve(true);

          }.bind(this));*/

          this.setState({           
            loading_totals: true,
            is_saving: false,
          });

          //load EVERYTHING
          this.loadAll();


        }.bind(this))
        .catch(function(err){
          console.log('ERR',err);
          ErrorHandler(err,this.props);
          reject(err);
        }.bind(this))       

      }.bind(this)) 
      .catch(function(err){
        console.log('ERR',err);
        ErrorHandler(err,this.props);
        reject(err);
      }.bind(this))
    });

    return p;

  }

  handleSignatureChange(ev){

    //is it being turned off?
    if(this.state.requireSignature && !ev.target.checked){
      this.props.enqueueSnackbar(" Are you sure? Lost or stolen shipments will not be replaced. Kiss suggests adding a signature on all orders to avoid a lost or stolen package");
    }

    //update state
    this.setState({
      requireSignature: ev.target.checked,
      loading_totals: true
    });

    //update api
    var p = new Promise((resolve,reject)=>{
      DataSource.post('/cart/shipping_options',{options:{require_signature:ev.target.checked}})
      .then(function(res){       
        console.log('response from server',res);
        if(res.data.success){

          //fetch payment methods         
          this.fetch('payment_methods')
          .then(function(res){
            console.log('loaded all methods',res);
            var selected = res.credit_cards.find(cc=>cc.selected);
            console.log('selected',selected);

            this.setState({
              payment_methods: res,   
              payment_method: selected,            
              is_saving: false,
            });

            //fetch the updated totals
            this.fetch('totals')
            .then(function(res){
              console.log('loaded totals',res);            
              
              this.setState({
                totals: res,
                loading_totals: false,
              });
            
              resolve();

            }.bind(this));

          }.bind(this));


          
        }else{
          this.props.enqueueSnackbar(res.data.reason,{variant:'error'}); 
          this.setState({
            loading_totals: false,
          });
        }

         

      }.bind(this)) 
      .catch(function(err){
        console.log('ERR',err);
        ErrorHandler(err,this.props);
        reject(err);
      }.bind(this))
    });

    return p;

  }

  handleShipMethodChangeSetUsps(ev){
      // for close popup windows
      this.setState({show_usps_popup: ev})
  }

  handleShipMethodIncorrectAddressPopup(ev){
    // for close popup windows
    this.setState({show_incorrect_address_popup: ev})
  }

  handleShipMethodChange(ev){
    console.log('handleShipMethodChange',ev.target.value);

    //get the id, using the name
    var match = false;
    for(let type of this.state.shipping_methods){
      console.log('looking for',ev.target.value,'in ',type.methods);
      var isUspsShipping = type.type == "USPS";
      this.setState({show_usps_popup: isUspsShipping});
      match = type.methods.find(m=>m.name==ev.target.value);
      if(match){
        break;
      }
    }
    console.log('match',match,this.state.shipping_methods);
    if(match){

      //update state
      //var copy = this.state.shipping_methods.slice();
      //copy.map(type=>type.methods.map(m=>m.selected=(m.name==ev.target.value)))     
      this.setState({
        //shipping_methods: copy,
        loading_totals: true,
      });

      //send to API
      var p = new Promise((resolve,reject)=>{
        DataSource.post('/cart/shipping_method', {
          method: match._id
        })
        .then(function(res){      
          console.log('updated shipping method - res',res);          
          
          //fetch the shipping methods
          this.fetch('shipping_methods')
          .then(function(res){

            this.setState({
              shipping_methods:res
            });

            //fetch the shipping methods
            this.fetch('shipping_options')
            .then(function(res){
              console.log('loaded shipping_options',res);
              
              this.setState({
                requireSignature: res.require_signature,
              });


              //Get all ccs
              this.fetch('payment_methods')
              .then(function(res){
                console.log('loaded all methods',res);
                var selected = res.credit_cards.find(cc=>cc.selected);
                console.log('selected',selected);

                this.setState({
                  payment_methods: res,   
                  payment_method: selected,            
                  is_saving: false,
                });

                //fetch the updated totals
                this.fetch('totals')
                .then(function(res){
                  console.log('loaded totals',res);            
                  
                  this.setState({
                    totals: res,
                    loading_totals: false,
                  });

                  // todo holidays deadline fix
                  this.fetch('handling_methods')
                      .then(function(res){
                        this.setState({
                          handling_methods: res,
                        });
                        resolve();

                      }.bind(this));

                }.bind(this)); 

              }.bind(this));

            }.bind(this));

               
          }.bind(this))
        }.bind(this)) 
        .catch(function(err){
          console.log('ERR',err);
          ErrorHandler(err,this.props);
          reject(err);
        }.bind(this))
      });

      return p;
    }else{
      return false;
    }

  }


  handleHandlingMethodChange(ev){
    console.log('handleHandlingMethodChange',ev.target.value);
    
    //get the id, using the name
    var match = this.state.handling_methods.find(m=>m.name==ev.target.value);
    
    console.log('match',match,this.state.handling_methods);
    if(match){

      //update state
      this.setState({
        //handling_method: match,
        loading_totals: true,
      });

      //send to API
      var p = new Promise((resolve,reject)=>{
        DataSource.post('/cart/handling_method', {
          method: match._id
        })
        .then(function(res){      
          console.log('updated handling method - res',res);          

          //fetch the handling methods
          this.fetch('handling_methods')
          .then(function(res){  

            this.setState({
              handling_methods:res
            });

            //Get all ccs
            this.fetch('payment_methods')
            .then(function(res){
              console.log('loaded all methods',res);
              var selected = res.credit_cards.find(cc=>cc.selected);
              console.log('selected',selected);

              this.setState({
                payment_methods: res,   
                payment_method: selected,            
                is_saving: false,
              });

              //fetch the updated totals
              this.fetch('totals')
              .then(function(res){
                console.log('loaded totals',res);            
                
                this.setState({
                  totals: res,
                  loading_totals: false,
                });

                resolve();

              }.bind(this));  

            }.bind(this));
            
          }.bind(this));
        }.bind(this)) 
        .catch(function(err){
          console.log('ERR',err);
          ErrorHandler(err,this.props);
          reject(err);
        }.bind(this))
      });

      return p;
    }else{
      return false;
    }

  }

  handlePaymentMethodChange(ev){
    console.log('handlePaymentMethodChange',ev.target.value);
    
    //get the id, using the name
    var match = this.state.payment_methods.credit_cards.find(m=>m.token==ev.target.value);
    
    console.log('match',match,this.state.payment_methods);
    if(match){

      //update state
      this.setState({
        //handling_method: match,
        loading_totals: true,
      });

      //send to API
      var p = new Promise((resolve,reject)=>{
        DataSource.put('/cart/payment_method', {
          amount:this.state.totals.total.total,
          type:'credit_card',
          token: match.token
        })
        .then(function(res){      
          console.log('updated payment method - res',res); 

          //Get all ccs
          this.fetch('payment_methods')
          .then(function(res){
            console.log('loaded all methods',res);
            var selected = res.credit_cards.find(cc=>cc.selected);
            console.log('selected',selected);

            this.setState({
              payment_methods: res,   
              payment_method: selected,            
              is_saving: false,
              loading_totals: false,
            });

            resolve(true);

          }.bind(this));

        }.bind(this)) 
        .catch(function(err){
          console.log('ERR',err);
          ErrorHandler(err,this.props);
          reject(err);
        }.bind(this))
      });

      return p;
    }else{
      return false;
    }

  }

  handleStoreCreditApply(){
    //update state
    this.setState({
      //handling_method: match,
      loading_totals: true,
    });

    //convert to number (to remove any zeros in front), but tofixed so that we keep the 2 decimals
    var the_amount = Number(this.state.storeCreditInputAmount).toFixed(2);
    this.setState({storeCreditInputAmount:the_amount});

    //send to API
    var p = new Promise((resolve,reject)=>{
      DataSource.put('/cart/payment_method', {
        amount: the_amount,
        type: 'store_credit',        
      })
      .then(function(res){      
        console.log('updated payment method to store credit - res',res); 

        //fetch the payment_methods
        this.fetch('payment_methods')
        .then(function(res){  

          console.log('got back updated from server, setting in state...',res.store_credit);
          this.setState({
            payment_methods:res,
            storeCreditInputAmount: res.store_credit.amount,
          });

          this.props.enqueueSnackbar('$'+Number(res.store_credit.amount).toFixed(2)+' store credit applied',{variant:'success'}); 

          //fetch the updated totals
          this.fetch('totals')
          .then(function(res){
            console.log('loaded totals',res);            
            
            this.setState({
              totals: res,
              loading_totals: false,
            });

            resolve();

          }.bind(this)); 
        }.bind(this))

      }.bind(this)) 
      .catch(function(err){
        console.log('ERR',err);
        ErrorHandler(err,this.props);
        reject(err);
      }.bind(this))
    });

    return p;
  }

  handleUseCreditInputChange(val){
    this.setState({      
      storeCreditInputAmount: val,
    });
  }

  handleShipAddressRemove(index) {
    var p = new Promise((resolve,reject)=>{
      DataSource.delete('/addresses/' + index)
        .then(function(res){
          var copyAddresses = this.state.addresses.slice();
          var resultAddresses = [];
          for(var copyAddress of copyAddresses){
            if(copyAddress._id != index) {
              resultAddresses.push(copyAddress);
            }
          }
          this.setState({
            addresses: resultAddresses
          });
          resolve();
        }.bind(this))
        .catch(function(err){
          console.log('ERR',err);
          ErrorHandler(err,this.props);
          reject(err);
        }.bind(this))
    });

    return p;
  }

  handleShipAddressChange(vals){
    console.log('handleShipAddressChange',vals);
    
    //update state
    this.setState({
      shipping_address: vals,
      loading_totals: true,
    });

    //send to API
    var p = new Promise((resolve,reject)=>{
      DataSource.put('/cart/addresses/shipping', vals)
      .then(function(res){      
        console.log('updated ship address - res',res);          

        //fetch the shipping methods
        this.fetch('shipping_methods')
        .then(function(res){          

          this.setState({
            shipping_methods: res,           
          });

          //Get all ccs
          this.fetch('payment_methods')
          .then(function(res){
            console.log('loaded all methods',res);
            var selected = res.credit_cards.find(cc=>cc.selected);
            console.log('selected',selected);

            this.setState({
              payment_methods: res,   
              payment_method: selected,            
              is_saving: false,
            });

            //fetch the updated totals
            this.fetch('totals')
            .then(function(res){
              console.log('loaded totals',res);            
              
              this.setState({
                totals: res,
                loading_totals: false,
              });

              resolve();

            }.bind(this));   

          }.bind(this));

              

        }.bind(this));

      }.bind(this)) 
      .catch(function(err){
        console.log('ERR',err);
        ErrorHandler(err,this.props);
        reject(err);
      }.bind(this))
    });

    return p;
   
  }

  setCardEditorOpen(){
    console.log('card editor open');
    this.setState({cardEditorOpen:!this.state.cardEditorOpen});
  }

  handleAddCard(vals){
    console.log('handleAddCard',vals);
    
    //update state
    this.setState({
      loading_totals: true,
    });

    //send to API
    var p = new Promise((resolve,reject)=>{
      DataSource.post('/payment_methods/credit_card', {card_number: vals.number.split(' ').join(''), card_exp: vals.exp.split(' ').join(''), card_cvv: Number(vals.cvv), address:vals.address})
      .then(function(res){              

        //fetch the payment_methods
        this.fetch('payment_methods')
        .then(function(res){  

          console.log('got back updated from server, setting in state...',res.store_credit);
          this.setState({
            payment_methods:res,
            storeCreditInputAmount: res.store_credit.amount,
            loading_totals: false,
            cardEditorOpen: false,
          });         

          resolve();
        }.bind(this))

      }.bind(this)) 
      .catch(function(err){
        console.log('ERR',err);
        ErrorHandler(err,this.props);
        reject(err);
      }.bind(this))
    });

    return p;
  }
  

  handleRemoveCard(token){
    console.log('handleAddCard',token);
    
    //update state
    this.setState({
      loading_totals: true,
    });

    //send to API
    var p = new Promise((resolve,reject)=>{
      DataSource.delete('/payment_methods/credit_card/'+token)
      .then(function(res){              

        //fetch the payment_methods
        this.fetch('payment_methods')
        .then(function(res){  

          console.log('got back updated from server, setting in state...',res.store_credit);
          this.setState({
            payment_methods:res,
            storeCreditInputAmount: res.store_credit.amount,
            loading_totals: false,
          });

          resolve();
        }.bind(this))

      }.bind(this)) 
      .catch(function(err){
        console.log('ERR',err);
        ErrorHandler(err,this.props);
        reject(err);
      }.bind(this))
    });

    return p;
  }

  handlePromoCodeApply(code){
    console.log('handlePromoCodeApply',code);
    
    //update state
    this.setState({
      loading_totals: true,
    });

    //send to API
    var p = new Promise((resolve,reject)=>{
      DataSource.post('/cart/discounts/code/',{code})
      .then(function(res){       
        console.log('response from server',res);
        if(res.data.success){

          //Get all ccs
          this.fetch('payment_methods')
          .then(function(res){
            console.log('loaded all methods',res);
            var selected = res.credit_cards.find(cc=>cc.selected);
            console.log('selected',selected);

            this.setState({
              payment_methods: res,   
              payment_method: selected,            
              is_saving: false,
            });

            //fetch the updated totals
            this.fetch('totals')
            .then(function(res){
              console.log('loaded totals',res);            
              
              this.setState({
                totals: res,
                loading_totals: false,
              });

              //show something about it being successful
              this.props.enqueueSnackbar('Discount applied! ',{variant:'success'}); 
              resolve();

            }.bind(this));

          }.bind(this));


          
        }else{
          this.props.enqueueSnackbar(res.data.reason,{variant:'error'}); 
          this.setState({
            loading_totals: false,
          });
        }

         

      }.bind(this)) 
      .catch(function(err){
        console.log('ERR',err);
        ErrorHandler(err,this.props);
        reject(err);
      }.bind(this))
    });

    return p;
  }

  handleClientEmailChange(email){

    console.log('handleClientEmailChange',email);
    //update state
    this.setState({
      loading_totals: true,
    });

    //send to API
    var p = new Promise((resolve,reject)=>{
      DataSource.put('/cart/client_email/',{email})
      .then(function(res){       
        console.log('response from server',res);
        if(res.data.success){

          this.fetch('user')
          .then(function(res){
            console.log('loaded user',res);
            
            this.setState({
              send_status_emails: res.send_status_emails,
              uid: res.id,
              clientEmail: res.cart.shipping.options.client_email,
              loading_totals: false,
            });
      
          }.bind(this));
          
          resolve();
          
        }else{
          this.props.enqueueSnackbar(res.data.reason,{variant:'error'}); 
          this.setState({
            loading_totals: false,
          });
        }         

      }.bind(this)) 
      .catch(function(err){
        console.log('ERR',err);
        ErrorHandler(err,this.props);
        reject(err);
      }.bind(this))
    });

    return p;
  }

  handlePopupOpen(){
    this.setState({             
      show_agreement_popup: true
    });
  }

  handlePopupClose(){
    this.setState({             
      show_agreement_popup: false
    });
  }

  handleSubmit(){


    //manually validate the payment methods
    console.log('totals',this.state.totals,'payment_methods',this.state.payment_methods,'store_credit',this.state.store_credit);

    var card = false;
    if(this.state.payment_methods){
      var cc = this.state.payment_methods.credit_cards.find(c=>c.selected);
      if(cc){
        card = cc;
          console.log('set card',card);
      }else{
        card = false;
      }
    }

    
    if(Number(this.state.totals.total.total)>0 && !card){      
      this.props.enqueueSnackbar("Please select your payment method.",{variant:'error'}); 
      return;
    }

    //put up spinner
    this.setState({             
      submitting: true,
      show_agreement_popup: false,
    });

    //send to api, then handle response
    DataSource.post('/cart/process',{})
    .then(function(res){
      console.log('response from server',res);
      if(res.data.success){

        this.setState({
          loading_totals: false,
          submitting: false,
          show_agreement_popup: false
        });

        //show a order success message
        this.props.enqueueSnackbar('Order placed successfully! ',{variant:'success'});

        //send the Facebook FB pixel purchase event
        if(process.env.REACT_APP_FB_PIXEL_ID){
          ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID);
          ReactPixel.track('Purchase', {
            currency: 'USD',
            value: this.state.totals.total.total,
            contents: this.state.products.map(op=>{return {id:op.name, quantity:op.quantity}})
          }); // For tracking purchase
          console.log('done sending fb pixel');
        }

        //send tapfiliate conversion (if user created after 6-11-20)
        if(moment(res.data.order.user.date_created).isAfter('2020-06-11')){

          let discount_code_used = res.data.order.discounts.find(d=>d.code && d.code.length>0);
          (function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){ (t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');

          let tap = window['tap'];
          tap('create', '14642-e17f17', { integration: "javascript" });
          tap(
            'conversion',
            res.data.order._id,
            this.state.totals.total.total,
            {
              customer_id:res.data.order.user._id,
              coupons:(discount_code_used ? discount_code_used.code : ''),
              meta_data : {
                first_name: res.data.order.user.first_name,
                last_name: res.data.order.user.last_name,
                email: res.data.order.user.email,
              }
            }
          );
        }

        //show the order confirmation page
        this.props.history.push('/confirmation/'+res.data.order._id);

      }else{
        this.props.enqueueSnackbar("Sorry but we couldn't place your order. Error: "+res.data,{variant:'error'});
        this.setState({
          loading_totals: false,
          submitting: false,
          show_agreement_popup: false,
        });
      }
    }.bind(this))
    .catch(function(err){
      console.log('ERR',err);
      ErrorHandler(err,this.props);
      this.setState({
        submitting: false,
        show_agreement_popup: false,
      });
    }.bind(this))
  }

  set_send_status_emails(val){
    //update state
    this.setState({send_status_emails:val});

    //update user in api
    DataSource.patch('/user',{send_status_emails:val})
    .then(function(res){       
      console.log('response from server',res);
      if(res.data.result!='success'){        
        this.props.enqueueSnackbar("Sorry but we couldn't save that setting. Error: "+res.data,{variant:'error'});                
      }
    }.bind(this)) 
    .catch(function(err){
      console.log('ERR',err);
      ErrorHandler(err,this.props);      
    }.bind(this))
  }

  set_marketing_use(val){
    //update state
    this.setState({marketing_use:val});

    //update user in api
    DataSource.put('/cart/comments/marketing_use',{marketing_use:val})
    .then(function(res){       
      console.log('response from server',res);
    //   if(res.data.result!='success'){        
    //     this.props.enqueueSnackbar("Sorry but we couldn't save that setting. Error: "+res.data,{variant:'error'});                
    //   }
    }.bind(this)) 
    .catch(function(err){
      console.log('ERR',err);
      ErrorHandler(err,this.props);      
    }.bind(this))
  }



  fetch(type){
    var url='';
    switch(type){
      case 'products':
        url = '/cart/products';
      break;
      case 'totals':
        url = '/cart/totals';
      break;
      case 'shipping_address':
        url = '/cart/addresses/shipping';
      break;
      case 'billing_address':
        url = '/cart/addresses/billing';
      break;     
      case 'shipping_methods':
        url = '/cart/shipping_method/all';
      break;
      case 'shipping_options':
        url = '/cart/shipping_options';
      break;
      case 'handling_methods':
        url = '/cart/handling_method/all';
      break;
      case 'payment_methods':
        url = '/cart/payment_method/all';
      break; 
      case 'store_credit':
        url = '/payment_methods/store_credit';
      break;
      case 'addresses':
        url = '/addresses';
      break;
      case 'user':
        url = '/user';
      break;
      default:
        throw new Error('No match for the fetch type specified: '+type);
      break;
    }
    /*if(type!='payment_methods'){
      return new Promise((resolve,reject)=>{});
    }*/
    return new Promise(function(resolve,reject){     
      DataSource.get(url)
      .then(function(res){       
        resolve(res.data);
      }.bind(this)) 
      .catch(function(err){
        console.log('ERR',err);
        ErrorHandler(err,this.props);
        reject(err);
      }.bind(this))
    }.bind(this));
  }

  componentDidMount() {

    //set page title
    document.title = 'Kiss Shop - Check Out';

    //check for site live
    SiteLive();

    this.loadAll();

    //fb pixel
    if(process.env.REACT_APP_FB_PIXEL_ID){
      ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID);
      ReactPixel.pageView(); // For tracking page view
    }

  }

  handleProductsUpdate(){
    this.loadAll();
  }

  loadAll(){
    this.setState({             
      loading_totals: true,
      products: []
    });

    //Get the products
    this.fetch('products')
    .then(async function(res){
      console.log('loaded products',res);
      //TODO call method cart/handling_method (POST)
      // for switching type of handling to standard if cart has linen box and rush.
      if (res.errors.length > 0) {
        for (let error of res.errors) {
          this.props.enqueueSnackbar(error,{variant:'error'});
        }
        this.setState({
          blockButton: true,
        });
      }


      //if cart is empty
      if(res.items.length==0){
        this.props.history.push('/products');
        return;
      }
      //first, check to see if we've approved all albums that may be in the cart
      var pids = [];
      res.items.map(p=>{
        //kiss book
        if(p.type=="5c0ed23b7c2d3d2a323e3b8d"){
          /*if(!designs.includes(p.options.ref_design_id)){
            designs.push(p.options.ref_design_id);
          } */
          pids.push(p._id);
        }

      });

      if(pids.length>0){
        var pidstring = pids.join('_');
        if(Cookies.get('products_rendered')!=pidstring){
          //have not approved these pids, so send to approval slideshow
          
        }
      }

      //console.log('designs',designs);

      //add to state
      this.setState({
        products: res.items,
      });


      // LOAD THE REST
      //Get the totals
      this.fetch('totals')
      .then(function(res){
        console.log('loaded totals',res);
        
        this.setState({
          totals: res,
          loading_totals:false,
        });

      }.bind(this));

      //Get the shipping_address
      this.fetch('shipping_address')
      .then(function(res){
        console.log('loaded shipping address',res);
    
        this.setState({
          shipping_address: res,
        });

      }.bind(this));

      //Get the billing_address
      this.fetch('billing_address')
      .then(function(res){
        console.log('loaded billing address',res);
        
        this.setState({
          billing_address: res,
        });

      }.bind(this));

      //Get all shipping_methods
      this.fetch('shipping_methods')
      .then(function(res){
        console.log('loaded shipping methods',res);
        let isShowIncorrectAddressPopup = res.length == 0;
        this.setState({show_incorrect_address_popup: isShowIncorrectAddressPopup})
        this.setState({
          shipping_methods: res,
        });

      }.bind(this));

      //Get shipping options
      this.fetch('shipping_options')
      .then(function(res){
        console.log('loaded shipping_options',res);
        
        this.setState({
          requireSignature: res.require_signature,
        });

      }.bind(this));

      //Get all handling_methods
      this.fetch('handling_methods')
      .then(function(res){
        console.log('loaded all handling methods',res);
        
        this.setState({
          handling_methods: res,
        });

        //TODO Getting total for linen box and rush.
        //Get the totals
        this.fetch('totals')
            .then(function(res){
              console.log('loaded totals',res);

              this.setState({
                totals: res,
                loading_totals:false,
              });

            }.bind(this));

      }.bind(this));

        //Get all ccs
        this.fetch('payment_methods')
            .then(function (res) {
              console.log('loaded payment methods', res);
              this.setState({
                payment_methods: res,
                storeCreditInputAmount: res.store_credit.amount,
              });

            }.bind(this));

      //Get store credit
      this.fetch('store_credit')
      .then(function(res){
        console.log('loaded store credit',res);
        
        this.setState({
          store_credit: res,
        });

      }.bind(this));


      //Get all saved addresses
      this.fetch('addresses')
      .then(function(res){
        console.log('loaded all addresses',res);
        
        this.setState({
          addresses: res.items,
        });

      }.bind(this));

      //Get email status updates toggle status
      this.fetch('user')
      .then(function(res){
        console.log('loaded user',res,'clientEmail',res.cart.shipping.options.client_email);
        
        var mm_use = false;
        if( res.cart.marketing_use && res.cart.marketing_use == true ) {
            mm_use = true;
        }

        this.setState({
          send_status_emails: res.send_status_emails,
          marketing_use: mm_use,
          uid: res.id,
          clientEmail: res.cart.shipping.options.client_email,
        });
  
      }.bind(this));
    }.bind(this));


    //run this every second
    setInterval(this.sendComments.bind(this), 1000);




  }

  render () {   

    const {products, totals, shipping_address, addresses, shipping_methods, 
      handling_methods, payment_methods, store_credit, 
      is_saving, loading_totals, storeCreditInputAmount, send_status_emails, marketing_use, requireSignature, uid,
      clientEmail, comments} = this.state;

      console.log('shipping_methods',shipping_methods);

    //wait for these to load
    if(products && totals && shipping_methods && payment_methods && !is_saving){

      if(products.length == 0){
        return (<div style={{width:'912px'}}>
          <Header location={this.props.location}/>
          <Grid item xs={12}>
          <Grid container justify={'center'} style={{marginTop:'20px'}}>
            <Grid item >
              <Typography>Your cart is empty.</Typography>
              <br/>
              
              <Button type='submit' variant="contained" href='/products'>Shop Now</Button>
            </Grid>
          </Grid>
        </Grid>
        </div>);
      }

      //optionally show these if needed (guest checkout)
      if(!shipping_address.city){
        return (<div><Header location={this.props.location}/><CheckoutShippingAddress handleSubmit={this.handleShippingAddressSubmit.bind(this)} /></div>);
      }else if(!payment_methods.credit_cards.length>0 && !payment_methods.store_credit.amount>0){
        return (<div><Header location={this.props.location}/><CheckoutPayment handleSubmit={this.handlePaymentMethodSubmit.bind(this)}/></div>);
      }else{
        //SHOW THE REVIEW PAGE
        return (<div><Header location={this.props.location}/> <CheckoutReview
        uid={uid}
        products={products}
        totals={totals}
        clientEmail={clientEmail}
        handleClientEmailChange={this.handleClientEmailChange.bind(this)}
        shipping_address={shipping_address}        
        shipping_methods={shipping_methods}        
        handling_methods={handling_methods}
        payment_methods={payment_methods}        
        store_credit={store_credit}
        handleShipMethodChange={this.handleShipMethodChange.bind(this)}
        show_usps_popup={this.state.show_usps_popup}
        show_agreement_popup={this.state.show_agreement_popup}
        handleShipMethodChangeSetUsps={this.handleShipMethodChangeSetUsps.bind(this)}
        show_incorrect_address_popup={this.state.show_incorrect_address_popup}
        handleShipMethodIncorrectAddressPopup={this.handleShipMethodIncorrectAddressPopup.bind(this)}
        handleHandlingMethodChange={this.handleHandlingMethodChange.bind(this)}
        loading_totals={loading_totals}
        handleShipAddressChange={this.handleShipAddressChange.bind(this)}
        handleShipAddressRemove={this.handleShipAddressRemove.bind(this)}
        handlePaymentMethodChange={this.handlePaymentMethodChange.bind(this)}
        handleStoreCreditApply={this.handleStoreCreditApply.bind(this)}        
        handleUseCreditInputChange={this.handleUseCreditInputChange.bind(this)}
        storeCreditInputAmount={storeCreditInputAmount}
        setCardEditorOpen={this.setCardEditorOpen.bind(this)}
        cardEditorOpen={this.state.cardEditorOpen}
        handleAddCard={this.handleAddCard.bind(this)}
        handleRemoveCard={this.handleRemoveCard.bind(this)}
        handlePromoCodeApply={this.handlePromoCodeApply.bind(this)}
        handleSubmit={this.handleSubmit.bind(this)}
        handlePopupOpen={this.handlePopupOpen.bind(this)}
        handlePopupClose={this.handlePopupClose.bind(this)}
        addresses={addresses}
        send_status_emails={send_status_emails}
        set_send_status_emails={this.set_send_status_emails.bind(this)}
        marketing_use={marketing_use}
        set_marketing_use={this.set_marketing_use.bind(this)}
        handleSignatureChange={this.handleSignatureChange.bind(this)}
        requireSignature={requireSignature}
        handleProductsUpdate={this.handleProductsUpdate.bind(this)}
        submitting={this.state.submitting}
        enqueueSnackbar={this.props.enqueueSnackbar}
        comments
        handleCommentsChange={this.handleCommentsChange.bind(this)}
        blockButton={this.state.blockButton}
        /></div>);
      }

    }else{
      return (
        <div><Header location={this.props.location}/> 
        <div style={{textAlign:'center',marginTop:'50px', width:'100%'}}>
          <CircularProgress/><br/>
          {/*<Typography variant='caption'>Loading your items...</Typography>*/}

        </div>
        </div>
      );
    }    
  }
}

export default withSnackbar(Checkout);