import React, { Fragment} from 'react'
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import formatCurrency from '../utils/formatCurrency';
import formatDate from '../utils/formatDate';

function HandlingMethod(props){
    console.log(' handling props',props);
    const {totals, methods, disabled, handleChange,onHandlingSelected} = props;
    console.log('inside handling. methods',methods);
    var selected;
    methods.map(m=>m.selected?selected=m.name:'');
    console.log('handling selected',selected);
    onHandlingSelected(selected);

    return(
        <Fragment>
            <Typography variant='subtitle1'>Handling</Typography>
            <FormControl component="fieldset">            
                                  
                <RadioGroup
                        aria-label="Credit Card"
                        name="cc"
                        value={selected}
                        onChange={handleChange}
                    >
                    {methods.map(m=>(
                        <FormControlLabel key={m.name} value={m.name} control={<Radio disabled={disabled}/>} label={
                            <Fragment>{m.name_pretty} {formatCurrency(m.cost)}<br/>
                                <Typography variant='caption'>
                                    Estimated Ship Date: <strong>{formatDate(m.ship_date)}</strong>
                                </Typography>
                            </Fragment>}
                            style={(selected==m.name ? {} : {opacity:'.25'})}>                                
                        </FormControlLabel>
                    ))}
                </RadioGroup>
                  
            </FormControl>
        </Fragment>
    )
}

export default HandlingMethod;