import React, { Fragment, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  FormikTextField,
  FormikSelectField,
  FormikCheckboxGroupField,
} from "formik-material-fields";
import CountryData from "../utils/countries";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import FilledInput from "@material-ui/core/FilledInput";
import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import DataSource from "../utils/DataSource";
import ErrorHandler from "../utils/ErrorHandler";
import styled from "styled-components";
const StyledButton = styled(Button)`
  color: #ffffff !important;
  font-family: "Montserrat" !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: 41px !important;
  border-radius: 10px !important;
  background-color: #c4b7a3 !important;
  width: 100% !important;
  margin-top: 20px !important;
  &:disabled {
    opacity: 0.5 !important;
  }
`;
const Text = styled.div`
  color: #333;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  padding-top: 25px;
`;

const getSignupSchema = (typeForm) => {
  const rules = {
    name: Yup.string()
      .min(3, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    address: Yup.string()
      .min(3, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    address_2: Yup.string().min(3, "Too Short!").max(50, "Too Long!"),
    city: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    state: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    zip: Yup.string()
      .min(3, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    country: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
  };
  if (typeForm === "singlePageOrder") {
    rules.email = Yup.string().email("Invalid email").required("Required");
  }
  if (typeForm === "shipping" || typeForm === "singlePageOrder") {
    rules.phone = Yup.string()
      .min(7, "Too Short!")
      .max(50, "Too Long!")
      .required("Required");
  }
  return Yup.object().shape(rules);
};

function AddressForm(props) {
  const {
    changeHandler,
    addresses,
    type,
    handleSubmit,
    cancel,
    submit,
    handleClose,
    handleShipAddressRemove,
    allowed,
  } = props;
  const SignupSchema = getSignupSchema(type);
  const the_address = props.address;

  const [countryOptions, setCountryOptions] = useState(false);
  const [stateOptions, setStateOptions] = useState(false);

  const [_id, setId] = useState(the_address ? the_address._id : "");
  const [name, setName] = useState(the_address ? the_address.name : "");
  const [email, setEmail] = useState(the_address ? the_address.email : "");
  const [company, setCompany] = useState(
    the_address ? the_address.company : ""
  );
  const [address, setAddress] = useState(
    the_address ? the_address.address : ""
  );
  const [address_2, setAddress2] = useState(
    the_address ? the_address.address_2 : ""
  );
  const [city, setCity] = useState(the_address ? the_address.city : "");
  const [state, setState] = useState(the_address ? the_address.state : "AZ");
  const [country, setCountry] = useState(
    the_address ? the_address.country : "United States"
  );

  const [zip, setZip] = useState(the_address ? the_address.zip : "");
  const [phone, setPhone] = useState(the_address ? the_address.phone : "");
  const [is_default, setIsDefault] = useState(
    the_address ? the_address.is_default : false
  );

  //handler for country change
  function handleCountryChange(e) {
    setCountry(e.target.value);
  }

  //handler for state change
  function handleStateChange(e) {
    setState(e.target.value);
  }

  function handleDefaultChange(val) {
    setIsDefault(val);
  }

  function handleChooseSaved(e, index) {
    console.log("chose saved.", index);
    var the_address = addresses.find((a) => a._id == index);
    var vals = {
      _id: the_address._id,
      name: the_address.name,
      company: the_address.company,
      address: the_address.address,
      address_2: the_address.address_2,
      city: the_address.city,
      state: the_address.state,
      country: the_address.country,
      zip: the_address.zip?.trim(),
      phone: the_address.phone,
      is_default: the_address.is_default,
    };
    handleSubmit(vals);
    handleClose();
  }

  function handleDeleteShippingAddressClick(e, index) {
    handleShipAddressRemove(index);
    handleClose();
  }

  //run every time the props address changes
  useEffect(() => {
    //set the country list
    var foundCountries = CountryData("countries").map(function (cn) {
      return { label: cn.name, value: cn.name };
    });
    console.log("foundCountries", foundCountries);
    setCountryOptions(foundCountries);
  }, [state]);

  //run whenever address is changed
  useEffect(() => {
    if (changeHandler) {
      changeHandler({
        _id,
        name,
        company,
        address,
        address_2,
        city,
        state,
        country,
        zip,
        phone,
        is_default,
      });
    }
    console.log(props.isSubmitting);
  }, [
    _id,
    name,
    company,
    address,
    address_2,
    city,
    state,
    country,
    zip,
    phone,
    is_default,
  ]);

  //run every time the country changes
  useEffect(() => {
    //find the country
    var found_country = CountryData("country", country);
    console.log("found_country", found_country);
    var states = CountryData("states", found_country.id).map((s) => {
      return { label: s.name, value: s.name };
    });
    console.log("states", states);
    setStateOptions(states);

    //if country does not have states then empty this
    if (!states || !states.length > 0) {
      console.log("empty the state");
      setState("");
    }

    //console.log('Country',CountryData.getAllCountries());
  }, [country]);

  //don't render if not set
  if (!countryOptions) {
    return null;
  }

  //console.log('cda',cda.getCountries());
  //console.log('cda',cda.fromCountryCode('GB'));
  //console.log('addressForm addresses',addresses);
  return (
    <Formik
      initialValues={
        address
          ? {
              _id: _id,
              name: name,
              company: company,
              address: address,
              address_2: address_2,
              city: city,
              state,
              country,
              zip: zip,
              phone: phone,
              is_default: is_default ? "true" : "false",
            }
          : { country: "United States", state: "Alabama" }
      }
      validationSchema={SignupSchema}
      onSubmit={(values, actions) => {
        if (values.is_default == null) {
          // set false for correct saving to DB instead of null
          values.is_default = false;
        }
        if (values.zip){
          values.zip = values.zip?.trim();
        }
        //check for state
        if (!state || !state.length > 0) {
          values.state = "";
        }
      
        handleSubmit(values);
        if (handleClose) {
          handleClose();
        }
        actions.setSubmitting(false);
      }}
      render={(props) => (
        <div>
          {addresses.length > 0 && (
            <Fragment>
              <Typography>Choose a Saved Address:</Typography>
              <FormControl fullWidth>
                <InputLabel htmlFor="existing">Saved Addresses</InputLabel>
                <Select
                  variant="filled"
                  margin="normal"
                  // onChange={e=>handleChooseSaved(e)}
                  input={
                    <FilledInput fullWidth id="existing" margin="normal" />
                  }
                  fullWidth
                >
                  {addresses.map((a) => (
                    <MenuItem value={a._id}>
                      <InputLabel onClick={(e) => handleChooseSaved(e, a._id)}>
                        {a.name + ", " + a.city + " " + a.state}
                      </InputLabel>
                      <Tooltip title="Delete address Info">
                        <IconButton
                          onClick={(e) =>
                            handleDeleteShippingAddressClick(e, a._id)
                          }
                          aria-label="Delete"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br />
              <br />
              <Typography>- OR -</Typography>
            </Fragment>
          )}

          <Form>
            <FormikTextField
              name="name"
              variant="filled"
              label="Name"
              margin="normal"
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
            <FormikTextField
              name="company"
              variant="filled"
              label="Company"
              margin="normal"
              fullWidth
              onChange={(e) => setCompany(e.target.value)}
            />
            {type === "singlePageOrder" && (
              <FormikTextField
                variant="filled"
                fullWidth
                label="Email"
                name="email"
                margin="normal"
                onChange={(e) => setEmail(e.target.value)}
              />
            )}
            <FormikTextField
              name="address"
              variant="filled"
              label="Address"
              margin="normal"
              fullWidth
              onChange={(e) => setAddress(e.target.value)}
            />
            <FormikTextField
              name="address_2"
              variant="filled"
              label="Address 2"
              margin="normal"
              fullWidth
              onChange={(e) => setAddress2(e.target.value)}
            />
            <FormikSelectField
              name="country"
              variant="filled"
              label="Country"
              margin="normal"
              options={countryOptions}
              onChange={(e) => handleCountryChange(e)}
              native
              fullWidth
            />
            <Grid container spacing={16}>
              <Grid item xs={6}>
                <FormikTextField
                  name="city"
                  variant="filled"
                  label="City"
                  margin="normal"
                  fullWidth
                  onChange={(e) => setCity(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                {stateOptions && stateOptions.length > 0 && (
                  <FormikSelectField
                    name="state"
                    variant="filled"
                    label="State"
                    margin="normal"
                    options={stateOptions}
                    onChange={(e) => handleStateChange(e)}
                    native
                    fullWidth
                  />
                )}
              </Grid>
            </Grid>
            <Grid container spacing={16}>
              <Grid item xs={6}>
                <FormikTextField
                  name="zip"
                  variant="filled"
                  label="Zip"
                  margin="normal"
                  fullWidth
                  onChange={(e) => setZip(e.target.value?.trim())}
                />
              </Grid>
              <Grid item xs={6}>
                {(type === "shipping" || type === "singlePageOrder") && (
                  <FormikTextField
                    name="phone"
                    variant="filled"
                    label="Phone"
                    margin="normal"
                    fullWidth
                    onChange={(e) => setPhone(e.target.value)}
                  />
                )}
              </Grid>
            </Grid>
            {type !== "singlePageOrder" && (
              <>
                <FormikCheckboxGroupField
                  name="is_default"
                  variant="filled"
                  margin="normal"
                  options={[
                    { label: "Save as Primary Address", value: "true" },
                  ]}
                />
                {props.isValid && (
                  <FormikCheckboxGroupField
                    name="save"
                    variant="filled"
                    margin="normal"
                    options={[{ label: "Save For Later", value: "true" }]}
                  />
                )}
              </>
            )}
            {type === "shipping" && (
              <div style={{ textAlign: "right" }}>
                <Button onClick={handleClose} color="secondary">
                  {cancel ? cancel : "Cancel"}
                </Button>
                <Button
                  onClick={props.handleSubmit}
                  color="primary"
                  type="submit"
                  disabled={
                    !!props.isSubmitting
                      ? props.isSubmitting || !props.isValid
                      : !props.isValid
                  }
                >
                  {submit ? submit : "Submit"}
                </Button>
              </div>
            )}
            {type === "singlePageOrder" && (
              <>
                <StyledButton
                  color="primary"
                  type="submit"
                  disabled={
                    !!props.isSubmitting
                      ? props.isSubmitting || !props.isValid || !allowed
                      : !props.isValid || !allowed
                  }
                  onClick={props.handleSubmit}
                >
                  Order Now!
                </StyledButton>

                <Text>
                  By placing your order you agree to our&nbsp;
                  <a href="https://my.kiss.us/login/terms_of_service">terms and conditions</a>
                  &nbsp;and&nbsp;
                  <a href="https://my.kiss.us/home/privacy_policy">privacy policy</a>
                </Text>
              </>
            )}
          </Form>
        </div>
      )}
    />
  );
}

export default AddressForm;
