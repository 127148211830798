import React from 'react';
import Typography from '@material-ui/core/Typography';
import Products from '../components/productList';

function OrderProducts(props){
    const {products} = props;
        
    return (
        <div>            
            <Typography variant='h6'>Items</Typography>
            <Typography>
                <Products products={products} expansion={false} />
            </Typography>            
        </div>       
    )
}

export default OrderProducts;