import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import React  from "react";

// Show any popup with message
function PopUp(props) {

    const {setShow} = props;

    return (
        <Dialog open={props.show} onClose={e => setShow(false)}>
            <DialogContent>
                <Typography>
                    {props.message}
                </Typography>
            </DialogContent>
        </Dialog>
    )
}

export default PopUp;