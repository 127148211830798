import React, { Fragment} from 'react'
import { withStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DataSource from '../utils/DataSource';
import formatDate from '../utils/formatDate';
import BreadCrumbs from '../components/breadCrumbs';
import ShippingAddress from '../components/orderShippingAddress';
import ShippingMethod from '../components/orderShippingMethod';
import HandlingMethod from '../components/orderHandlingMethod';
import PaymentMethod from '../components/orderPaymentMethod';
import Totals from '../components/totalsDisplay';
import Products from '../components/orderProducts';
import Comments from '../components/orderComments';
import Header from '../components/header';

import MobileConfirmation from '../components/mobileConfirmation';

const styles = theme => ({});

class OrderConfirmation extends React.Component {

  constructor(props) {
    super(props);
    console.log('orderconfirmation props',props);   
  }
  
  state = {
    order:false
  };

  componentDidMount() {   
    const path_nodes = this.props.location.pathname.split('/');
    const final = path_nodes[path_nodes.length-2];


    //pull from api 
    DataSource.get('/orders/'+this.props.match.params.id+'?full=true')
    .then(function(res){
        console.log('RES',res);
        this.setState({order: res.data});

        //set page title
        document.title = 'Kiss Shop - Order #'+res.data.order_id;

    }.bind(this)) 
    .catch(function(err){
        console.log('ERR',err);
    })
  }
    
  render () {
    const {order} = this.state;
    if(!order){
      return null;
    }
    
    const totals = [];
    order.totals.map(t=>totals[t.type]={total:t.amount});
    if(order.discounts && order.discounts.length > 0){
      totals.discounts = order.discounts.map(d=>{return {total:d.amount, name:d.name}});
      console.log('order.totals.discounts',order.totals.discounts);
    }
    console.log('parsed totals=',totals);

    var freeSampleProducts = [];
    order.products.map((product)=>{
      if (product.type == 'free_sample_swatches') {
        freeSampleProducts.push(product);
      }
    });
    var onlyFreeSampleSwatches = (order.products.length == freeSampleProducts.length)

    return (
      <MobileConfirmation content={
      
          <Fragment>        

            <div style={{textAlign:'center'}}>
              <a href={process.env.REACT_APP_DASHBOARD_URL}><img src='/images/branding/kiss-horiz.svg' width='150' alt='Kiss Books'/></a>
            </div>
          
            <Typography variant='h6'>Order Confirmation</Typography>        
            <Typography>Invoice #{order.order_id}</Typography>         
            <Typography>Ordered {formatDate(order.date_created)} | Order #{order.order_id}</Typography>
            <br/>
            {/* Place for notifications */}
            <div style={{float:'right', marginTop:'-50px', textAlign:'right'}}>
              
                <Button onClick={e=>window.print()}>Print</Button>
            
            </div>
            {(order) ? 
              
            <Grid container spacing={32}>
              <Grid item xs={6}>
                <ShippingAddress address={order.shipping_address} />
              </Grid>
              <Grid item xs={6}>
                <PaymentMethod
                methods={order.balance_events.filter(be=>be.type=='payment').map(p=>p.meta.type)}
                display={!onlyFreeSampleSwatches}
                />
              </Grid>

              <Grid item xs={6}>
                <ShippingMethod method={order.shipping_method}/>
              </Grid>
              <Grid item xs={6}>
                <HandlingMethod method={order.handling_method} />
              </Grid>

              <Grid item xs={12}>
                <Comments comments={order.comments}/>
              </Grid>
              
              <Grid item xs={6}>                
                <Products products={order.products}/>
              </Grid>
              <Grid item xs={6}>
                <Totals totals={totals} discounts={order.discounts}/>
              </Grid>      
            </Grid>
            :
            <CircularProgress/>
          } 
          </Fragment>
      }/>
    )
  }
}

export default withStyles(styles)(OrderConfirmation);