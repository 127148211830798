import React, {useState} from 'react';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {Typography} from '@material-ui/core';
import companyTheme from '../../theme/companyBaseTheme';
import styled from "styled-components";

const Wrapper = styled.div`
    border: ${prop => prop.border};
    padding: 20px;
    height: 248px;
    background: ${prop => prop.background};
    background-size: 100% 97px;
`

function LargeOption(props) {

    const {name, description, buttonText, handleButton, added, background} = props;
    const [code, setCode] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    function handler(e) {

        e.preventDefault();
        console.log('stopped default', e, code);

    }

    return (
        <Wrapper background={background} border={'1px '+(added?'solid '+companyTheme.palette.primary.main:'dashed #e8dfce')}>

            <div style={{
                height: '172px'
            }}>
                <Typography variant={'h6'}
                            style={added ? {paddingTop: '80px'} : {marginTop: '-5px'}}>{name}</Typography>
                <Typography variant='caption'>{description}</Typography>
            </div>
            <div style={{
                height: '70px'
            }}>
                <Button variant='contained' onClick={handleButton}>{buttonText}</Button>
            </div>


        </Wrapper>


    );

}

export default LargeOption;