import React, { Fragment, useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormikTextField, FormikSelectField, FormikCheckboxGroupField } from 'formik-material-fields';
import CountryData from "../../utils/countries";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import DataSource from '../../utils/DataSource';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import FilledInput from '@material-ui/core/FilledInput';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import CircularProgress from '@material-ui/core/CircularProgress';


const FormSchema = Yup.object().shape({   
    state: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    zip: Yup.string()
      .min(3, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    country: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required')
    
  });
  

function ShippingEstimator(props){ 

    const {changeHandler, address, shipId, weight, addressChangeHandler} = props;

    const the_address = (props.address && props.address.state ? props.address : undefined);

    const [countryOptions, setCountryOptions] = useState(false);   
    const [stateOptions, setStateOptions] = useState(false);   

    const [isSubmitting, setIsSubmitting] = useState(false);  

    const [methodChoices, setMethodChoices] = useState([]);  
    
    const [loadingEstimates, setLoadingEstimates] = useState(false);   
    

    
     
    const [state, setState] = useState(the_address ? the_address.state : 'Alabama');  
    const [country, setCountry] = useState(the_address ? the_address.country : 'United States');       
    const [zip, setZip] = useState(the_address ? the_address.zip : '');    
    
    
    const [selected, setSelected] = useState('');  
    const [selectedMethod, setSelectedMethod] = useState({});  
    
    





    //handler for country change
    function handleCountryChange(e){
      setCountry(e.target.value);
    }

    //handler for state change
    function handleStateChange(e){
      setState(e.target.value);
    }

    async function handleShipMethodChange(ev){
        let method  = ev.target.value;
        console.log('handleShipMethodChange',method);
        await setSelected(method);

        let price = 0;
        let id = '';
        methodChoices.map(t=>t.methods.map((m)=>{
            if(m.name==method){
                console.log('handleShipMethodChange',m.name,'==',method);
                price=m.cost;
                id=m._id;
            }
        }
        ));
        changeHandler(id, price);
    }

    useEffect(() => {
        //set the country list
        const foundCountries = CountryData("countries").map(function (cn) {
            return { label: cn.name, value: cn.name };
        });
        setCountryOptions(foundCountries);
    }, [state]);

    useEffect(() => {
        const found_country = CountryData("country", country);
        const states = CountryData("states", found_country.id).map((s) => {
            return { label: s.name, value: s.name };
        });
        setStateOptions(states);

        //if country does not have states then empty this
        if (!states || !states.length > 0) {
            setState("");
        }

    }, [country]);

    //run every time we get method choices
    useEffect(() => {
        if(methodChoices.length>0){
            console.log('handle methodchoices changed');
            let selected_method = {};
            methodChoices.map(t=>t.methods.map(m=>m.selected?selected_method={...m,...{carrier:t.type}}:''));
            if(!selected_method.carrier){
                console.log('no selected method - set the first one');
                selected_method = {...methodChoices[0].methods[0],...{carrier:methodChoices[0].type}};
                handleShipMethodChange({target:{value:selected_method.name}});
            }
            
            console.log('method choices',methodChoices);
            console.log('selected_method',selected_method);
            let selected = selected_method.name;
            console.log('selected',selected);
            setSelected(selected);
        }
        
    }, [methodChoices]);  
    
    
    //run once, on load
    useEffect(() => {
        if(address){            

            if(weight){
                let data = {
                    weight,
                    country,
                    state,
                    zip,
                }
                setLoadingEstimates(true);

                DataSource.post('/tools/calculator/shipping_estimate', data)
                .then(function(res){
                    console.log('shipping estimate results:',res);

                    //if shipId, then apply it
                    if(shipId){
                        console.log('has ship id, look for match');
                        let match = '';
                        res.data.map(t=>t.methods.map(m=>m._id==shipId?m.selected=true:''));
                        console.log('foiund match?',JSON.stringify(res.data));
                       
                    }

                    //set choices
                    setMethodChoices(res.data);
                    setLoadingEstimates(false);

                    
                });
            }
        }
        
    }, []);  
    
    
      
    //don't render if not set
    if(!countryOptions){
      return null;
    }

    return (
        
        <Formik
        initialValues={(address ? 
            {            
            state,
            country,
            zip, 
        }
        :
        {country:'United States'})}
        validationSchema={FormSchema}
        onSubmit={(values, actions) => {          
            console.log('submitted values',values);
            setLoadingEstimates(true);

            //hit API to get quotes
            let data = {
                weight,
                country:values.country,
                state:values.state,
                zip:values.zip,
            }

            //pull from api 
            DataSource.post('/tools/calculator/shipping_estimate', data)
            .then(function(res){
                console.log('shipping estimate results:',res);
                setMethodChoices(res.data);
                setLoadingEstimates(false);
            });

            //notify handler of address change
            addressChangeHandler({               
                country:values.country,
                state:values.state,
                zip:values.zip,
            })


        }}
        render={props=>(
        
            <div> 
                
                <Form>
                    <Grid container spacing={16}>
                        <Grid item xs={6}>
                            <Typography variant='subtitle1'>Step 1: Enter Shipping Info</Typography> 
                            <FormikSelectField name="country"  variant="filled" label='Country' margin='normal' options={countryOptions} onChange={e=>handleCountryChange(e)} native fullWidth />              
                            <FormikSelectField name="state"  variant="filled" label='State' margin='normal' options={stateOptions} onChange={e=>handleStateChange(e)} native fullWidth />                                      
                            <FormikTextField name="zip"  variant="filled" label='Zip' margin='normal' fullWidth onChange={e=>setZip(e.target.value)}/>
                            <div style={{textAlign:'right'}}>
                                <Button onClick={props.handleSubmit} variant="outlined" type="submit" disabled={loadingEstimates || !props.isValid}>Get Estimates</Button>
                            </div>                            
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='subtitle1' style={methodChoices.length ? {} : {opacity:'.5'}}>Step 2: Choose Shipping Method</Typography>
                            {loadingEstimates &&
                                <div style={{textAlign:'center'}}>
                                    <CircularProgress size={20} style={{marginTop:'50px'}}/>
                                </div>
                            }
                            {(methodChoices && !loadingEstimates) &&
                                <Fragment>                                    
                                    <FormControl component="fieldset" style={{marginTop:'10px'}}>            
                                        
                                        <RadioGroup
                                        aria-label="Shipping Method"
                                        name="shipping_method"
                                        value={selected}
                                        onChange={handleShipMethodChange}
                                        style={{marginLeft:'20px'}}
                                        >
                                        {methodChoices.map(method=>(
                                                
                                            method.methods.map((m,i)=>(
                                                
                                            <FormControlLabel 
                                            value={m.name} 
                                            control={<Radio />} 
                                            label={
                                                <Fragment>{m.name_pretty+(m.name_pretty=='Local Pickup' ? ' In Indianapolis' : '')}<br/>
                                                    
                                                    <Typography variant='caption'>
                                                        <strong>${Number(m.cost).toFixed(2)}</strong>
                                                    </Typography>
                                                    
                                                </Fragment>
                                            }
                                            style={(selected==m.name ? {} : {opacity:'.25'})}
                                            />
                                                
                                            ))                        
                                                
                                        ))}
                                        </RadioGroup>                              
                                        
                                    </FormControl> 
                                </Fragment>
                                
                            }
                        </Grid>
                                                   
                    </Grid>                   
                    
                </Form>  

                
                          
            </div>
        )}
        />            
       
    );

}


export default ShippingEstimator;