import React, { useState, Fragment, useEffect } from 'react';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FilledInput from '@material-ui/core/FilledInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import { Typography } from '@material-ui/core';
import LargeOption from './LargeOption.js';
import SmallOption from './SmallOption.js';
import companyTheme from '../../theme/companyBaseTheme';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function BoxOptions(props){

    //handlers
    const {handleApply, handleCancel} = props;

    //state hooks, using the props as the defaults and control-values     
    const [type, setType] = useState(props.type);
    const [size, setSize] = useState(props.size);
    const [engraving, setEngraving] = useState(props.engraving);
    const [debossing, setDebossing] = useState(props.debossing);
    const [debossingSize, setDebossingSize] = useState(props.debossingSize);
    const [logoDebossing, setLogoDebossing] = useState(props.logoDebossing);

    let stateHooks={
        setType,
        setSize,
        setEngraving,
        setDebossing,
        setDebossingSize,
        setLogoDebossing
    }

    function handleSwitchChange(event){
        console.log('event',event,event.target,event.target.name,event.target.checked);
        var name = event.target.name;
        let setter = 'set'+name.charAt(0).toUpperCase() + name.slice(1);
        console.log('setter',setter);
        stateHooks[setter](event.target.checked);
    };
    
    function handleSelectChange(event){
        var name = event.target.name;   
        console.log('select change. setting',name,event.target.value);
        //this.setState({[name]: event.target.value});    
        let setter = 'set'+name.charAt(0).toUpperCase() + name.slice(1);
        console.log('setter',setter);
        stateHooks[setter](event.target.value);
    };

    function handleSubmit(e){
        handleApply({
            type,
            size,
            engraving,       
            debossing,
            debossingSize,
            logoDebossing
        })
    }

    function handleRemove(e){
        handleApply({
            type:'',
            size:'',
            engraving:false,       
            debossing:false,
            logoDebossing:false
        })
    }

    useEffect(()=>{
        if(engraving && debossing){
            console.log('turning off debossing');
            setDebossing(false);
        }
    },[engraving])

    useEffect(()=>{
        if(engraving && debossing){
            console.log('turning off engraving');
            setEngraving(false);
        }
    },[debossing])

    return (       
    
        <Dialog open={true} maxWidth='sm' fullWidth onClose={handleCancel}>
            <DialogTitle id="form-dialog-title">Box Options 
                <div style={{float:'right',textAlign:'right'}}>
                    <Button onClick={handleRemove} >Remove</Button>
                </div>
            </DialogTitle>
            <DialogContent>
                
                <FormControl variant='filled' margin='normal' fullWidth>
                    <InputLabel>Box Type</InputLabel>
                    <Select
                    value={type}
                    onChange={handleSelectChange}                    
                    input={<FilledInput name="type" labelId='type'/>}
                    >                          
                        {size!='4' && <MenuItem value='walnut'>Walnut</MenuItem>}
                        {(props.bookCoverMaterial=='linen' && size!='4') && <MenuItem value='linen'>Linen</MenuItem>}
                        <MenuItem value='presentation'>Presentation</MenuItem>                
                       
                    </Select>
                </FormControl><br/>
                
                {/*
                <FormControl variant='filled' margin='normal' fullWidth>
                    <InputLabel htmlFor="size">Box Size</InputLabel>
                    <Select
                    value={size}
                    onChange={handleSelectChange}
                    input={<FilledInput name="size" id="size" />}
                    >                       
                        {type!='linen' &&
                        <MenuItem value='4'>4"</MenuItem>
                        }
                        <MenuItem value='8'>8"</MenuItem>
                        <MenuItem value='10'>10"</MenuItem>
                        <MenuItem value='12'>12"</MenuItem>
                    </Select>
                    </FormControl><br/>*/}

                <Grid container>
                    <Grid item xs={9} style={{paddingTop:'12px'}}><FormLabel><Typography>Box Engraving</Typography></FormLabel></Grid>
                    <Grid item xs={3} style={{textAlign:'right'}}><Switch
                    name='engraving'                    
                    checked={engraving}
                    onChange={handleSwitchChange}
                    size="small"
                    value="true"
                    color="primary"
                    /></Grid>
                </Grid>

                {(type=='linen' || type=='presentation') &&
                <Grid container>
                    <Grid item xs={9} style={{paddingTop:'12px'}}><FormLabel><Typography>Box Debossing</Typography></FormLabel></Grid>
                    <Grid item xs={3} style={{textAlign:'right'}}><Switch
                    name='debossing'
                    checked={debossing}
                    onChange={handleSwitchChange}
                    size="small"
                    value="true"
                    color="primary"
                    /></Grid>
                </Grid>
                }

                {debossing &&
                <FormControl variant='filled' margin='normal' fullWidth>
                    <InputLabel htmlFor="">Debossing Size</InputLabel>
                    <Select
                    value={debossingSize}                   
                    onChange={handleSelectChange}
                    input={<FilledInput name="debossingSize" id="debossingSize" />}
                    >                     
                    
                        <MenuItem value='5x1'>5"x1"</MenuItem>
                        <MenuItem value='5x2'>5"x2"</MenuItem>
                        <MenuItem value='5x4'>5"x4"</MenuItem>
                                    
                    </Select>
                </FormControl>
                }


                {type=='linen' &&
                <Grid container>
                    <Grid item xs={9} style={{paddingTop:'12px'}}><FormLabel><Typography>Logo Debossing</Typography></FormLabel></Grid>
                    <Grid item xs={3} style={{textAlign:'right'}}><Switch
                    name='logoDebossing'                    
                    checked={logoDebossing}
                    onChange={handleSwitchChange}
                    size="small"
                    value="true"
                    color="primary"
                    /></Grid>
                </Grid>
                }

            </DialogContent>
            <DialogActions>                
                <Button onClick={handleCancel} >
                Cancel
                </Button>
                <Button onClick={handleSubmit} variant='contained'>
                Apply
                </Button>
            </DialogActions>
        </Dialog>       
       
        );
    
    }
    
    export default BoxOptions;