import React, { Fragment, useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import PrettyLink from '../components/prettyLink';

function OrderShippingMethod(props){
    const {method} = props;
        
    return (
        <div>            
            <Typography variant='h6'>Shipping Method</Typography>
            <Typography>{method.name_pretty}</Typography>
            {method.name_pretty=='Local Pickup' &&
                <Typography variant='caption'>
                    <br/><b>Pick up at Kiss:</b><br/>
                    Kiss Books<br/>5470 W 84th St<br/>Indianapolis, IN 46268<br/>
                    <PrettyLink target='_blank' href='https://www.google.com/maps?hl=en&q=Kiss+Books,+5470+W+84th+St,+Indianapolis,+IN+46268,+USA'><i>View Map</i></PrettyLink>

                </Typography>
            }
        </div>       
    )
}

export default OrderShippingMethod;