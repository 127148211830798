import React, { useEffect, useState, Component } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Style from 'style-it';

function fetch_item_count(token) {

    var store_products_url = (process.env.REACT_APP_STORE_PRODUCTS_URL ? process.env.REACT_APP_STORE_PRODUCTS_URL : "http://localhost:3000/cart/products");

    //set axios config  
    axios.defaults.withCredentials = false;
    axios.defaults.headers = {
        'Authorization': 'bearer ' + token,
        'Content-Type': 'application/json'
    };
    //console.log('getting ready to send...', axios.defaults);

    return new Promise(function (resolve, reject) {
        axios.get(store_products_url)
            .then(function (response) {
                // handle success
                //console.log('cart products',response);
                //console.log('count=',response.data.items.length);
                var amount = 0;
                let bundles = ['box_set', 'parent_set', 'gift_set'];
                response.data.items.map(item => {
                    if (!bundles.includes(item.type.name)) {
                        amount += Number(item.quantity);
                    }
                })
                resolve(amount);
            })
            .catch(function (err) {
                console.log('error:', err);
                // handle error      
                reject(err);
            })

    });
}

function Header(props) {
    const { comments } = props;

    const [items, setItems] = useState('');
    const [triedReauth, setTriedReauth] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const token = Cookies.get((process.env.REACT_APP_STORE_USER_TOKEN ? process.env.REACT_APP_STORE_USER_TOKEN : 'store_user_token'));

    useEffect(() => {
        //console.log('token',token);
        if (token) {
            fetch_item_count(token)
                .then(count => setItems(count))
                .catch(err => {
                    console.log('fetch item count err', err)
                });
        }
    }, [loaded]);

    //periodically flip loaded to trigger updating the counter if needed
    setTimeout(function () {
        setLoaded(!loaded);
    }, 5000);

    return (
        <Style>
            {`
            .cart{
                float:right;
                margin-top:13px;
                margin-left:10px;
            }
            
            .cart_count{                    
                clear:both;
                background:#ebe0c9;
                width:20px;
                height:20px;
                line-height:20px !important;
                font-family: 'Montserrat', sans-serif;
                font-size:12px;
                text-align:center;               
                font-weight:bold;
                text-decoration:none;
                -moz-border-radius: 50%;  
                -webkit-border-radius: 50%;  
                border-radius: 50%;                                        
                margin:-40px 0px 0px 4px;
                z-index:100;
                float:left;
                position:absolute;                                            
            }
            `}
            <a id="cart" className='cart' href="/cart">
                <img src="/images/shopping-cart.svg" alt=""></img>
                <div id='cart_count' className='cart_count'>
                    {items}
                </div>
            </a>
        </Style>
    )
}

export default Header;





