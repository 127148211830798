import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import DataSource from '../utils/DataSource';
import PrettyLink from '../components/prettyLink';
import BreadCrumbs from '../components/breadCrumbs';
import moment from 'moment';
import ShippingMap from "../components/shippingMap";
import Header from '../components/header';
import Products from '../components/orderProducts';
import tu from 'tracking-url';
import { getCarrier, getCarrierLink } from 'shipmethod';
import SiteLive from '../utils/siteLive';
import ReactPixel from 'react-facebook-pixel';

const styles = theme => ({});

class OrderTrack extends React.Component {

    constructor(props) {
        super(props);
        console.log('orderconfirmation props', props);
    }

    state = {
        order: false,
        view_type: 'standard'
    };

    componentDidMount() {

        //check for site live
        SiteLive();

        //fb pixel
        if (process.env.REACT_APP_FB_PIXEL_ID) {
            ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID);
            ReactPixel.pageView(); // For tracking page view
        }

        let params = new URLSearchParams(this.props.location.search);
        if (params.get("type")) {
            this.setState({ 'view_type': params.get("type") });
        }

        //pull from api 
        DataSource.get('/orders/' + this.props.match.params.id + '?full=true')
            .then(function (res) {
                console.log('RES', res);
                this.setState({ order: res.data });

                //set page title
                document.title = 'Kiss Shop - Order #' + res.data.order_id;

                var tracking_data = [];

                if (this.state.order.tracking_numbers.length > 0) {
                    //this.state.order.tracking.map(te=>console.log(te.Address))

                    //get the tracking history / data for the tracking number(s)            
                    this.state.order.tracking_numbers.map(t => {

                        if (t != null && t != 'test123') {

                            if (t.length == 34) {
                                var short = t.substr(-12);

                                var info = {
                                    name: getCarrier(short),
                                    url: getCarrierLink(getCarrier(short), short),
                                    number: short
                                }

                            } else {
                                var info = tu(t);
                                info.number = t;
                            }

                            console.log(t, info);
                            tracking_data.push({ carrier: info.name.toUpperCase(), url: info.url, number: info.number })

                        }

                    });



                }

                //set step content
                this.setState({
                    content: {
                        '0': '',
                        '1': (this.state.order.production_percent > 0 ? this.state.order.production_percent + '% Complete' : ''),
                        '2': tracking_data.map(td =>
                            <Fragment>{td.carrier + ' Tracking #'}
                                <PrettyLink href={td.url} target="_blank">{td.number}</PrettyLink>
                                <br />
                                <Fragment>Estimate: {moment(this.state.order.estimated_delivery).format('M/D/YYYY')}</Fragment>
                            </Fragment>),
                        '3': (this.state.order.delivered ? 'Delivered ' + moment(this.state.order.delivered_date).format('M/D/YYYY') : ''),
                    }
                })

                if (this.state.order.delivered) {
                    var clone = Object.assign({}, this.state.order);
                    clone.status = 'arrived';
                    this.setState({ order: clone });
                }

            }.bind(this))
            .catch(function (err) {
                console.log('ERR', err);
            })
    }

    getContent(index) {

        return (this.state.content ? this.state.content[index] : '');
    }



    render() {
        const { order } = this.state;
        if (!order) {
            return null;
        }

        const production_percent = order.production_percent;
        const status = order.status;
        const status_map = {
            pending: 0,
            paid: 0,
            production: 1,
            shipped: 2,
            arrived: 3,
        }
        const steps = [
            'Ordered',
            'In Production',
            'Shipped',
            'Arrived',
        ];
        const activeStep = status_map[status];


        return (
            <div>

                <Fragment>
                    <Header location={this.props.location} />
                    <BreadCrumbs>Order #{order.order_id}</BreadCrumbs>
                </Fragment>

                <Typography variant='h6'>Track Order</Typography>
                <Grid container>
                    <Grid item xs>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                    {activeStep == index &&
                                        <StepContent>
                                            <Typography>{this.getContent(index)}</Typography>
                                        </StepContent>
                                    }
                                </Step>
                            ))}
                        </Stepper>
                        <br />
                        <br />
                        <Button component={Link} style={{ marginBottom: '10px' }} variant='outlined' to={'/confirmation/' + order.id}>Order Details</Button><br />
                    </Grid>
                    <Grid item xs>


                        {/*{(activeStep > 1 && this.state.order.tracking && this.state.order.destination) &&*/}
                        {/*<Fragment>*/}
                        {/*    <ShippingMap                  */}
                        {/*    coords={this.state.order.tracking.map(t=>t.geo)}*/}
                        {/*    dest={this.state.order.destination.geo}*/}
                        {/*    containerElement={<div style={{ height: `400px` }} />}                    */}
                        {/*    googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDBRRYKOufKFzvLQtRBFUmQHQITMuPng_Y"                     */}
                        {/*    loadingElement={<div style={{ height: `100%` }} />}*/}
                        {/*    mapElement={<div style={{ height: `100%` }} />}                    */}
                        {/*    />                        */}
                        {/*    <br/>*/}
                        {/*</Fragment>*/}
                        {/*}*/}


                        <Products products={this.state.order.products} />
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(OrderTrack);