import React from 'react';
import { Route, Switch } from "react-router-dom";
import Products from './views/Products';
import ProductCategory from './views/ProductCategory';
import Product from './views/Product';
import Cart from './views/Cart';
import Checkout from './views/Checkout';
import OrderConfirmation from './views/OrderConfirmation';
import OrderConfirmationPrint from './views/OrderConfirmationPrint';
import OrderTrack from './views/OrderTrack';
import AlbumAddons from './views/AlbumAddons';
import ExampleLogin from './views/ExampleLogin';
import ErrorNotFound from './views/ErrorNotFound';
import ErrorSiteDown from './views/ErrorSiteDown';
import PricingCalculator from './views/PricingCalculator';


//import SearchResults from './views/SearchResults';

class Routes extends React.Component {
  constructor(props) {
    super(props);
  }

  render(){
    console.log('rendering... this.props',this.props);
    return (  
        <div className='routes'>        
          
          <Switch>            
            {/*<Route path='/search/:q' component={SearchResults} exact={true} />   */}
            
            <Route path='/products/category/:category' component={ProductCategory} exact />   
            <Route path='/products' component={Products} />               
            <Route path='/product/:name/:option' component={Product} />   
            <Route path='/product/:name' component={Product} />   
            <Route path='/album_addons/:id' component={AlbumAddons} />                    
            <Route path='/cart/' component={Cart} />   
            <Route path='/checkout/' component={Checkout} />               
            <Route path='/confirmation/:id' component={OrderConfirmation} />            
            <Route path='/confirmation_print/:id' component={OrderConfirmationPrint} />            
            <Route path='/track/:id' component={OrderTrack} />            
            <Route path='/calculator' component={PricingCalculator} />            
            <Route path='/login/' component={ExampleLogin} />            
            <Route path='/404' component={ErrorNotFound} />        
            <Route path='/site_down' component={ErrorSiteDown} />        
            <Route path='/' exact component={Products} />        
            <Route component={ErrorNotFound} />        
          </Switch>
         
        </div>
    );
  }
}

  export default Routes;