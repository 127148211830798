import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import HeaderNav from '../components/headerNav';

function Header(props){
    
    //run on page/location change
    useEffect(()=>{

        //active campaign tracking   
        (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i);console.log('eton',e,t,o,n,p,r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
        let vgo = window['vgo'];
        vgo('setAccount', '609818996');
        vgo('setTrackByDefault', true);
        vgo('process'); 

        //convertbox
        (function(e,t){
            return (e=t.createElement("script")).src="https://cdn.convertbox.com/convertbox/js/embed.js",e.id="app-convertbox-script",e.async=true,e.dataset.uuid="59000e3e-9fe8-4871-9929-db439626f8e0",document.getElementsByTagName("head")[0].appendChild(e)
          })(window,document);
          
    },[window.location])

    return (
        <div style={{borderTop:'1px solid #E5E5E5',borderBottom:'1px solid #E5E5E5',marginBottom:'30px'}}>
            <Grid container direction='row'  >
                <Grid item sm={3} xs={12}>
                    <a href='/products'>
                        <img src='/images/branding/kiss-horiz.svg' width='150' alt='Kiss Books'/>
                    </a>
                </Grid>
                <Grid item sm={9} xs={12}>
                    <HeaderNav />
                </Grid>            
            </Grid>            
        </div>       
    )
}

export default Header;

