import React, { Fragment, useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import PrettyLink from '../components/prettyLink';
import Cookies from 'js-cookie';
import jsonwebtoken from 'jsonwebtoken';

function BreadCrumbs(props){
    const {products} = props;
    const [breadcrumb_root, set_breadcrumb_root] = useState(''); 
    useEffect(()=>{
        

        //determine if has user account
        var token_details = jsonwebtoken.decode(Cookies.get((process.env.REACT_APP_STORE_USER_TOKEN ? process.env.REACT_APP_STORE_USER_TOKEN : 'store_user_token')));
        if(token_details && token_details.status && token_details.status == 'approved'){
            set_breadcrumb_root(<Fragment><PrettyLink target='_self' href={(process.env.REACT_APP_DASHBOARD_URL ? process.env.REACT_APP_DASHBOARD_URL : 'http://localhost/mykiss')}>My Kiss</PrettyLink> > </Fragment>);
        }   
        
    },[])
        
    return (
        <div style={{marginBottom:'50px'}}>
          <Typography>{breadcrumb_root}<PrettyLink to='/products'>Shop</PrettyLink> > {props.children}</Typography>
        </div>    
    )
}

export default BreadCrumbs;