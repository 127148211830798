import React, { useState } from 'react';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';


function PromoCodeForm(props){

    const {showPromoCode, handleSubmit, cancel, submit, handleApply} = props;        
    const [code, setCode] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    function handler(e){
        setIsSubmitting(true);
        e.preventDefault();
        console.log('stopped default',e,code);
        handleApply(code)
        .then(res=>{
            setCode('');
            setIsSubmitting(false);
        })
    }

    return (
        
        <form onSubmit={handler}>           
            <Grid container>
                <Grid item xs={10}>
                    <TextField 
                    variant='filled'
                    label="Promo Code"
                    name='code'
                    value={code}
                    fullWidth
                    onChange={e=>setCode(e.target.value)}
                    />
                </Grid>
                <Grid item xs={2} style={{textAlign:'right', paddingTop:'10px'}}>
                    <Button type='submit' disabled={!code}>Apply</Button> 
                </Grid>
            </Grid>
        </form>
       
    );

}

export default PromoCodeForm;