import React, { Fragment} from 'react'
import Typography from '@material-ui/core/Typography';
import Markdown from 'markdown-to-jsx';
import PrettyLink from '../components/prettyLink';  
 
function ProductDescription(props){
  console.log('product',props.product);

  //define props
  const {product} = props;

  
  //passthrough links
  const myLink = ({ children, ...props }) => (
    <PrettyLink {...props}>{children}</PrettyLink>
  );

  return(
    <Fragment>
      <Typography>
        <Markdown
        options={{
          overrides:{
            a:{
              component: myLink
            }
          }
        }}
        >{product.description_pretty}</Markdown>
      </Typography>       
    </Fragment>
  )
}

export default ProductDescription;

