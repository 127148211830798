import React from 'react';
import Typography from '@material-ui/core/Typography';

function OrderPaymentMethod(props){
    const {methods, display} = props;
    return (
        <div>
            {(display == true) ?
                <div>
                    <Typography variant='h6'>Payment Method</Typography>
                    {methods.map(m => <Typography variant='caption'>{m}<br/></Typography>)}
                </div>
                : ''
            }
        </div>       
    )
}

export default OrderPaymentMethod;