import React, { Fragment} from 'react'
//import styled from 'styled-components'
import { withSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Header from '../components/header'; 
import axios from 'axios';
import Cookies from 'js-cookie';

class ExampleLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     token:false,
    };
  }


  handle_submit(){    
        //send email and other creds to the login endpoint
        var creds = {
            "client_id":"5bf5b16b5849fd73982eec8f",
            "client_secret": "khs786kj23lmacwe9sh17sbd9s5256",
            "email": this.state.email
        }

        axios.post((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:3000')+'/login',creds)
        .then(res=>{
            console.log('res',res);
            if(res.data.success){
                //store token in cookie
                Cookies.set((process.env.REACT_APP_STORE_USER_TOKEN ? process.env.REACT_APP_STORE_USER_TOKEN : 'store_user_token'),res.data.token);
                this.setState({'token':res.data.token})

                //show message
                this.props.enqueueSnackbar('Logged In',{variant:'success'}); 
            }
        })
        .catch(err=>{
            console.log('err',err);
        })


  }

  handle_logout(){
    Cookies.remove((process.env.REACT_APP_STORE_USER_TOKEN ? process.env.REACT_APP_STORE_USER_TOKEN : 'store_user_token'));
    this.setState({'token':Cookies.get((process.env.REACT_APP_STORE_USER_TOKEN ? process.env.REACT_APP_STORE_USER_TOKEN : 'store_user_token'))})

  }

  handleEmailChange(e){
      this.setState({email:e.target.value})
  }


  componentDidMount() {
    this.setState({'token':Cookies.get((process.env.REACT_APP_STORE_USER_TOKEN ? process.env.REACT_APP_STORE_USER_TOKEN : 'store_user_token'))})

    //set page title
    document.title = 'Kiss Shop - Login (Test)';
  }

  render () {   

    const {token} = this.state;
    console.log('token',token);

    return (
      <div>
        <Header location={this.props.location}/>
        <Typography variant='h6'>Example Login</Typography>
        <Grid container direction='row' justify='center' alignItems='center'>
            <Grid item xs={4}>
                { token ?
                <Fragment>
                    <Typography>Logged in.</Typography>
                    <Button type='submit' variant='contained' onClick={this.handle_logout.bind(this)}>Log Out</Button>
                </Fragment>
                :
                <div  style={{textAlign:'right'}}>
                    <TextField          
                    label='Email'
                    margin="normal"
                    variant="filled"
                    fullWidth
                    name='email'
                    onChange={this.handleEmailChange.bind(this)}
                    />
                    <br/>
                    <Button type='submit' variant='contained' onClick={this.handle_submit.bind(this)}>Log In</Button>
                </div>
                }
            </Grid>
        </Grid>        
      </div>       
    )
  }
}

export default withSnackbar(ExampleLogin);