import React, { Fragment } from 'react'
//import styled from 'styled-components'
import { withSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DataSource from '../utils/DataSource';
import BreadCrumbs from '../components/breadCrumbs';
import ProductList from '../components/productList';
import CartTotals from '../components/totalsDisplay';
import Header from '../components/header'; 
import ErrorHandler from '../utils/ErrorHandler';
import SiteLive from '../utils/siteLive';
import ReactPixel from 'react-facebook-pixel';

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: false,
      totals:false,
      updatingCart:false,
      blockButton: false,
    };
  }

  handleSelectChange(ev){
    console.log('something',ev.target.value);
    const options = Object.assign({}, this.state.options);
    options[ev.target.name] = ev.target.value;
    this.setState({options});
    this.fetchProductPrice(this.state.product._id, options);
  }

  handleTextChange(ev){
    const options = Object.assign({}, this.state.options);
    options[ev.target.name] = ev.target.value;
    this.fetchProductPrice(this.state.product._id, options);
  }

  handleSwitchChange(ev){
    const options = Object.assign({}, this.state.options);
    options[ev.target.name] = ev.target.checked;
    this.fetchProductPrice(this.state.product._id, options);
  }

  fetchProducts(){
    return new Promise(function(resolve,reject){
      //Get the product
      DataSource.get('/cart/products')
      .then(function(res){
        console.log('product RES',res.data);
        if (res.data.errors.length > 0) {
          for (let error of res.data.errors) {
            this.props.enqueueSnackbar(error,{variant:'error'});
          }
          this.setState({
            blockButton: true,
          });
        }
        resolve(res.data);
      }.bind(this)) 
      .catch(function(err){
        console.log('ERR',err);
        ErrorHandler(err,this.props);
        reject(err);
      }.bind(this))
    }.bind(this));
  }

  fetchTotals(){
    return new Promise(function(resolve,reject){
      //Get the product
      DataSource.get('/cart/totals')
      .then(function(res){
        console.log('product RES',res.data);
        resolve(res.data);
      }.bind(this)) 
      .catch(function(err){
        console.log('ERR',err);
        ErrorHandler(err,this.props);
        reject(err);
      }.bind(this))
    }.bind(this));
  }

  fetchAll(){
    this.setState({
      products: [],
    });

    return new Promise(function(resolve,reject){
      var loaded=0;

      //get products
      this.fetchProducts()
      .then(function(res){
        console.log('loaded products',res);
        
        //save in state
        this.setState({
          products: res.items,
        });

        //are we done loading?
        if(++loaded == 2){
          resolve();
        }

      }.bind(this));

      //get totals
      this.fetchTotals()
      .then(function(res){
        //remove the 'Total' line item since we're still on the cart page
        delete res.total;
        this.setState({
          totals: res,
          updatingCart: false,//turn of the blocker/loader
        });

        //are we done loading?
        if(++loaded == 2){
          resolve();
        }

      }.bind(this));

    }.bind(this));
  } 

  handleQuantityChange(cart_product_id, qty){
    this.setState({updatingCart:true});

    if(qty>0){
      //change qty      
      let cart_product = this.state.products.find(p=>p._id==cart_product_id);
      console.log('increasing qty of product',cart_product_id,cart_product);

      //exception for kiss books
      if(cart_product.type=="5c0ed23b7c2d3d2a323e3b8d"){
        //create new products
        let new_product = JSON.parse(JSON.stringify(cart_product));
        delete new_product.date_created;
        delete new_product.date_updated;
        delete new_product.options_pretty;
        delete new_product.total;
        delete new_product.user;
        delete new_product._v;
        delete new_product._id;
        delete new_product.images.icon.url;

        let promises = [];
        for(var i=1;i<qty;i++){
          promises.push(new Promise(function(resolve,reject){
            DataSource.post('/cart/products',new_product)
            .then(function(res){
              resolve();
            }.bind(this)) 
            .catch(function(err){
              console.log('ERR',err);
              ErrorHandler(err,this.props);  
              reject();    
            }.bind(this))
          }));
        }
        Promise.all(promises).then(function(){
          this.fetchAll()
          .then(function(res){
        
            //turn of the blocker/loader
            this.setState({       
              updatingCart: false,
            });

          }.bind(this));
        }.bind(this));

        
      }else{
        //do regular qty change
        DataSource.patch('/cart/products/'+cart_product_id,{'quantity':qty})
        .then(function(res){
          this.fetchAll()
          .then(function(res){
        
            //turn of the blocker/loader
            this.setState({       
              updatingCart: false,
            });

          }.bind(this));
        }.bind(this)) 
        .catch(function(err){
          console.log('ERR',err);
          ErrorHandler(err,this.props);      
        }.bind(this))   
      }
    }else{
      //delete
      DataSource.delete('/cart/products/'+cart_product_id)
      .then(function(res){
        this.fetchAll()
        .then(function(res){
      
          //turn of the blocker/loader
          this.setState({       
            updatingCart: false,
          });

        }.bind(this));
      }.bind(this)) 
      .catch(function(err){
        console.log('ERR',err);
        ErrorHandler(err,this.props);      
      }.bind(this))   
    }

  }

  handle_submit(){
    //then redirect to the checkout page
    this.props.history.push('/checkout', {});
  }


  componentDidMount() {
    //set page title
    document.title = 'Kiss Shop - Cart';

    //check for site live
    SiteLive();

    //Get the cart
    this.fetchAll();

    //fb pixel
    if(process.env.REACT_APP_FB_PIXEL_ID){
      ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID);
      ReactPixel.pageView(); // For tracking page view
    }

  }

  render () {   

    const {products, totals, updatingCart, blockButton} = this.state;

    return (
      <div>
        <Header location={this.props.location}/>
        <BreadCrumbs>Shopping Cart</BreadCrumbs>
        <Typography variant='h5'>Shopping Cart</Typography>
        {(products && totals) ? 
          <Grid container spacing={0}>
            {products.length > 0 
            ?
            <Fragment>
              <Grid item xs={12} sm={7}>
                <ProductList products={products} show_actions={true} handleQuantityChange={this.handleQuantityChange.bind(this)} updatingCart={updatingCart}/>
              </Grid>
              <Grid item sm={1} xs={0}/>
              <Grid item sm={4} xs={12}>
                <CartTotals totals={totals} updatingCart={updatingCart}/>
                <div style={{'textAlign':'right','marginTop':'20px'}}>
                  <Button type='submit' variant="contained" onClick={e=>this.handle_submit()} disabled={updatingCart || blockButton}>Go To Check Out</Button>
                </div>
              </Grid>
            </Fragment>
            :
            <div style={{width:'912px'}}>
            <Grid item xs={12}>
              <Grid container justify={'center'} style={{marginTop:'20px'}}>
                <Grid item >
                  <Typography>Your cart is empty.</Typography>
                  <br/>
                  
                  <Button type='submit' variant="contained" href='/products'>Shop Now</Button>
                </Grid>
              </Grid>
            </Grid>
            </div>
            }
          </Grid>
        :
          <CircularProgress/>
        }
        
      </div>       
    )
  }
}

export default withSnackbar(Cart);