import axios from 'axios';
import Cookies from 'js-cookie';

var token = Cookies.get((process.env.REACT_APP_STORE_USER_TOKEN ? process.env.REACT_APP_STORE_USER_TOKEN : 'store_user_token'));//'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiI1YmY1YzY1ZGYxNGM0ZTc1M2YyNzJhMGEiLCJyb2xlcyI6WyJjdXN0b21lciIsImFkbWluIiwiZGVzaWduZXIiXSwiaWF0IjoxNTU2NzMzMTgzLCJleHAiOjE1NTkzMjUxODN9.3Mi6Ux5J5Ye2syDnm2QE4Tn_Znm-SXzrDPXFDR-ajjw';

//set axios config
axios.defaults.baseURL = (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:3000')+'/';
axios.defaults.withCredentials = false;
axios.defaults.headers = {
  'Authorization' : 'bearer '+token,
  'Content-Type':'application/json'};

//handle 404s
axios.interceptors.response.use(
  
  function (response) {
    // Do something with response data
    return response;
  }, 
      
  function (error) {
    console.log('datasource error',error);

    if(error.response){
      // Do something with response error
      var code = error.response.status;

      if(code==404){
        //exit immediately, and redirect to 404 page
        window.location = '/404';
        return false;
      }else if(code==401){
      
        //redirect
        window.location = (process.env.REACT_APP_LOGIN_URL ? process.env.REACT_APP_LOGIN_URL : '/mykiss/auth/login');
        return false;
      }
    }
     
    return Promise.reject(error);
  }
);

export default axios; 