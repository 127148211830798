//import { Link } from "react-router-dom";
import Link from '@material-ui/core/Link';
import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import prependHttp from 'prepend-http';

class PrettyLink extends React.Component {
    constructor(props) {
    super(props);       
    } 

    

    handleClick= (ev) => {
        console.log('handleclick this.props',this.props,ev.target.to);
        this.props.history.push(this.props.to, {});
    }

    render(){
        const { classes, theme, style } = this.props;

        if(style){
            //console.log('found and added',style);
            var styles = Object.assign({},style);
            styles.cursor = 'pointer';
            
        }else{
            var styles = {cursor:'pointer'};
            //console.log('found and added',styles);
        }
          

        if(this.props.to){
            return (
                <Link to={this.props.to} onClick={this.handleClick} style={styles}>{this.props.children}</Link>
            );
        }else{ 
            //parse it
            var url = prependHttp(this.props.href);           
            return (
                <Link href={url} target={(this.props.target ? this.props.target : '_blank')} style={styles}>{this.props.children}</Link>
            );
        }
    }
    
   
}
PrettyLink.propTypes = {
    theme: PropTypes.object.isRequired,
  };

export default withRouter(withTheme()(PrettyLink));