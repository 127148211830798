import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import DataSource from '../utils/DataSource';
import PrettyLink from '../components/prettyLink';
import BreadCrumbs from '../components/breadCrumbs';
import ShippingEstimator from '../components/pricing_calculator/ShippingEstimator.js';
import moment from 'moment';
import ShippingMap from "../components/shippingMap";
import Header from '../components/header';
import Products from '../components/orderProducts';
import tu from 'tracking-url';
import { getCarrier, getCarrierLink } from 'shipmethod';
import SiteLive from '../utils/siteLive';
import BookCard from '../components/pricing_calculator/BookCard.js';
import Totals from '../components/pricing_calculator/Totals.js';
import ReactPixel from 'react-facebook-pixel';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import formatCurrency from '../utils/formatCurrency';
import toHuman from '../utils/toHuman';

const styles = theme => ({});

/* PRICING */
const albumPricing = {
    'color_line': {
        fine: 30,
        silk: 0,
        linen: 0,
        leather: 0,
        suede: 0,
        distressed: 0,
    },
    'luster': 0,
    'matte': 30,
    'press_printed': 0,
    'cameo': 40,
    'engraving': 40,
    'debossing': {
        '5x1': 40,
        '5x2': 60,
        '5x4': 85
    },
    'faceoff_debossing': 10,
    '4': {
        'thin': {
            'leather': 95,
            'linen': 70,
            'luster': 5,
            'matte': 8,
            'press_printed': 3,
            'press_printed_linen': 59,
            'press_printed_leather': false,
        },
        'thick': {
            'leather': false,
            'linen': false,
            'luster': false,
            'matte': false,
            'press_printed': false,
            'press_printed_linen': false,
            'press_printed_leather': false,
        },
    },
    '8': {
        'thin': {
            'leather': 215,
            'linen': 125,
            'luster': 8,
            'matte': 11,
            'press_printed': 4,
            'press_printed_linen': 75,
            'press_printed_leather': false,
        },
        'thick': {
            'leather': 280,
            'linen': 190,
            'luster': 14,
            'matte': 17,
            'press_printed': false,
            'press_printed_linen': false,
            'press_printed_leather': false,
        },
    },
    '10': {
        'thin': {
            'leather': 270,
            'linen': 155,
            'luster': 9,
            'matte': 12,
            'press_printed': 6,
            'press_printed_linen': 100,
            'press_printed_leather': false,
        },
        'thick': {
            'leather': 340,
            'linen': 235,
            'luster': 15,
            'matte': 18,
            'press_printed': false,
            'press_printed_linen': false,
            'press_printed_leather': false,
        },
    },
    '12': {
        'thin': {
            'leather': 325,
            'linen': 185,
            'luster': 10,
            'matte': 13,
            'press_printed': false,
            'press_printed_linen': false,
            'press_printed_leather': false,
        },
        'thick': {
            'leather': 400,
            'linen': 280,
            'luster': 16,
            'matte': 19,
            'press_printed': false,
            'press_printed_linen': false,
            'press_printed_leather': false,
        },
    }
}

const boxPricing = {
    'walnut': {
        '4': 130,
        '8': 195,
        '10': 210,
        '12': 225
    },
    'linen': {
        '4': false,
        '8': 160,
        '10': 180,
        '12': 210
    },
    'presentation': {
        '4': 20,
        '8': 20,
        '10': 20,
        '12': 20
    },
    'engraving': 40,
    'logo_engraving': 10,
    'debossing': {
        '5x1': 40,
        '5x2': 60,
        '5x4': 85
    },
    'logo_debossing': 10,
}

var tableCellStyle = {
    border: '1px solid #cabfab',
    padding: '10px'
}

const defaultProduct = {
    name: 'Kiss Book',
    options: {
        coverType: '',
        product: 'kiss_book',
        size: '',
        addons: {}
    },
    price: 0,
    margin: 225,
    profit: 0,
    boxPrice: 0,
    boxMargin: 100,
    boxProfit: 0,
    giftWrapPrice: 0,
    giftWrapMargin: 100,
    giftWrapProfit: 0,
    weight: 0
}

class PricingCalculator extends React.Component {

    constructor(props) {
        super(props);
        console.log('orderconfirmation props', props);
    }

    state = {
        products: [],
        sh: 0,
        tempSh: 0,
        shipId: '',
        tempShipId: '',
        total: 0,
        weight: 0,
        breakdown: false,
        showResetConfirm: false,
        hasSample: false,
        showShippingEstimator: false,
        calculatingShipping: false,
        address: {},
    };

    componentDidMount() {

        //check for site live
        SiteLive();

        //fb pixel
        if (process.env.REACT_APP_FB_PIXEL_ID) {
            ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID);
            ReactPixel.pageView(); // For tracking page view
        }

        //pull from api 
        DataSource.get('/tools/calculator')
            .then(async function (res) {
                console.log('saved calculator data', res);
                if (res.data) {
                    await this.setState({
                        address: (res.data.shipping_address ? res.data.shipping_address : {}),
                        shipId: (res.data.shipping_method ? res.data.shipping_method : {}),
                    })

                    if (res.data.products && res.data.products.length > 0) {
                        await this.setState({ products: res.data.products });
                    } else {
                        this.setDefault();
                    }

                } else {
                    //if no data, default
                    this.setDefault();
                }
            }.bind(this));
    }

    round(num) {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }

    async setDefault() {
        console.log('setDefault before - defaultProduct', JSON.stringify(defaultProduct));
        let def = JSON.parse(JSON.stringify(defaultProduct));
        await this.setState({ products: [def] });
        console.log('setDefault after - ddefaultProduct', JSON.stringify(defaultProduct));

    }

    async handleTotalChange(num, totals) {

        console.log('handleTotalChange', num, totals);

        //update this product state cost
        let temp = this.state.products.slice();
        temp[num].costs = totals;

        //save to state
        await this.setState({ products: temp });

        //calculate numbers for the product
        await this.calculateProductNumbers(num);

        //calculate total
        this.calculateTotal();
    }

    handleWeightChange(num, weight) {

        //update this product state cost
        let temp = this.state.products.slice();
        temp[num].weight = weight;

        //save to state
        this.setState({ products: temp });

        //calculate numbers 
        this.calculateWeight();
    }

    calculateWeight() {
        let weight_total = 0;
        this.state.products.map(p => weight_total += p.weight);

        //save to state
        this.setState({ weight: weight_total });

        //if we have a shipping method id saved, then get new shipping price
        if (this.state.shipId && this.state.shipId.length > 1 && this.state.address && this.state.address.state && weight_total > 0) {

            this.setState({ calculatingShipping: true });

            let data = Object.assign({}, this.state.address);
            data.weight = weight_total;
            data.method = this.state.shipId;

            //pull from api 
            DataSource.post('/tools/calculator/shipping_estimate', data)
                .then(async function (res) {
                    console.log('shipping estimate results (for one method):', res);
                    if (res.data[0] && res.data[0].methods[0]) {
                        let cost = Number(res.data[0].methods[0].cost);
                        //setMethodChoices(res.data);
                        console.log('got cost for one ship method', cost, res.data);
                        await this.setState({
                            sh: cost,
                            calculatingShipping: false
                        });

                        //calculate total
                        this.calculateTotal();
                    }
                }.bind(this));
        }
    }

    async handleOptionChange(num, options) {
        console.log('handleOptionChange', num, options);
        //update the product in state
        let temp = this.state.products.slice();
        let the_product = temp[num];
        the_product.options = Object.assign(the_product.options, options);
        if (the_product.options.size && the_product.options.material && the_product.options.product) {

            var prefix = (the_product.options.product == 'kiss_book') ? 'Kiss Book - ' : 'Press Printed Book - ';

            the_product.name = prefix + the_product.options.size + '" ' + toHuman(the_product.options.material) + (the_product.options.sample ? ' Studio Sample' : '');
        } else {
            console.log('handleOptionChange - defaultProduct', JSON.stringify(defaultProduct));
            the_product.name = defaultProduct.name;
            console.log('handleOptionChange - after  defaultProduct', JSON.stringify(defaultProduct));
        }
        let hasSample = false;
        temp.map((p) => { if (p.options.sample) { hasSample = true; } });
        await this.setState({ products: temp, hasSample: hasSample });
        if (hasSample && this.state.breakdown) {
            await this.setState({ breakdown: false });
        }

        //save changes
        await this.save();
    }

    async calculateProductNumbers(num, skip_profit) {



        //update product profit and price
        let temp = this.state.products.slice();
        if (temp.length > 1) {
            console.log('calculate temp 0 and 1 prices', temp[0].costs);
        }
        let the_product = temp[num];

        console.log('calculateProductNumbers', the_product.costs, the_product.options.sample, the_product.margin, the_product.profit);

        //Book
        if (!skip_profit) {
            let new_profit = (the_product.costs.book * (the_product.margin * .01));
            if (this.round(new_profit) != this.round(the_product.profit)) {
                console.log('calculateproductnumbers ', this.round(new_profit), '!=', this.round(the_product.profit));
                the_product.profit = this.round(new_profit);
            }
        }
        the_product.price = this.round(the_product.costs.book + (the_product.options.sample ? 0 : the_product.profit));

        //Box
        if (!skip_profit) {
            let new_profit = (the_product.costs.box * (the_product.boxMargin * .01));
            if (this.round(new_profit) != this.round(the_product.boxProfit)) {
                the_product.boxProfit = this.round(new_profit);
            }
        }
        the_product.boxPrice = this.round(the_product.costs.box + (the_product.options.sample ? 0 : the_product.boxProfit));

        //Gift Wrap
        if (!skip_profit) {
            let new_profit = (the_product.costs.giftWrap * (the_product.giftWrapMargin * .01));
            if (this.round(new_profit) != this.round(the_product.giftWrapProfit)) {
                the_product.giftWrapProfit = this.round(new_profit);
            }
        }
        the_product.giftWrapPrice = this.round(the_product.costs.giftWrap + (the_product.options.sample ? 0 : the_product.giftWrapProfit));

        console.log('calculateProductNumbers - actual numbers', the_product);

        //save to state
        await this.setState({ products: temp });
    }

    async calculateTotal() {
        console.log('calculateTotal', this.state.products.slice());
        let the_total = 0;

        this.state.products.map(p => the_total += p.price + p.boxPrice + p.giftWrapPrice);
        console.log('calculateTotal producxts', the_total);
        the_total += this.state.sh;
        console.log('calculateTotal producxts+sh', this.state.sh, the_total);
        await this.setState({ 'total': the_total });
    }

    setBreakdown(e) {
        this.setState({ breakdown: !this.state.breakdown })
    }

    async handleAddBook() {
        let new_products = this.state.products.slice();
        console.log('calculate - before adding new product', JSON.stringify(new_products));
        console.log('calculate - defaultProduct', JSON.stringify(defaultProduct));
        let newp = JSON.parse(JSON.stringify(defaultProduct));
        new_products.push(newp);
        console.log('calculate - adding new product', JSON.stringify(new_products));
        console.log('calculate - after defaultProduct', JSON.stringify(defaultProduct));
        await this.setState({
            products: new_products
        });

        //save changes
        await this.save();
    }

    handleClickReset() {
        this.setState({ showResetConfirm: !this.state.showResetConfirm });

    }

    async handleReset() {
        //remove all but 1 default product     
        await this.setState({ products: new Array() });

        await this.setDefault();

        //save changes
        await this.save();

        await this.setState({ sh: 0 });

        //calculate total
        await this.calculateTotal();

        //hide confirmation dialog
        await this.setState({ showResetConfirm: !this.state.showResetConfirm });
    }

    async handleMarginChange(ev, num) {
        //update the product (in state) with the new margin figure
        let margin = Number(ev.target.value);
        if (margin < 0) { margin = 0; }

        let temp = this.state.products.slice();

        //only update if an actually different number
        if (margin.toFixed(2) !== temp[num].margin.toFixed(2)) {

            temp[num].margin = this.round(margin);
            await this.setState({ products: temp });

            console.log('updated margin number', JSON.parse(JSON.stringify(temp)));
        }

        //recalculate numbers
        await this.calculateProductNumbers(num);

        //save changes
        await this.save();

        //calculate total
        this.calculateTotal();

    }

    async handleBoxMarginChange(ev, num) {
        //update the product (in state) with the new margin figure
        let margin = Number(ev.target.value);
        if (margin < 0) { margin = 0; }
        let temp = this.state.products.slice();

        //only update if an actually different number
        if (margin.toFixed(2) !== temp[num].boxMargin.toFixed(2)) {
            temp[num].boxMargin = this.round(margin);
            await this.setState({ products: temp });
            console.log('updated boxMargin number', JSON.parse(JSON.stringify(temp)));
        }



        //recalculate numbers
        await this.calculateProductNumbers(num);

        //save changes
        await this.save();

        //calculate total
        this.calculateTotal();

    }

    async handleGiftWrapMarginChange(ev, num) {
        //update the product (in state) with the new margin figure
        let margin = Number(ev.target.value);
        if (margin < 0) { margin = 0; }
        let temp = this.state.products.slice();

        //only update if an actually different number
        if (margin.toFixed(2) !== temp[num].giftWrapMargin.toFixed(2)) {
            temp[num].giftWrapMargin = this.round(margin);
            await this.setState({ products: temp });

            console.log('updated giftWrapMargin number', JSON.parse(JSON.stringify(temp)));
        }

        //recalculate numbers
        await this.calculateProductNumbers(num);

        //save changes
        await this.save();

        //calculate total
        this.calculateTotal();

    }



    async handleProfitChange(ev, num) {

        //copy from state
        let temp = this.state.products.slice();

        //using the new profit number, calculate the margin
        let profit = Number(ev.target.value);
        if (profit < 0) { profit = 0; }

        //only update if an actually different number
        if (profit.toFixed(2) !== temp[num].profit.toFixed(2)) {
            temp[num].profit = profit;
            console.log('book profit', profit, 'temp[num].costs.book', temp[num].costs.book);
            //update the product (in state) with the new margin
            await this.setState({ products: temp });
        }

        let margin = (profit * 100) / temp[num].costs.book;

        //only update if an actually different number
        if (this.round(margin) !== this.round(temp[num].margin)) {
            temp[num].margin = this.round(margin);

            //update the product (in state) with the new margin
            await this.setState({ products: temp });

        }

        //recalculate numbers
        await this.calculateProductNumbers(num, true);

        //save changes
        await this.save();

        //calculate total
        this.calculateTotal();
    }

    async handleBoxProfitChange(ev, num) {

        //copy from state
        let temp = this.state.products.slice();

        //using the new profit number, calculate the margin
        let profit = Number(ev.target.value);
        if (profit < 0) { profit = 0; }

        //only update if an actually different number
        if (profit.toFixed(2) !== temp[num].boxProfit.toFixed(2)) {
            temp[num].boxProfit = profit;
            console.log('boxProfit profit', profit, 'temp[num].costs.box', temp[num].costs.box);
            //update the product (in state) with the new margin
            await this.setState({ products: temp });
        }

        let margin = (profit * 100) / temp[num].costs.box;

        //only update if an actually different number
        if (this.round(margin) !== this.round(temp[num].boxMargin)) {
            temp[num].boxMargin = this.round(margin);

            //update the product (in state) with the new margin
            await this.setState({ products: temp });
        }

        //recalculate numbers
        await this.calculateProductNumbers(num, true);

        //save changes
        await this.save();

        //calculate total
        this.calculateTotal();
    }

    async handleGiftWrapProfitChange(ev, num) {

        //copy from state
        let temp = this.state.products.slice();

        //using the new profit number, calculate the margin
        let profit = Number(ev.target.value);
        if (profit < 0) { profit = 0; }

        //only update if an actually different number
        if (profit.toFixed(2) !== temp[num].giftWrapProfit.toFixed(2)) {
            temp[num].giftWrapProfit = profit;
            console.log('giftWrapProfit profit', profit, 'temp[num].costs.giftWrap', temp[num].costs.giftWrap);

            //update the product (in state) with the new margin
            await this.setState({ products: temp });
        }

        let margin = (profit * 100) / temp[num].costs.giftWrap;

        //only update if an actually different number
        if (this.round(margin) !== this.round(temp[num].giftWrapMargin)) {
            temp[num].giftWrapMargin = this.round(margin);

            //update the product (in state) with the new margin
            await this.setState({ products: temp });
        }

        //recalculate numbers
        await this.calculateProductNumbers(num, true);

        //save changes
        await this.save();

        //calculate total
        this.calculateTotal();
    }

    handleFocus(type, num) {
        this.setState({ focus: type + '_' + num });
    }

    handleBlur(type, num) {
        this.setState({ focus: '' });
    }

    handlePrint() {
        window.print();
    }

    handleClickShippingEstimator() {
        this.setState({ 'showShippingEstimator': !this.state.showShippingEstimator })
    }

    showShippingEstimator() {

    }

    handleSelectShipType(id, price) {
        console.log('handleSelectShipType', id, price);
        this.setState({
            tempSh: Number(price),
            tempShipId: id,
        })

    }

    async handleApplyShipType() {
        console.log('handleApplyShipType', this.state.tempSh, this.state.tempShipId);
        await this.setState({
            sh: this.state.tempSh,
            shipId: this.state.tempShipId,
            showShippingEstimator: false,
        });

        //save changes
        await this.save();

        //calculate total
        this.calculateTotal();

    }


    handleShippingAddressChange(address) {
        this.setState({ address: address })
    }

    async save() {
        //save
        let data = {
            products: this.state.products,
            shipping_address: this.state.address,
        }

        if (this.state.shipId) {
            data.shipping_method = this.state.shipId;
        }

        await DataSource.post('/tools/calculator/save', data);
    }

    render() {
        const { products } = this.state;

        return (
            <div>
                <Fragment>
                    <Header location={this.props.location} />
                    <BreadCrumbs>Pricing Calculator</BreadCrumbs>
                </Fragment>

                <Grid container>
                    <Grid item xs={12}>
                        {products.map((product, num) =>
                            <BookCard
                                num={num}
                                name={product.name}
                                options={product.options}
                                albumPricing={albumPricing}
                                boxPricing={boxPricing}
                                price={product.price}

                                /*bookCoverOptions={{
                                    size:'10',                            
                                }}
                                bookSpreadOptions={{
                                    pageThickness:'thick',                            
                                    paperType:'luster',
                                    spreads:'10'
                                }}*/
                                handleTotalChange={this.handleTotalChange.bind(this)}
                                handleWeightChange={this.handleWeightChange.bind(this)}
                                handleOptionChange={this.handleOptionChange.bind(this)}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '0px' }}>
                        <Tooltip
                            title='First, add a Cover &amp; Spreads'
                            disableFocusListener={(this.state.products && this.state.products[0] && this.state.products[0].costs && this.state.products[0].costs.book > 0)}
                            disableHoverListener={(this.state.products && this.state.products[0] && this.state.products[0].costs && this.state.products[0].costs.book > 0)}
                            disableTouchListener={(this.state.products && this.state.products[0] && this.state.products[0].costs && this.state.products[0].costs.book > 0)}
                        >
                            <span>
                                <Button
                                    variant='outlined'
                                    onClick={this.handleAddBook.bind(this)}
                                    disabled={!(this.state.products && this.state.products[0] && this.state.products[0].costs && this.state.products[0].costs.book > 0)}
                                >Add Another Book</Button>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '40px' }}>
                        <Grid container>
                            <Grid xs='6'>
                                <Typography variant='h5'>My Price</Typography>
                            </Grid>
                            <Grid xs='6' style={{ textAlign: 'right' }}>
                                <Tooltip title={(this.state.hasSample ? ' (not available with Studio Samples)' : 'View and edit the Profit Margins')}>
                                    <FormControlLabel
                                        control={
                                            <Switch checked={this.state.breakdown} disabled={this.state.hasSample} onChange={e => this.setBreakdown(e.target.checked)} value={true} size='small' />
                                        }
                                        label="Pricing Breakdown"
                                        labelPlacement='start'
                                    />
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <th style={Object.assign({ textAlign: 'left', width: (this.state.breakdown ? '20%' : '50%') }, tableCellStyle)}><Typography><b>Product</b></Typography></th>
                                {this.state.breakdown &&
                                    [
                                        <th style={Object.assign({ textAlign: 'right', width: (this.state.breakdown ? '20%' : '50%') }, tableCellStyle)}><Typography><b>Cost</b></Typography></th>,
                                        <th style={Object.assign({ textAlign: 'right', width: (this.state.breakdown ? '20%' : '50%') }, tableCellStyle)}><Typography><b>Margin</b></Typography></th>,
                                        <th style={Object.assign({ textAlign: 'right', width: (this.state.breakdown ? '20%' : '50%') }, tableCellStyle)}><Typography><b>Profit</b></Typography></th>
                                    ]
                                }
                                <th colSpan={this.state.breakdown ? 1 : 2} style={Object.assign({ textAlign: 'right', width: (this.state.breakdown ? '20%' : '50%') }, tableCellStyle)}><Typography><b>Price</b></Typography></th>
                            </thead>
                            {products.map((product, num) =>
                                <tr>
                                    <td style={tableCellStyle}>
                                        <Typography>
                                            <table>

                                                <tr>
                                                    <td style={{ height: '32px', verticalAlign: 'middle' }}>
                                                        {product.name}
                                                    </td>
                                                </tr>

                                                {product.options.addons.box &&
                                                    <tr>
                                                        <td style={{ height: '32px', verticalAlign: 'middle' }}>
                                                            - {toHuman(product.options.addons.box.type)} Box
                                                        </td>
                                                    </tr>
                                                }

                                                {product.options.addons.giftWrap &&
                                                    <tr>
                                                        <td style={{ height: '32px', verticalAlign: 'middle' }}>
                                                            - Gift Wrap
                                                        </td>
                                                    </tr>
                                                }

                                                {product.options.addons.dustCover &&
                                                    <tr>
                                                        <td style={{ height: '32px', verticalAlign: 'middle' }}>
                                                            - Dust Cover
                                                        </td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <td style={{ height: '32px', verticalAlign: 'middle' }}>
                                                    </td>
                                                </tr>
                                            </table>
                                        </Typography>

                                    </td>
                                    {this.state.breakdown &&
                                        [
                                            <th style={Object.assign({ textAlign: 'right' }, tableCellStyle)}>

                                                <Typography>
                                                    <table style={{ width: '100%' }}>
                                                        <tr>
                                                            <td style={{ height: '32px', verticalAlign: 'middle', textAlign: 'right' }}>
                                                                {product.costs && formatCurrency(product.costs.book, true)}
                                                            </td>
                                                        </tr>
                                                        {product.options.addons.box &&
                                                            <tr>
                                                                <td style={{ height: '32px', verticalAlign: 'middle', textAlign: 'right' }}>
                                                                    {product.costs && formatCurrency(product.costs.box, true)}
                                                                </td>
                                                            </tr>
                                                        }

                                                        {product.options.addons.giftWrap &&
                                                            <tr>
                                                                <td style={{ height: '32px', verticalAlign: 'middle', textAlign: 'right' }}>
                                                                    {product.costs && formatCurrency(product.costs.giftWrap, true)}
                                                                </td>
                                                            </tr>
                                                        }

                                                        {product.options.addons.dustCover &&
                                                            <tr>
                                                                <td style={{ height: '32px', verticalAlign: 'middle', textAlign: 'right' }}>
                                                                    --
                                                                </td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td style={{ height: '32px', verticalAlign: 'middle', textAlign: 'right' }}>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </Typography>
                                            </th>,
                                            <th style={Object.assign({ textAlign: 'right' }, tableCellStyle)}>
                                                <Typography>
                                                    <table>
                                                        <tr>
                                                            <td style={{ height: '32px', verticalAlign: 'middle', textAlign: 'right' }}>
                                                                {(product.costs && typeof (product.costs.book) == 'number' && typeof (product.margin) == 'number')
                                                                    ?
                                                                    <Fragment>
                                                                        {this.state.focus == 'margin_' + num
                                                                            ?
                                                                            <Input
                                                                                type='number'
                                                                                tabIndex={num + 1}
                                                                                InputProps={{ inputProps: { min: 0, max: 500 } }}
                                                                                onChange={ev => this.handleMarginChange(ev, num)}
                                                                                onFocus={ev => this.handleFocus('margin', num)}
                                                                                onBlur={ev => this.handleBlur('margin', num)}
                                                                                value={product.margin}
                                                                                style={{ width: '70%' }}
                                                                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                                            />
                                                                            :
                                                                            <Input
                                                                                type='number'
                                                                                tabIndex={num + 1}
                                                                                InputProps={{ inputProps: { min: 0, max: 500 } }}
                                                                                onChange={ev => this.handleMarginChange(ev, num)}
                                                                                onFocus={ev => this.handleFocus('margin', num)}
                                                                                onBlur={ev => this.handleBlur('margin', num)}
                                                                                value={product.margin.toFixed(2)}
                                                                                style={{ width: '70%' }}
                                                                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                                            />
                                                                        }
                                                                    </Fragment>

                                                                    :
                                                                    <Fragment>
                                                                        --
                                                                    </Fragment>
                                                                }
                                                            </td>
                                                        </tr>

                                                        {product.options.addons.box &&
                                                            <tr>
                                                                <td style={{ height: '32px', verticalAlign: 'middle', textAlign: 'right' }}>
                                                                    {(product.costs && typeof (product.costs.book) == 'number' && typeof (product.boxMargin) == 'number')
                                                                        ?
                                                                        <Fragment>
                                                                            {this.state.focus == 'boxMargin_' + num
                                                                                ?
                                                                                <Input
                                                                                    type='number'
                                                                                    tabIndex={num + 1}
                                                                                    InputProps={{ inputProps: { min: 0, max: 500 } }}
                                                                                    onChange={ev => this.handleBoxMarginChange(ev, num)}
                                                                                    onFocus={ev => this.handleFocus('boxMargin', num)}
                                                                                    onBlur={ev => this.handleBlur('boxMargin', num)}
                                                                                    value={product.boxMargin}
                                                                                    style={{ width: '70%' }}
                                                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                                                />
                                                                                :
                                                                                <Input
                                                                                    type='number'
                                                                                    tabIndex={num + 1}
                                                                                    InputProps={{ inputProps: { min: 0, max: 500 } }}
                                                                                    onChange={ev => this.handleBoxMarginChange(ev, num)}
                                                                                    onFocus={ev => this.handleFocus('boxMargin', num)}
                                                                                    onBlur={ev => this.handleBlur('boxMargin', num)}
                                                                                    value={product.boxMargin.toFixed(2)}
                                                                                    style={{ width: '70%' }}
                                                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                                                />
                                                                            }
                                                                        </Fragment>
                                                                        :
                                                                        <Fragment>
                                                                            --
                                                                        </Fragment>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        }
                                                        {product.options.addons.giftWrap &&
                                                            <tr>
                                                                <td style={{ height: '32px', verticalAlign: 'middle', textAlign: 'right' }}>
                                                                    {(product.costs && typeof (product.costs.book) == 'number' && typeof (product.giftWrapMargin) == 'number')
                                                                        ?
                                                                        <Fragment>
                                                                            {this.state.focus == 'giftWrapMargin_' + num
                                                                                ?
                                                                                <Input
                                                                                    type='number'
                                                                                    tabIndex={num + 1}
                                                                                    InputProps={{ inputProps: { min: 0, max: 500 } }}
                                                                                    onChange={ev => this.handleGiftWrapMarginChange(ev, num)}
                                                                                    onFocus={ev => this.handleFocus('giftWrapMargin', num)}
                                                                                    onBlur={ev => this.handleBlur('giftWrapMargin', num)}
                                                                                    value={product.giftWrapMargin}
                                                                                    style={{ width: '70%' }}
                                                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                                                />
                                                                                :
                                                                                <Input
                                                                                    type='number'
                                                                                    tabIndex={num + 1}
                                                                                    InputProps={{ inputProps: { min: 0, max: 500 } }}
                                                                                    onChange={ev => this.handleGiftWrapMarginChange(ev, num)}
                                                                                    onFocus={ev => this.handleFocus('giftWrapMargin', num)}
                                                                                    onBlur={ev => this.handleBlur('giftWrapMargin', num)}
                                                                                    value={product.giftWrapMargin.toFixed(2)}
                                                                                    style={{ width: '70%' }}
                                                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                                                />
                                                                            }
                                                                        </Fragment>
                                                                        :
                                                                        <Fragment>
                                                                            --
                                                                        </Fragment>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        }
                                                        {product.options.addons.dustCover &&
                                                            <tr>
                                                                <td style={{ height: '32px', verticalAlign: 'middle', textAlign: 'right' }}>
                                                                    --
                                                                </td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td style={{ height: '32px', verticalAlign: 'middle', textAlign: 'right' }}>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </Typography>
                                            </th>,
                                            <th style={Object.assign({ textAlign: 'right' }, tableCellStyle)}>
                                                <Typography>
                                                    <table>
                                                        <tr>
                                                            <td style={{ height: '32px', verticalAlign: 'middle', textAlign: 'right' }}>
                                                                {(product.costs && typeof (product.profit) == 'number') ?
                                                                    <Fragment>
                                                                        {this.state.focus == 'profit_' + num
                                                                            ?
                                                                            <Input
                                                                                type='number'
                                                                                tabIndex={num + 1}
                                                                                InputProps={{ inputProps: { min: 0 } }}
                                                                                onChange={ev => this.handleProfitChange(ev, num)}
                                                                                onFocus={ev => this.handleFocus('profit', num)}
                                                                                onBlur={ev => this.handleBlur('profit', num)}
                                                                                value={product.profit}
                                                                                style={{ width: '70%' }}
                                                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                                            />
                                                                            :
                                                                            <Input
                                                                                type='number'
                                                                                tabIndex={num + 1}
                                                                                InputProps={{ inputProps: { min: 0 } }}
                                                                                onChange={ev => this.handleProfitChange(ev, num)}
                                                                                onFocus={ev => this.handleFocus('profit', num)}
                                                                                onBlur={ev => this.handleBlur('profit', num)}
                                                                                value={product.profit.toFixed(2)}
                                                                                style={{ width: '70%' }}
                                                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                                            />
                                                                        }
                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        --
                                                                    </Fragment>
                                                                }
                                                            </td>
                                                        </tr>

                                                        {product.options.addons.box &&
                                                            <tr>
                                                                <td style={{ height: '32px', verticalAlign: 'middle', textAlign: 'right' }}>
                                                                    {(product.costs && typeof (product.boxProfit) == 'number')
                                                                        ?
                                                                        <Fragment>
                                                                            {this.state.focus == 'boxProfit_' + num
                                                                                ?
                                                                                <Input
                                                                                    type='number'
                                                                                    tabIndex={num + 1}
                                                                                    InputProps={{ inputProps: { min: 0 } }}
                                                                                    onChange={ev => this.handleBoxProfitChange(ev, num)}
                                                                                    onFocus={ev => this.handleFocus('boxProfit', num)}
                                                                                    onBlur={ev => this.handleBlur('boxProfit', num)}
                                                                                    value={product.boxProfit}
                                                                                    style={{ width: '70%' }}
                                                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                                                />
                                                                                :
                                                                                <Input
                                                                                    type='number'
                                                                                    tabIndex={num + 1}
                                                                                    InputProps={{ inputProps: { min: 0 } }}
                                                                                    onChange={ev => this.handleBoxProfitChange(ev, num)}
                                                                                    onFocus={ev => this.handleFocus('boxProfit', num)}
                                                                                    onBlur={ev => this.handleBlur('boxProfit', num)}
                                                                                    value={product.boxProfit.toFixed(2)}
                                                                                    style={{ width: '70%' }}
                                                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                                                />
                                                                            }
                                                                        </Fragment>
                                                                        :
                                                                        <Fragment>
                                                                            --
                                                                        </Fragment>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        }
                                                        {product.options.addons.giftWrap &&
                                                            <tr>
                                                                <td style={{ height: '32px', verticalAlign: 'middle', textAlign: 'right' }}>
                                                                    {(product.costs && typeof (product.giftWrapProfit) == 'number')
                                                                        ?
                                                                        <Fragment>
                                                                            {this.state.focus == 'giftWrapProfit_' + num
                                                                                ?
                                                                                <Input
                                                                                    type='number'
                                                                                    tabIndex={num + 1}
                                                                                    InputProps={{ inputProps: { min: 0 } }}
                                                                                    onChange={ev => this.handleGiftWrapProfitChange(ev, num)}
                                                                                    onFocus={ev => this.handleFocus('giftWrapProfit', num)}
                                                                                    onBlur={ev => this.handleBlur('giftWrapProfit', num)}
                                                                                    value={product.giftWrapProfit}
                                                                                    style={{ width: '70%' }}
                                                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                                                />
                                                                                :
                                                                                <Input
                                                                                    type='number'
                                                                                    tabIndex={num + 1}
                                                                                    InputProps={{ inputProps: { min: 0 } }}
                                                                                    onChange={ev => this.handleGiftWrapProfitChange(ev, num)}
                                                                                    onFocus={ev => this.handleFocus('giftWrapProfit', num)}
                                                                                    onBlur={ev => this.handleBlur('giftWrapProfit', num)}
                                                                                    value={product.giftWrapProfit.toFixed(2)}
                                                                                    style={{ width: '70%' }}
                                                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                                                />
                                                                            }
                                                                        </Fragment>
                                                                        :
                                                                        <Fragment>
                                                                            --
                                                                        </Fragment>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        }
                                                        {product.options.addons.dustCover &&
                                                            <tr>
                                                                <td style={{ height: '32px', verticalAlign: 'middle', textAlign: 'right' }}>
                                                                    --
                                                                </td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td style={{ height: '32px', verticalAlign: 'middle', textAlign: 'right' }}>

                                                            </td>
                                                        </tr>
                                                    </table>
                                                </Typography>
                                            </th>
                                        ]
                                    }
                                    <td colSpan={this.state.breakdown ? 1 : 2} style={Object.assign({ textAlign: 'right' }, tableCellStyle)}>
                                        <Typography>
                                            <table style={{ width: '100%' }}>

                                                <tr>
                                                    <td style={{ height: '32px', verticalAlign: 'middle', textAlign: 'right' }}>
                                                        {formatCurrency(product.price, true)}
                                                    </td>
                                                </tr>
                                                {product.options.addons.box &&
                                                    <tr>
                                                        <td style={{ height: '32px', verticalAlign: 'middle', textAlign: 'right' }}>
                                                            {formatCurrency(product.boxPrice, true)}
                                                        </td>
                                                    </tr>
                                                }
                                                {product.options.addons.giftWrap &&
                                                    <tr>
                                                        <td style={{ height: '32px', verticalAlign: 'middle', textAlign: 'right' }}>
                                                            {formatCurrency(product.giftWrapPrice, true)}
                                                        </td>
                                                    </tr>
                                                }
                                                {product.options.addons.dustCover &&
                                                    <tr>
                                                        <td style={{ height: '32px', verticalAlign: 'middle', textAlign: 'right' }}>
                                                            {formatCurrency(0, true)}
                                                        </td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <td style={{ height: '32px', verticalAlign: 'middle', textAlign: 'right' }}>
                                                        SubTotal: {(product.price + product.boxPrice + product.giftWrapPrice) > 0 ? formatCurrency(product.price + product.boxPrice + product.giftWrapPrice, true) : '--'}
                                                    </td>
                                                </tr>


                                            </table>
                                        </Typography>
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td colSpan={this.state.breakdown ? 2 : 1} rowSpan={2} style={{ verticalAlign: 'top', paddingTop: '20px' }}>
                                    <Button variant='outlined' disabled={!this.state.total > 0} onClick={this.handleClickShippingEstimator.bind(this)}>Shipping Estimator</Button>
                                </td>

                                <td colSpan={this.state.breakdown ? 2 : 1} style={{ textAlign: 'right', paddingRight: '20px' }}>
                                    <Typography variant='subtitle1'>Shipping</Typography>
                                </td>
                                <td style={Object.assign({ textAlign: 'right' }, tableCellStyle)}>
                                    {this.state.calculatingShipping
                                        ?
                                        <CircularProgress size={18} />
                                        :
                                        <Typography>{formatCurrency(this.state.sh, true)}</Typography>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={this.state.breakdown ? 2 : 1} style={{ textAlign: 'right', paddingRight: '20px' }}>
                                    <Typography variant='subtitle1'>Grand Total</Typography>
                                </td>
                                <td style={Object.assign({ textAlign: 'right' }, tableCellStyle)}>
                                    <Typography variant='h4'>{this.state.total > 0 ? formatCurrency(this.state.total, true) : '--'}</Typography>
                                </td>
                            </tr>

                        </table>
                        {(this.state.products && this.state.products.length > 0) &&
                            <div style={{ textAlign: 'right', marginTop: '40px' }}>
                                <Button variant='outlined' style={{ marginRight: '10px' }} disabled={!(this.state.products[0].costs && this.state.products[0].costs.book) > 0} onClick={this.handleClickReset.bind(this)}>Reset All</Button>
                                <Button variant='contained' disabled={!this.state.total > 0} onClick={this.handlePrint.bind(this)}>Print</Button>
                            </div>
                        }
                    </Grid>
                </Grid>

                <Dialog open={this.state.showResetConfirm} onClose={this.handleClickReset.bind(this)}>
                    <DialogContent>
                        <Typography>Are you sure? This will reset the calculator.</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClickReset.bind(this)} >
                            Cancel
                        </Button>
                        <Button onClick={this.handleReset.bind(this)} variant='contained'>
                            Reset
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.showShippingEstimator} onClose={this.handleClickShippingEstimator.bind(this)}>
                    <DialogContent>
                        <ShippingEstimator
                            weight={this.state.weight}
                            changeHandler={this.handleSelectShipType.bind(this)}
                            addressChangeHandler={this.handleShippingAddressChange.bind(this)}
                            address={this.state.address}
                            shipId={this.state.shipId}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClickShippingEstimator.bind(this)} >
                            Cancel
                        </Button>
                        <Button onClick={this.handleApplyShipType.bind(this)} disabled={!this.state.tempShipId} variant='contained'>
                            Apply
                        </Button>
                    </DialogActions>
                </Dialog>




            </div>
        )
    }
}

export default withStyles(styles)(PricingCalculator);
