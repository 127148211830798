import React from 'react'
import Typography from '@material-ui/core/Typography';
import formatCurrency from '../utils/formatCurrency';

function ProductPrice(props) {
    const { price } = props;

    return (

        <Typography>Price: {formatCurrency(price.price - price.discount)}
            {price.discount > 0 &&
                <s style={{ marginLeft: '5px', opacity: '.5' }}>{formatCurrency(price.price)}</s>
            }
        </Typography>

    )
}

export default ProductPrice;