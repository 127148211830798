import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ProductList from "../components/productList";
import CartTotals from "../components/totalsDisplay";
import ShipTo from "../components/shipTo";
import PayWith from "../components/payWith";
import ShipMethod from "../components/shipMethod";
import HandlingMethod from "../components/handlingMethod";
import Comments from "../components/commentsField";
import PromoCodeForm from "../components/promoCodeForm";
import ClientEmailForm from "../components/clientEmailForm";
import Checkbox from "@material-ui/core/Checkbox";
import PrettyLink from "../components/prettyLink";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataSource from "../utils/DataSource";
import ErrorHandler from "../utils/ErrorHandler";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import formatCurrency from "../utils/formatCurrency";
import PopUp from "./PopUp";
import styled from "styled-components";
import { DateTime } from 'luxon';

DateTime.now().setZone('America/Los_Angeles');

console.log('datenow', DateTime.now().setZone('America/Los_Angeles').toISO());

const TypeCSS = `
    .typecss {
        color: red;
        font-size: 13px;
        font-weight: 700;
        margin-top: 20px;
    }
`;

const StyledDialog = styled(Dialog)`
  & > div > div {
    border: 2px solid #cabfab;
    border-radius: 0;
  }
`;
const StyledDialogTitle = styled(DialogTitle)`
  background-color: #cabfab;
  margin-bottom: 10px !important;
  h6 {
    color: #ffffff;
    font-size: 28px;
    font-family: "Helvetica";
  }
`;
const Subscription = styled.div`
  margin: 0 !important;
  &,
  & > * {
    color: #333 !important;
    font-size: 11px;
    font-family: Montserrat, Cormorant Garamond, Courier, Helvetica;
    font-weight: 400;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  justify-content: space-between !important;
  width: 100%;
  padding: 0 16px 24px 24px;
  margin: 0 !important;
`;
const Buttons = styled.div`
  display: flex;
`;
const StyledButton = styled(Button)`
  border: 1px solid #cabfab !important;
  border-radius: 0 !important;
  margin-left: 10px !important;
  font-size: 14px !important;
  &[type="submit"] {
    color: #cabfab !important;
  }
`;

const infoStyle = {
    color: "red",
    fontSize: '13px',
    lineHeight: '21px',
    marginTop: '10px'
};

function CheckoutReview(props) {
    const {
        uid,
        products,
        totals,
        shipping_methods,
        shipping_address,
        payment_methods,
        store_credit,
        handling_methods,
        address,
        type,
        open,
        handleClose,
        handleShipMethodChange,
        loading_totals,
        handleHandlingMethodChange,
        handleShipAddressChange,
        handleShipAddressRemove,
        handlePaymentMethodChange,
        handleStoreCreditApply,
        handleUseCreditInputChange,
        storeCreditInputAmount,
        setCardEditorOpen,
        handleAddCard,
        handleRemoveCard,
        cardEditorOpen,
        handlePromoCodeApply,
        handleSubmit,
        handlePopupClose,
        handlePopupOpen,
        addresses,
        send_status_emails,
        set_send_status_emails,
        marketing_use,
        set_marketing_use,
        handleSignatureChange,
        requireSignature,
        handleProductsUpdate,
        submitting,
        show_agreement_popup,
        clientEmail,
        handleClientEmailChange,
        enqueueSnackbar,
        comments,
        handleCommentsChange,
        show_usps_popup,
        handleShipMethodChangeSetUsps,
        show_incorrect_address_popup,
        handleShipMethodIncorrectAddressPopup,
        blockButton
    } = props;

    const [countryOptions, setCountryOptions] = useState(false);
    const [updatingCart, setUpdatingCart] = useState(false);

    const [selectedMethod, setSelectedMethod] = useState(false);
    const [handlingSelected, setHandlingSelected] = useState(false);

    const handleSelectedMethod = (data) => {
        console.log('selectedMethod1', selectedMethod);

        setSelectedMethod(data);
    }

    const handleHandlingSelected = (data) => {
        console.log('handlingSelected1', handlingSelected);

        setHandlingSelected(data);
    }

    var typeFC = 1;
    var typeTxt1 = '';
    var typeTxt2 = ''; 
    var typeTxt3 = '';

    var typehide = {
        display: 'none'
    };

    console.log('datetimef', DateTime.now(), DateTime.fromISO('2023-11-16T00:00:00').toUTC(), DateTime.fromISO('2023-11-16T23:59:59').toUTC(), selectedMethod, handlingSelected);

    if(DateTime.now() >= DateTime.fromISO('2023-11-17') && DateTime.now() <= DateTime.fromISO('2023-11-20') && selectedMethod == 'GROUND-HOME-DELIVERY' && handlingSelected == 'standard' ) {
        // 
        console.log('Yes in it.');
        typeFC = 2;
        typeTxt1 = 'The shipment method you have selected does not qualify to make it on time for the holidays.';
        typeTxt2 = 'Please view our ';
        typeTxt3 = ' and update your shipment method if you are wanting to guarantee it is delivered by Dec 22nd!';

        typehide = {
            display: 'inline'
        }
      
    } else if(DateTime.now() >= DateTime.fromISO('2023-11-21T00:00:00').toUTC() && DateTime.now() <= DateTime.fromISO('2023-11-21T23:59:59').toUTC() && ( selectedMethod == 'GROUND-HOME-DELIVERY' || selectedMethod == 'FEDEX-2-Day' ) && handlingSelected == 'standard') {
        
        console.log('In 2nd');
        typeFC = 3;
        typeTxt1 = 'The shipment method you have selected does not qualify to make it on time for the holidays.';
        typeTxt2 = 'Please view our ';
        typeTxt3 = ' and update your shipment method if you are wanting to guarantee it is delivered by Dec 22nd!';

        typehide = {
            display: 'inline'
        }

    } else if (DateTime.now() >= DateTime.fromISO('2023-11-22') && DateTime.now() <= DateTime.fromISO('2023-12-01') && handlingSelected == 'standard' ) {
        console.log('In 3rd');
        typeFC = 4;
        typeTxt1 = 'The shipment method you have selected does not qualify to make it on time for the holidays.';
        typeTxt2 = 'Please view our ';
        typeTxt3 = ' and update your shipment method if you are wanting to guarantee it is delivered by Dec 22nd!';

        typehide = {
            display: 'inline'
        }
    }

    function checkHandleHandlingMethodChange(ev) {
        // if(ev.target.value=='rush'){

        //check to see if order contains linen box - if so then display message
        // if(products.find(p=>p.type.name=="linen_box")!=undefined){
        //     console.log('products',products);
        //     enqueueSnackbar('Note: Your linen box(es) will be shipped separately, in the standard turnaround time.');
        // }
        // }

        //proceed as normal
        handleHandlingMethodChange(ev);
    }

    function handleStatusEmailClick(ev) {
        set_send_status_emails(ev.target.checked);
    }

    function handleMarketingClick(ev) {
        if( ev.target.checked == true ) {
            set_marketing_use( true );
        } else {
            set_marketing_use( false );
        }
    }

    function handleQuantityChange(cart_product_id, qty) {
        setUpdatingCart(true);
        if (qty > 0) {
            //change qty
            let cart_product = products.find((p) => p._id == cart_product_id);
            console.log("increasing qty of product", cart_product_id, cart_product);

            //exception for kiss books
            if (cart_product.type == "5c0ed23b7c2d3d2a323e3b8d") {
                //create new products
                let new_product = JSON.parse(JSON.stringify(cart_product));
                delete new_product.date_created;
                delete new_product.date_updated;
                delete new_product.options_pretty;
                delete new_product.total;
                delete new_product.user;
                delete new_product._v;
                delete new_product._id;
                delete new_product.images.icon.url;

                let promises = [];
                for (var i = 1; i < qty; i++) {
                    promises.push(
                        new Promise(function (resolve, reject) {
                            DataSource.post("/cart/products", new_product)
                                .then(
                                    function (res) {
                                        resolve();
                                    }.bind(this)
                                )
                                .catch(
                                    function (err) {
                                        console.log("ERR", err);
                                        ErrorHandler(err, this.props);
                                        reject();
                                    }.bind(this)
                                );
                        })
                    );
                }
                Promise.all(promises).then(function () {
                    handleProductsUpdate();
                    setUpdatingCart(false);
                });
            } else {
                DataSource.patch("/cart/products/" + cart_product_id, { quantity: qty })
                    .then(
                        function (res) {
                            handleProductsUpdate();
                            setUpdatingCart(false);
                        }.bind(this)
                    )
                    .catch(
                        function (err) {
                            enqueueSnackbar(err.response.data.description, { variant: 'error' });
                            handleProductsUpdate();
                            setUpdatingCart(false);
                        }.bind(this)
                    );
            }
        } else {
            //delete
            DataSource.delete("/cart/products/" + cart_product_id)
                .then(
                    function (res) {
                        handleProductsUpdate();
                        setUpdatingCart(false);
                    }.bind(this)
                )
                .catch(
                    function (err) {
                        console.log("ERR", err);
                        ErrorHandler(err, this.props);
                    }.bind(this)
                );
        }
    }

    return (
        <div>
            <Typography variant="h6" gutterBottom>
                Review Your Order
            </Typography>
            {products && totals && shipping_methods ? (
                <Grid container spacing={16}>
                    <Grid item xs={12} sm={4}>
                        <ShipTo
                            fields={shipping_address}
                            addresses={addresses}
                            handleSubmit={handleShipAddressChange}
                            handleShipAddressRemove={handleShipAddressRemove}
                        />
                        <br />
                        <HandlingMethod
                            methods={handling_methods}
                            handleChange={checkHandleHandlingMethodChange}
                            disabled={loading_totals || updatingCart}
                            onHandlingSelected={handleHandlingSelected}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <ShipMethod
                            methods={shipping_methods}
                            handleChange={handleShipMethodChange}
                            handleSignatureChange={handleSignatureChange}
                            requireSignature={requireSignature}
                            disabled={loading_totals || updatingCart}
                            onSetSelectedMethod={handleSelectedMethod}
                        />
                        <PopUp
                            show={show_usps_popup}
                            message={
                                "Due to the Covid-19 pandemic, USPS has been experiencing delays and lost shipments as their normal transit routes have been interrupted or closed. Please know that Kiss is not responsible if you choose USPS as your shipping courier and your shipment is delayed or lost in transit."
                            }
                            setShow={handleShipMethodChangeSetUsps}
                        ></PopUp>
                        <PopUp
                            show={show_incorrect_address_popup}
                            message={
                                "The address you entered may not be correct. Please check the address and try again to see all available shipping quotes"
                            }
                            setShow={handleShipMethodIncorrectAddressPopup}
                        ></PopUp>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <PayWith
                            methods={payment_methods}
                            store_credit={store_credit}
                            handleChange={handlePaymentMethodChange}
                            disabled={loading_totals || updatingCart}
                            handleStoreCreditApply={handleStoreCreditApply}
                            handleUseCreditInputChange={handleUseCreditInputChange}
                            storeCreditInputAmount={storeCreditInputAmount}
                            setCardEditorOpen={setCardEditorOpen}
                            cardEditorOpen={cardEditorOpen}
                            handleAddCard={handleAddCard}
                            handleRemoveCard={handleRemoveCard}
                        />
                        <div style={infoStyle} >We have recently enhanced our payment process in order to improve our services. As a result, we kindly request that you provide your billing details as part of the upgraded payment process.</div>
                    </Grid>
                    <div class="typecss">
                        
                        <style>{TypeCSS}</style>
                        {typeTxt1}
                        <br />
                        {typeTxt2}
                        <a href="https://www.kiss.us/holiday-deadlines/" target="_blank" style={ typehide }>Holiday Deadlines</a>
                        {typeTxt3}

                    </div>

                    {/* <Typography variant="caption">
              {requireSignature ? (
                <em>
                  Please Note: Your order will not be delivered unless someone
                  is there to sign for the package(s).
                </em>
              ) : (
                <em>
                  Please Note: Depending on your delivery destination, FedEx may
                  require a signature for delivery. USPS does not require a
                  signature for delivery. We suggest selecting a shipping
                  address, such as your workplace, where you know there will be
                  someone to sign for your package(s). See our{" "}
                  <PrettyLink
                    href="http://kiss.us/mykiss/home/shipping_policy"
                    target="_blank"
                  >
                    shipping policy
                  </PrettyLink>{" "}
                  for more information.
                </em>
              )}
            </Typography> */}

                    <Grid item xs={12}>
                        <hr style={{ border: "1px solid #eee" }} />
                        <br />
                        <Grid container spacing="8">
                            <Grid item xs={12} sm={5}>
                                <Typography variant="subtitle1">Items</Typography>
                                <ProductList
                                    products={products}
                                    show_actions={true}
                                    handleQuantityChange={handleQuantityChange}
                                    updatingCart={updatingCart}
                                />
                            </Grid>
                            <Grid item xs={0} sm={1}></Grid>
                            <Grid item xs={12} sm={6}>
                                <Comments handleChange={handleCommentsChange} comments />
                                {uid == "5e5fff00f18ccd546a1db24f" && ( //5bf5c65df14c4e753f272a0a
                                    <div style={{ marginTop: "20px" }}>
                                        <ClientEmailForm
                                            savedEmail={clientEmail}
                                            handleApply={handleClientEmailChange}
                                        />
                                    </div>
                                )}
                                <div style={{ marginTop: "20px" }}>
                                    <PromoCodeForm handleApply={handlePromoCodeApply} />
                                </div>
                                <div style={{ marginTop: "20px" }}>
                                    <CartTotals
                                        totals={totals}
                                        loading={loading_totals}
                                        payment_methods={payment_methods}
                                    />
                                </div>
                                <div style={{ marginTop: "20px" }}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={<Checkbox value="checkedH" />}
                                        label="Send me email updates on my order progress &amp; when it ships"
                                        onClick={(e) => handleStatusEmailClick(e)}
                                        checked={send_status_emails}
                                    />
                                </div>
                                <div style={{ marginTop: "20px" }}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={<Checkbox value="yes" />}
                                        label="I give permission for Kiss to reprint this book for use in marketing images/videos"
                                        onClick={(e) => handleMarketingClick(e)}
                                        checked={marketing_use}
                                    />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "right" }}>
                                    <Grid container>
                                        <Grid item xs={6} sm={0}></Grid>
                                        <Grid item xs={2} sm={0}>
                                            {/*loading_totals &&
                                            <CircularProgress style={{marginTop:'5px'}} size={20}/>
                                        */}
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                disabled={loading_totals || updatingCart || blockButton}
                                                onClick={(e) => handlePopupOpen()}
                                            >
                                                Place Order
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>

                            <Typography style={{ marginTop: "10px" }} variant="caption">
                                Orders will never ship with an invoice, pricing information, or
                                Kiss branding. We do this to allow for simple drop-shipping to
                                your customers. To view or print your invoice, please click the
                                order number on your My Kiss Dashboard.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <div style={{ textAlign: "center", marginTop: "20px", width: "100%" }}>
                    <CircularProgress />
                </div>
            )}

            <StyledDialog
                open={show_agreement_popup}
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="sm"
                fullWidth
            >
                <StyledDialogTitle>Final Order Confirmation</StyledDialogTitle>
                <DialogContent>
                    <Typography>
                        We move fast so you and your clients can get albums as quickly as
                        possible! Production begins the moment you press ‘Confirm Order.’
                        Because of this, we are unable to make any changes to an order once
                        it has been submitted. If you have any questions or concerns, please
                        contact our team of experts prior to placing your order.
                    </Typography>
                </DialogContent>
                <StyledDialogActions>
                    <Subscription>
                        Email&nbsp;
                        <PrettyLink href="mailto:support@kiss.us">
                            support@kiss.us
                        </PrettyLink>
                        &nbsp;or call us at&nbsp;
                        <PrettyLink href="tel:(831) 278-4793">(831) 278-4793</PrettyLink>.
                    </Subscription>
                    <Buttons>
                        <StyledButton
                            onClick={handlePopupClose}
                            color="secondary"
                            type="cancel"
                        >
                            Cancel
                        </StyledButton>
                        <StyledButton onClick={handleSubmit} type="submit">
                            Confirm order
                        </StyledButton>
                    </Buttons>
                </StyledDialogActions>
            </StyledDialog>

            <Dialog
                open={submitting}
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Completing your order</DialogTitle>
                <DialogContent>
                    <Typography>
                        This will take just a few moments{" "}
                        <CircularProgress
                            style={{ marginTop: "8px", marginLeft: "10px" }}
                            size={20}
                        />
                    </Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default CheckoutReview;
