//load json
import countries from '../config/countries.json';
import states from '../config/states.json';

function query(type,option){
    if(type=='countries'){
        return countries;
    }else if(type=='country' && option){
        return countries.find(c=>c.name==option);
    }else if(type=='states' && option){
        console.log('looking for states that match country id:',option,states.filter(s=>s.country_id==option));
        return states.filter(s=>s.country_id==option);
    }else{
        throw new Error('Incorrect options passed to countries.js utility: '+type+' '+option);
    }

    
}


export default query;