import React, { Fragment, useState } from 'react';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import AddressForm from '../components/addressForm';
import CreditCardInput from 'react-credit-card-input';

const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'), 
  });

function PaymentForm(props){

    const {showPromoCode, handleSubmit, handleCancel, cancel, submit} = props;        
    const [number, setNumber] = useState('');
    const [exp, setExp] = useState('');
    const [cvv, setCvv] = useState('');
    const [code, setCode] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [address, setAddress] = useState({});

    function handler(e){
        setIsSubmitting(true);
        e.preventDefault();
        console.log('stopped default',e,number);
        handleSubmit({number,exp,cvv,code,address})
        .then(res=>setIsSubmitting(false))
    }

    function handleAddressChange(address){
        setAddress(address);
    }
    const ccinputStyles = {
        background:'rgba(0, 0, 0, 0.09)',
        fontSize:'16px',
        padding:'14px',
        borderBottom:'1px solid #666',
        marginLeft:'0px',
        minWidth:'75px'
    }

    return (
        
        <form onSubmit={handler}>
                        
            <CreditCardInput
            fieldClassName="input"
            cardNumberInputProps={{name:'number',onChange:e=>setNumber(e.target.value),style:ccinputStyles}}
            cardExpiryInputProps={{name:'exp',onChange:e=>setExp(e.target.value),style:ccinputStyles}}
            cardCVCInputProps={{name:'cvv',onChange:e=>setCvv(e.target.value),style:ccinputStyles,maxLength:'4'}}
            cardImageStyle={{width:'0px',marginLeft:'-20px'}}
            containerStyle={{padding:'0px', margin:'0px'}}
            fieldStyle={{height:'60px'}}
            />   
            <AddressForm type='billing' addresses={[]} changeHandler={handleAddressChange} />                         
        
            {showPromoCode &&                 
            <Fragment>
                <br/><br/>
                <Typography>Promo Code</Typography>
                <TextField 
                variant='filled'
                label="Code"
                name='code'
                fullWidth
                onChange={e=>setCode(e.target.value)}
                />
            </Fragment>                
            }

            <br/>                
            <Button type="submit" disabled={isSubmitting} style={{float:'right'}}>
            {submit ? submit : 'Submit'}
            </Button> 
            <br/> 
            <br/> 
        </form>
       
    );

}


export default PaymentForm;