import React, { Fragment} from 'react'
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';

function ProductActions(props) {
    const {handleSubmit, submitting, allowed, spinner, button_options, display_sold} = props;
    var isButtonOptionsExist = button_options != undefined;
    return (
        <Fragment>
            {display_sold &&
                <span>SOLD OUT</span>
            }

            {!display_sold &&
                <div>
                    {isButtonOptionsExist
                        ? <Button
                            style={button_options.style}
                            disabled={submitting || !button_options.attributes.allowed}
                            variant='contained'
                            onClick={e => handleSubmit()}
                        >
                            {button_options.attributes.label}
                        </Button>
                        : <Button disabled={submitting || !allowed} variant='contained' onClick={e => handleSubmit()}>
                            {spinner &&
                                <CircularProgress size={30}/>
                            }
                            Add To Cart
                        </Button>
                    }
                </div>
            }
        </Fragment>
    )
}

export default ProductActions;