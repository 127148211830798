import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router } from "react-router-dom";
import Grid from '@material-ui/core/Grid';

/*Theme*/
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import companyTheme from './theme/companyBaseTheme';
//import Styles from './Styles'; 
import Routes from './Routes';
import Styles from './Styles';
import UpscopeShare from './views/UpscopeShare';

class App extends React.Component {
    state = {
        searchOpen: false,
        searchResults: [],
        searchString: '',
    }

    render() {
        const { classes, theme } = this.props;

        const style_btn = {
            color: '#fff',
            fontSize: '14px',
            fontWeight: '700'
        };

        const style_banner = {
            width: '100%',
            textAlign: 'center',
            padding: '10px',
            backgroundColor: '#c6ac8f',
            marginBottom: '0px',
            color: '#fff',
            fontSize: '14px'
        };

        const style_below_banner = {
            paddingTop: '0px'
        }

        //console.log('searchResults',this.state.searchResults,'searchOpen',this.state.searchOpen);
        return (
            <MuiThemeProvider theme={companyTheme}>
                <Grid container spacing={2}>
                    <div style={style_banner}>
                        Try our all new Beta V2 Shop! <a href="https://shopv2.kiss.us/" style={style_btn}>Click Here</a>
                    </div>
                    <Grid item xs={12}>
                        <Grid container justify='center' spacing={2}>
                            <Grid item style={{ maxWidth: '960px' }}>
                                <div className={classes.root} style={style_below_banner}>
                                    <SnackbarProvider preventDuplicate={true}>
                                        <Router>

                                            <CssBaseline />
                                            <Routes
                                                searchResults={this.state.searchResults}
                                                searchOpen={this.state.searchOpen} />

                                        </Router>
                                    </SnackbarProvider>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <UpscopeShare />

            </MuiThemeProvider>
        );
    }
}

export default withStyles(Styles, { withTheme: true })(App);