import React, { Fragment } from "react";
import { withSnackbar } from "notistack";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DataSource from "../utils/DataSource";
import PrettyLink from "../components/prettyLink";
import Header from "../components/header";
import ImageViewer from "../components/imageViewer";
import ProductDescription from "../components/productDescription";
import ProductOptions from "../components/productOptions";
import ProductPrice from "../components/productPrice";
import ProductActions from "../components/productActions";
import axios from "axios";
import Cookies from "js-cookie";
import ErrorHandler from "../utils/ErrorHandler";
import BreadCrumbs from "../components/breadCrumbs";
import SiteLive from "../utils/siteLive";
import ReactPixel from "react-facebook-pixel";
import omit from "../utils/omit";
import DialogFreeSwatches from "../components/freeSwatchesDiscountModal";
import formatCurrency from '../utils/formatCurrency';

const styles = (theme) => ({});

const sortFreeSwatchesOptions = (options) => {
  var colors = Object.values(options);
  const colorsFilter = colors.filter((option) => {
    return option !== "default";
  });
  if (colorsFilter.length < 5) {
    while (colorsFilter.length < 5) {
      colorsFilter.push("default");
    }
  }
  var options = colorsFilter.reduce((object, option, index) => {
    object[`color_${index + 1}`] = option;
    return object;
  }, {});

  return options;
};
class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: false,
      options: {},
      price: "",
      submitting: false,
      current_image: 0,
      show_login: false,
      images: {},
      required_options_set: false,
      guest_email: "",
      guest_marketing: false,
      account_email: "",
      account_password: "",
      allowed_to_add: true,
      buyAllSwatches: false,
      order_once: false,
    };
  }

  handleSelectChange(ev) {
    console.log("something", ev.target.value);
    const options = Object.assign({}, this.state.options);
    options[ev.target.name] = ev.target.value;
    this.setState(
      { options },
      function () {
        this.fetchProductPrice(this.state.product._id, options);
        this.checkRequired();
      }.bind(this)
    );
  }

  handleTextChange(ev) {
    const options = Object.assign({}, this.state.options);
    options[ev.target.name] = ev.target.value;
    this.setState(
      { options },
      function () {
        this.fetchProductPrice(this.state.product._id, options);
        this.checkRequired();
      }.bind(this)
    );
  }

  handleSwitchChange(ev) {
    const options = Object.assign({}, this.state.options);
    options[ev.target.name] = ev.target.checked;
    this.fetchProductPrice(this.state.product._id, options);
  }

  handleOptionsChanged(options_to_save) {
    const options = Object.assign({}, this.state.options);

    Object.keys(options_to_save).map((option_name) => {
      options[option_name] = options_to_save[option_name];
    });

    this.setState({ options });

    this.fetchProductPrice(this.state.product._id, options);
  }

  handleImagesChanged(images) {
    this.setState({ images });
  }

  checkRequired() {
    //
    if (!this.state.original_options) {
      console.log("NO OPTIONS TO CHECK");
      return;
    }
    var filled = true;
    this.state.original_options.map((o) => {
      console.log("o", o);
      if (o.required) {
        if (Object.keys(this.state.options).includes(o.name)) {
          if (!this.state.options[o.name].toString()) {
            console.log("could not find required option.");
            filled = false;
          }
          console.log(
            "this.state.options[o.name].toString()",
            this.state.options[o.name].toString()
          );
        } else {
          console.log("key does not exist");
          filled = false;
        }
      }
    });
    console.log("required_options_set", filled);
    this.setState({ required_options_set: filled });
  }

  fetchUser() {
    console.log("this.props", this.props);
    return new Promise(
      function (resolve, reject) {
        //Get the user
        DataSource.get("/user/")
          .then(
            function (res) {
              console.log("user RES", res.data);
              resolve(res.data);
            }.bind(this)
          )
          .catch(
            function (err) {
              console.log("ERR", err);
              ErrorHandler(err, this.props);
              reject(err);
            }.bind(this)
          );
      }.bind(this)
    );
  }

  fetchProduct() {
    return new Promise(
      function (resolve, reject) {
        //Get the product
        DataSource.get("/products/" + this.props.match.params.name)
          .then(
            function (res) {
              console.log("product RES", res.data);
              resolve(res.data);
            }.bind(this)
          )
          .catch(
            function (err) {
              console.log("ERR", err);
              ErrorHandler(err, this.props);
              reject(err);
            }.bind(this)
          );
      }.bind(this)
    );
  }

  fetchProductPrice(pid, options) {
    DataSource.post("/products/calculate_product/" + pid, options)
      .then(
        function (res) {
          console.log("price RES", res.data);

          this.setState({ price: res.data });
          //discounts?
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("ERR", err);
          ErrorHandler(err, this.props);
        }.bind(this)
      );
  }

  handleSubmitOnePageOrder = (userData) => {
    this.setState({
      guest_email: userData.email,
    });

    const orderOnceError = () => {
      this.setState({ order_once: true });
    };

    const handleError = (err) => {
      console.log("ERR", err);
      this.setState({ submitting: false });
      if (err?.response?.data?.type === "ONLY_ORDER_ONCE") {
        orderOnceError();
      } else {
        ErrorHandler(err, this.props);
      }
    };

    const submitFreeSwatches = () => {
      if (
        Cookies.get(
          process.env.REACT_APP_STORE_USER_TOKEN
            ? process.env.REACT_APP_STORE_USER_TOKEN
            : "store_user_token"
        )
      ) {
        this.setState({ submitting: true });

        const product = {
          name: this.state.product.name_pretty,
          type: this.state.product._id,
          quantity: 1,
          images: this.state.images,
          options: sortFreeSwatchesOptions(this.state.options),
        };
        const address = {
          ...omit(userData, "email"),
          is_default: false,
          save: true,
        };

        DataSource.post("/cart/products/", product)
          .then(() => {
            DataSource.put("/cart/addresses/shipping", address)
              .then(() => {
                DataSource.post("/cart/process", { skipPayment: true })
                  .then(({ data }) => {
                    this.props.history.push("/confirmation/" + data.order._id);
                  })
                  .catch((err) => handleError(err));
              })
              .catch((err) => handleError(err));
          })
          .catch((err) => handleError(err));
      }
    };
    this.handleLogin(submitFreeSwatches);
  };

  handleSubmit = () => {
    if (
      !Cookies.get(
        process.env.REACT_APP_STORE_USER_TOKEN
          ? process.env.REACT_APP_STORE_USER_TOKEN
          : "store_user_token"
      )
    ) {
      //show dialog
      this.setState({ show_login: true });
      return false;
    }

    this.setState({ submitting: true });
    const options =
      this.state.product.name === "free_sample_swatches"
        ? sortFreeSwatchesOptions(this.state.options)
        : this.state.options;

    this.setState({ spinner: true });

    //send request
    const product = {
      name: this.state.product.name_pretty,
      type: this.state.product._id,
      quantity: 1,
      images: this.state.images,
      options,
    };
    DataSource.post("/cart/products/", product)
      .then((res) => {
        //redirect to cart
        this.props.history.push("/cart", {});
        this.setState({ spinner: false });
      })
      .catch((err) => {
        console.log("ERR", err);
        this.setState({ submitting: false });
        this.setState({ spinner: false });
        if (err?.response?.data?.type === "ONLY_ORDER_ONCE") {
          this.setState({ order_once: true });
        } else {
          ErrorHandler(err, this.props);
        }
      });
  }

  handleThumbnailClick(num) {
    this.setState({ current_image: num });
  }

  handleGuestEmailChange(e) {
    this.setState({ guest_email: e.target.value });
  }

  handleGuestMarketingChange(e) {
    this.setState({ guest_marketing: e.target.value });
  }

  handleAllowedToAddChange(allow) {
    this.setState({ allowed_to_add: allow });
  }
  /*
  handleAccountEmailChange(e){
    this.setState({'account_email':e.target.value});
  }

  handleAccountPasswordChange(e){
    this.setState({'account_password':e.target.value});
  }

  handleLoginAccount(e){
    var options = {
      "username": this.state.account_email,
      "password": this.state.account_password,
      "login": true
    }


   axios.defaults.withCredentials = false;
   axios.defaults.responseType = 'text';
   axios.defaults.headers = {     
     'Content-Type':'application/x-www-form-urlencoded'}; 
   


   axios.post('http://localhost/mykiss/login',qs.stringify(options)) 
   .then(res=>{
       console.log('kiss login try res',res);
       if(res.data.success){
           //store token in cookie
           //Cookies.set((process.env.REACT_APP_STORE_USER_TOKEN ? process.env.REACT_APP_STORE_USER_TOKEN : 'store_user_token'),res.data.token);

           //show message
           //this.props.enqueueSnackbar('Logged In',{variant:'success'}); 

           //force set the token to datasource
       
       }else{        
        this.props.enqueueSnackbar(res.data.message,{variant:'error'}); 
       }
   })
   .catch(err=>{
       console.log('err',err);
   })

   

  }
*/
  handleLogin = (successFn) => {
    //send email and other creds to the login endpoint
    /*var creds = {
      "client_id":"5bf5b16b5849fd73982eec8f",
      "client_secret": "khs786kj23lmacwe9sh17sbd9s5256",
      "email": this.state.guest_email
    }

    axios.post((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:3000')+'/login',creds)
    .then(res=>{
        console.log('res',res);
        if(res.data.success){
            //store token in cookie
            Cookies.set((process.env.REACT_APP_STORE_USER_TOKEN ? process.env.REACT_APP_STORE_USER_TOKEN : 'store_user_token'),res.data.token);

            //show message
            //this.props.enqueueSnackbar('Logged In',{variant:'success'}); 

            //force set the token to datasource
            DataSource.defaults.headers = {
              'Authorization' : 'bearer '+Cookies.get((process.env.REACT_APP_STORE_USER_TOKEN ? process.env.REACT_APP_STORE_USER_TOKEN : 'store_user_token')),
              'Content-Type':'application/json'};
             
            //Add to cart
            this.handleSubmit();            
        }
    })
    .catch(err=>{
        console.log('err',err);
    })*/
    this.setState({ submitting: true });

    var options = {
      email: this.state.guest_email,
    };

    DataSource.post("/login/", options)
      .then(
        function (res) {
          this.setState({ submitting: false });
          if (res.data.success) {
            //store token in cookie
            Cookies.set(
              process.env.REACT_APP_STORE_USER_TOKEN
                ? process.env.REACT_APP_STORE_USER_TOKEN
                : "store_user_token",
              res.data.token,
              {
                domain: process.env.REACT_APP_STORE_COOKIE_DOMAIN
                  ? process.env.REACT_APP_STORE_COOKIE_DOMAIN
                  : "localhost",
              }
            );

            //show message
            //this.props.enqueueSnackbar('Logged In',{variant:'success'});

            //force set the token to datasource
            DataSource.defaults.headers = {
              Authorization:
                "bearer " +
                Cookies.get(
                  process.env.REACT_APP_STORE_USER_TOKEN
                    ? process.env.REACT_APP_STORE_USER_TOKEN
                    : "store_user_token"
                ),
              "Content-Type": "application/json",
            };
            //Add to cart
            successFn();
          } else {
            ErrorHandler(res.data.message, this.props);
          }
        }.bind(this)
      )
      .catch(
        function (err) {
          this.setState({ submitting: false });
          console.log("ERR", err);
          ErrorHandler(err, this.props);
        }.bind(this)
      );
  };
  handleLoginGuest = () => {
    this.handleLogin(
      this.state.buyAllSwatches
        ? this.handleBuyAllSwatchesWithDiscount
        : this.handleSubmit
    );
    this.setState({ show_login: false });
  };

  handleBuyAllSwatchesWithDiscount = () => {
    if (
      !Cookies.get(
        process.env.REACT_APP_STORE_USER_TOKEN
          ? process.env.REACT_APP_STORE_USER_TOKEN
          : "store_user_token"
      )
    ) {
      //show dialog
      this.setState({ show_login: true, buyAllSwatches: true });
      return false;
    }

    const handleError = (err) => {
      console.log("ERR", err);
      this.setState({ submitting: false });
      ErrorHandler(err, this.props);
    };

    this.setState({ submitting: true });
    DataSource.get("/products/all_swatches")
      .then(({ data }) => {
        const config = {
          name: data.name_pretty,
          type: data._id,
          quantity: 1,
          images: {},
          options: {},
        };

        DataSource.post("/cart/products/", config)
          .then(() => {
            DataSource.post("/cart/discounts/code/", { code: "get50pswatches" })
              .then(() => {
                this.setState({ submitting: false, buyAllSwatches: false });
                this.props.history.push("/cart", {});
              })
              .catch((err) => handleError(err));
          })
          .catch((err) => handleError(err));
      })
      .catch((err) => handleError(err));
  };

  handleLoginClose(e) {
    this.setState({ show_login: false });
  }

  componentDidMount() {
    //check for site live
    SiteLive();

    //fb pixel
    if (process.env.REACT_APP_FB_PIXEL_ID) {
      ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID);
      ReactPixel.pageView(); // For tracking page view
    }

    this.fetchProduct().then(
      function (res) {
        console.log("loaded", res);

        if (res.deleted) {
          //redirect to 404
          this.props.history.push("/404", {});
        }

        //determine option defaults
        var options = {};
        if (res.options) {
          res.options.map((option) => {
            if (option.type == "values") {
              var oValues = option.values.filter((optionValue) => {
                return optionValue.enabled;
              });
              option.values = oValues;
              var found = option.values.find((v) => v.default);
              var def = found ? found.name : option.values[0].name;
              options[option.name] = def;
            }
          });
        }

        console.log("res", res);
        console.log("options", options);

        if (res.view != "default") {
          //load component
          //const component = '../components/'+res.view;
          //import custom_view from component;

          import("../components/" + res.view).then((r) => {
            this.setState({ view: r.default });
          });
        }

        this.setState({
          product: res,
          productBackUp: JSON.parse(JSON.stringify(res)),
          options: options,
          original_options: res.options ? res.options : [],
        });

        //set page title
        document.title = "Kiss Shop - " + res.name_pretty;

        //get price
        this.fetchProductPrice(res._id, options);

        //get user
        if (
          Cookies.get(
            process.env.REACT_APP_STORE_USER_TOKEN
              ? process.env.REACT_APP_STORE_USER_TOKEN
              : "store_user_token"
          )
        ) {
          this.fetchUser().then(
            function (res) {
              console.log("fetched user", res);
              this.setState({ user: res });
            }.bind(this)
          );
        }

        //check required options
        this.checkRequired();
      }.bind(this)
    );
  }

  renderCutOutPrice() {
    return (
        <Typography>
            Price: <s style={{ textDecoration: 'line-through', color: 'grey', marginRight: '20px;' }}>$40.00</s> $20.00
        </Typography>
    );
}

renderBoxCutOutPrice() {
    return (
        <Typography>
            Price: <s style={{ textDecoration: 'line-through', color: 'grey', marginRight: '20px;' }}>$99.00</s> $49.50
        </Typography>
    );
}


  render() {
    if (!this.state.product) {
      return null;
    }

    if (this.state.product.view != "default") {
      //load component
      //import custom_view from '../components/'+this.state.product.view;
      console.log("loaded custom view file", this.state.view);
      if (!this.state.view) {
        return null;
      }
    }

    console.log("this.props", this.props);
    console.log("this.state.product", this.state.product);

    

    return (
      <div>
        <Header location={this.props.location} />
        <BreadCrumbs>
          <PrettyLink
            to={"/products/category/" + this.state.product.category.name}
          >
            {this.state.product.category.name_pretty}
          </PrettyLink>{" "}
          > {this.state.product.name_pretty}
        </BreadCrumbs>

        {this.state.product.view == "default" ? (
          <Grid container spacing="32">
            <Grid item sm={6} xs={12}>
              <ImageViewer
                images={this.state.product.images.product_page}
                current={this.state.current_image}
                handleClick={this.handleThumbnailClick.bind(this)}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="h5" gutterBottom={true}>
                {this.state.product.name_pretty}
              </Typography>

              <ProductDescription product={this.state.product} />

              <ProductOptions
                options={this.state.product.options}
                values={this.state.options}
                handleSelectChange={this.handleSelectChange.bind(this)}
                handleTextChange={this.handleTextChange.bind(this)}
              />

              <Grid container spacing={0} style={{ marginTop: "20px" }}>
                
                <Grid
                  item
                  xs={6}
                  style={{ textAlign: "center", marginTop: "10px" }}
                >
                    <ProductPrice price={this.state.price} />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ textAlign: "center", verticalAlign: "center" }}
                >
                  <ProductActions
                    handleSubmit={this.handleSubmit}
                    submitting={this.state.submitting}
                    allowed={this.state.required_options_set}
                    display_sold={this.state.product.display_sold}
                    spinner={this.state.spinner}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Fragment>
            <this.state.view
              product={this.state.product}
              productOld={this.state.productBackUp}
              price={this.state.price}
              values={this.state.options}
              images={this.state.product.images.product_page}
              current={this.state.current_image}
              handleClick={this.handleThumbnailClick.bind(this)}
              handleSelectChange={this.handleSelectChange.bind(this)}
              handleTextChange={this.handleTextChange.bind(this)}
              handleSubmit={this.handleSubmit}
              handleSubmitOnePageOrder={this.handleSubmitOnePageOrder}
              submitting={this.state.submitting}
              option={this.props.match.params.option}
              history={this.props.history}
              user={this.state.user}
              enqueueSnackbar={this.props.enqueueSnackbar}
              handleOptionsChanged={this.handleOptionsChanged.bind(this)}
              handleImagesChanged={this.handleImagesChanged.bind(this)}
              allowed={
                this.state.required_options_set && this.state.allowed_to_add
              }
              spinner={this.state.spinner}
              handleAllowedToAddChange={this.handleAllowedToAddChange.bind(
                this
              )}
              handleBuyAllSwatchesWithDiscount={
                this.handleBuyAllSwatchesWithDiscount
              }
            />
          </Fragment>
        )}

        <Dialog
          open={this.state.show_login}
          onClose={this.handleLoginClose.bind(this)}
          aria-labelledby="form-dialog-title"
        >
          {/*<DialogTitle id="form-dialog-title">Subscribe</DialogTitle>*/}
          <DialogContent>
            <Grid container spacing={32}>
              <Grid item sm={6} xs={12}>
                <Typography variant="h5">Shop As Guest</Typography>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Email Address"
                  type="email"
                  fullWidth
                  variant="filled"
                  onChange={this.handleGuestEmailChange.bind(this)}
                />

                <FormControlLabel
                  control={<Checkbox value="true" />}
                  label="Be the first to know about exclusive offers, new products, helpful tips, &amp; more. "
                  onClick={this.handleGuestMarketingChange.bind(this)}
                />

                <Button
                  variant="contained"
                  onClick={this.handleLoginGuest}
                  style={{ clear: "both", float: "right" }}
                >
                  Go
                </Button>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography variant="h5">I Have An Account</Typography>

                <Typography>
                  Are you a professional photographer?
                  <br />
                  <Link
                    href={
                      (process.env.REACT_APP_LOGIN_URL
                        ? process.env.REACT_APP_LOGIN_URL
                        : "/mykiss/auth/login") + "/show/existing"
                    }
                  >
                    Create a free account!
                  </Link>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </Typography>

                <Button
                  component={Link}
                  variant="contained"
                  href={
                    process.env.REACT_APP_LOGIN_URL
                      ? process.env.REACT_APP_LOGIN_URL
                      : "/mykiss/auth/login"
                  }
                  style={{ float: "right" }}
                >
                  Log In
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
          {/*<DialogActions>
            <Button onClick={this.handleLoginClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleLoginClose} color="primary">
              Subscribe
            </Button>
          </DialogActions>*/}
        </Dialog>
        <DialogFreeSwatches
          showModal={this.state.order_once}
          handleShowModal={() => this.setState({ order_once: false })}
          handleBuy={this.handleBuyAllSwatchesWithDiscount}
          title="Hey Kiss Customer! Find something you like?"
          submitBtnText="Get all 50+ swatches for 50% right now!"
        >
          There are a lot of colors and textures to love! Free swatches are only
          available to those that have not ordered from Kiss, but you can get
          the complete set of 50+ swatches for 50% off right now. It's the
          lowest price you'll ever find them.
          <br />
          <br />
          <b>Want more swatches? Get all 50+ swatches for 50% off right now!</b>
        </DialogFreeSwatches>
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbar(Product));
