import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import AddressForm from '../components/addressForm';

function CheckoutShippingAddress(props){
    const {handleSubmit} = props;

    const [countryOptions, setCountryOptions] = useState(false);
    const steps = ['Shipping Address','Payment Method','Review Order'];
    const [activeStep, setActiveStep] = useState(0);

    return (
        <div>
            <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map(label => (
                <Step key={label}>
                <StepLabel>{label}</StepLabel>
                </Step>
            ))}
            </Stepper>
            
            <Grid container justify='center'>
                <Grid item sm={6} xs={12}>
                    <Typography variant='h6'>Shipping Address</Typography>
                    <AddressForm type='shipping' address={{country:'United States', state:'Alabama'}} addresses={[]} handleSubmit={handleSubmit} cancel='Back To Cart' submit='Next'/>
                </Grid>
            </Grid>
            
        </div>       
    )
}

export default CheckoutShippingAddress;