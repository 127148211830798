import React, { useState } from 'react';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@material-ui/core';
import companyTheme from '../../theme/companyBaseTheme';

function SmallOption(props){

    const {name, note, buttonText, handleButton, added, background, disabled} = props;        
    const [code, setCode] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    function handler(e){
       
        e.preventDefault();
        console.log('stopped default',e,code);
       
    }

    return (        
        
        <div style={{
            border:'1px '+(added?'solid '+companyTheme.palette.primary.main:'dashed #e8dfce'),
            height:'72px',
            background
            }}>
            <Grid container>
                <Grid item xs={8}>
                    <Typography variant={'h6'} style={added?{padding:'20px 0px 0px 80px'}:{padding:'20px 0px 0px 10px'}}>{name}<Typography variant='caption' style={{display:'inline',marginLeft:'10px'}}>{!added && note}</Typography></Typography>
                    
                </Grid>
                <Grid item xs={4} style={{textAlign:'right'}}>
                    <Tooltip title='First, add a Cover &amp; Spreads' disableFocusListener={!disabled} disableHoverListener={!disabled} disableTouchListener={!disabled}>
                        <span>
                        <Button disabled={disabled} onClick={handleButton} style={{margin:'18px 20px 0px 0px'}}>{buttonText}</Button> 
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
            
                   
        </div>
       
       
    );

}

export default SmallOption;