import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#fdf2dd',
      main: '#cabfab',          
      dark: '#998f7c',
      contrastText: '#000',
    },
    secondary: {
      light: '#aeaba6',
      main: '#7f7c77',  
      dark: '#53504b',   
      contrastText: '#fff',       
    },
    error: {
      light: '#ef9a9a',
      main: '#e57373',
      dark: '#ef5350',
    },
    background: {
      default: '#fff',
    },
  },
  
  text:{
    primary: '#fdf2dd',
    secondary: '#cabfab',                  
  },
  typography: {
    useNextVariants: true,
    fontFamily: ['Montserrat','Cormorant Garamond','Courier', 'Helvetica'].join(','),
    fontSize: 11,
    caption:{
      color: '#333',
    },
    label:{
      color: '#333',
    },
    body1:{
      color: '#333',
    },
    body2:{
      color: '#333',
    },
    subtitle1:{
      color: '#333',
    },
    subtitle2:{
      color: '#333',
    },
    h1: {
      fontFamily: 'Cormorant Garamond',
      color: '#cabfab'
    },
    h2: {
      fontFamily: 'Cormorant Garamond',
      color: '#cabfab'
    },
    h3: {
      fontFamily: 'Cormorant Garamond',
      color: '#cabfab'
    },
    h4: {
      fontFamily: 'Cormorant Garamond',
      color: '#cabfab'
    },
    h5: {
      fontFamily: 'Cormorant Garamond',
      color: '#cabfab'
    },
    h6: {
      fontFamily: 'Cormorant Garamond',
      color: '#cabfab'
    }
  },
  overrides:{
    Link: {

    //color: '#cabfab',

    },
    MuiInputBase: {
      disabled: {
        opacity:'.25'
      }
    }
  }
});

export default theme;