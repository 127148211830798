import React, { useState, Fragment, useEffect } from 'react';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FilledInput from '@material-ui/core/FilledInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import { Typography } from '@material-ui/core';
import LargeOption from './LargeOption.js';
import SmallOption from './SmallOption.js';
import companyTheme from '../../theme/companyBaseTheme';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function CoverOptions(props){

    //handlers
    const {handleApply, handleCancel} = props;

    console.log('coveroptions props',props);

    //state hooks, using the props as the defaults and control-values     
    const [material, setMaterial] = useState(props.material);
    const [colorLine, setColorLine] = useState(props.colorLine);
    const [cameo, setCameo] = useState(props.cameo ? props.cameo : false);
    const [debossing, setDebossing] = useState(props.debossing ? props.debossing : false);
    const [debossingSize, setDebossingSize] = useState(props.debossingSize);
    const [engraving, setEngraving] = useState(props.engraving ? props.engraving : false);
    const [faceoffDebossing, setFaceoffDebossing] = useState(props.faceoffDebossing ? props.faceoffDebossing : false);
    //const [faceoffDebossingSize, setFaceoffDebossingSize] = useState(props.faceoffDebossingSize);


    const [materialOptions, setMaterialOptions] = useState();

    let stateHooks={
        setMaterial,
        setColorLine,
        setCameo,
        setEngraving,
        setDebossing,
        setFaceoffDebossing,
        setDebossingSize
        //setFaceoffDebossingSize
    }

    function handleSwitchChange(event){
        console.log('event',event,event.target,event.target.name,event.target.checked);
        var name = event.target.name;
        let setter = 'set'+name.charAt(0).toUpperCase() + name.slice(1);
        console.log('setter',setter);
        stateHooks[setter](event.target.checked?true:false);
    };
    
    function handleSelectChange(event){
        var name = event.target.name;   
        console.log('select change. setting',name,event.target.value);
        //this.setState({[name]: event.target.value});    
        let setter = 'set'+name.charAt(0).toUpperCase() + name.slice(1);
        console.log('setter',setter);
        stateHooks[setter](event.target.value);
    };

    function handleSubmit(e){
        handleApply({
            material,
            colorLine,
            cameo,
            debossing,
            debossingSize,
            engraving,
            faceoffDebossing
        })
    }

    //watch for changes to material
    useEffect(()=>{
        //select the default for colorLine
        if(material=='leather'){
            if(colorLine!='distressed' && colorLine!='suede' && colorLine!='leather'){
                setColorLine('leather');
            }
            setMaterialOptions([
                <MenuItem value='distressed'>Distressed Leather</MenuItem>,
                <MenuItem value='suede'>Suede Leather</MenuItem>,
                <MenuItem value='leather'>Leather</MenuItem>
            ]);
        }else if(material=='linen'){
            if(colorLine!='fine' && colorLine!='silk' && colorLine!='linen'){
                setColorLine('linen');
            }
            if(props.product == 'kiss_book') {
                setMaterialOptions([
                    <MenuItem value='fine'>Fine Linen</MenuItem>,
                    <MenuItem value='silk'>Japanese Silk Linen</MenuItem>,
                    <MenuItem value='linen'>Select Linen</MenuItem>
                ]);
            }
            else {
                setMaterialOptions([
                    <MenuItem value='linen'>Select Linen</MenuItem>
                ]);
            }

        }
    },[material])

    useEffect(()=>{
        if(engraving && debossing){
            console.log('turning off debossing');
            setDebossing(false);
        }
    },[engraving])

    useEffect(()=>{
        if(engraving && debossing){
            console.log('turning off engraving');
            setEngraving(false);
        }
    },[debossing])

    return (
    
        <Dialog open={true} maxWidth='sm' fullWidth onClose={handleCancel}>
            <DialogTitle id="form-dialog-title">Cover Options</DialogTitle>
            <DialogContent>               
                
                
                <FormControl variant='filled' margin='normal' fullWidth>
                    <InputLabel>Cover Material</InputLabel>
                    <Select
                    value={material}
                    onChange={handleSelectChange}
                    
                    input={<FilledInput name="material" labelId='material'/>}
                    >
                        {props.product == 'kiss_book' &&
                            <MenuItem value='leather'>Leather</MenuItem>
                        }
                        <MenuItem value='linen'>Linen</MenuItem>
                    </Select>
                </FormControl><br/>

                
                <FormControl variant='filled' margin='normal' fullWidth>
                    <InputLabel htmlFor="colorLine">Cover Color Line</InputLabel>
                    <Select
                    value={colorLine}                    
                    onChange={handleSelectChange}
                    input={<FilledInput name="colorLine" id="colorLine" />}
                    >                       
                        {materialOptions}
                    </Select>
                </FormControl><br/>
                

                <Grid container>
                    <Grid item xs={9} style={{paddingTop:'12px'}}><FormLabel><Typography>Cover Cameo</Typography></FormLabel></Grid>
                    <Grid item xs={3} style={{textAlign:'right'}}><Switch
                    name='cameo'                    
                    checked={cameo}
                    onChange={handleSwitchChange}
                    size="small"
                    value={true}
                    color="primary"
                    /></Grid>
                </Grid>

                {props.product == 'kiss_book' &&
                    <Grid container>
                        <Grid item xs={9} style={{paddingTop: '12px'}}>
                            <FormLabel>
                                <Typography>Cover Debossing</Typography>
                            </FormLabel>
                        </Grid>
                        <Grid item xs={3} style={{textAlign: 'right'}}>
                            <Switch
                                name='debossing'
                                checked={debossing}
                                onChange={handleSwitchChange}
                                size="small"
                                value={true}
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                }

                    {debossing &&
                        <FormControl variant='filled' margin='normal' fullWidth>
                        <InputLabel htmlFor="faceoffDebossingSize">Debossing Size</InputLabel>
                        <Select
                        value={debossingSize}
                        onChange={handleSelectChange}
                        input={<FilledInput name="debossingSize" id="debossingSize"/>}
                        >

                        <MenuItem value='5x1'>5"x1"</MenuItem>
                        <MenuItem value='5x2'>5"x2"</MenuItem>
                        <MenuItem value='5x4'>5"x4"</MenuItem>

                        </Select>
                        </FormControl>
                    }


                <Grid container>
                    <Grid item xs={9} style={{paddingTop:'12px'}}><FormLabel><Typography>Cover Engraving</Typography></FormLabel></Grid>
                    <Grid item xs={3} style={{textAlign:'right'}}><Switch
                    name='engraving'                    
                    checked={engraving}
                    onChange={handleSwitchChange}
                    size="small"
                    value={true}
                    color="primary"
                    /></Grid>
                </Grid>

                {props.product == 'kiss_book' &&
                    <Grid container>
                        <Grid item xs={9} style={{paddingTop: '12px'}}>
                            <FormLabel>
                                <Typography>Inside-Cover Debossing</Typography>
                            </FormLabel>
                        </Grid>
                        <Grid item xs={3} style={{textAlign: 'right'}}>
                            <Switch
                                name='faceoffDebossing'
                                disabled={!material || !colorLine}
                                checked={faceoffDebossing}
                                onChange={handleSwitchChange}
                                size="small"
                                value={true}
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                }

              
                {/*faceoffDebossing &&
                <FormControl variant='filled' margin='normal' fullWidth>
                    <InputLabel htmlFor="faceoffDebossingSize">Inside-Cover Debossing Size</InputLabel>
                    <Select
                    value={faceoffDebossingSize}
                    disabled={!material || !colorLine}
                    onChange={handleSelectChange}
                    input={<FilledInput name="faceoffDebossingSize" id="faceoffDebossingSize" />}
                    >                       
                    
                        <MenuItem value='5x1'>5"x1"</MenuItem>
                        <MenuItem value='5x2'>5"x2"</MenuItem>
                        <MenuItem value='5x4'>5"x4"</MenuItem>
                                    
                    </Select>
                </FormControl>
                */}

            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} >
                Cancel
                </Button>
                <Button onClick={handleSubmit} disabled={!material || !colorLine} variant='contained'>
                Apply
                </Button>
            </DialogActions>
        </Dialog>       
       
        );
    
    }
    
    export default CoverOptions;