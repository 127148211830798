import React, { useState, Fragment, useEffect } from 'react';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FilledInput from '@material-ui/core/FilledInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import { Typography } from '@material-ui/core';
import LargeOption from './LargeOption.js';
import SmallOption from './SmallOption.js';
import companyTheme from '../../theme/companyBaseTheme';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PopUp from "../PopUp";

function SpreadOptions(props){

    //handlers
    const {handleApply, handleCancel} = props;

    //state hooks, using the props as the defaults and control-values     
    const [spreads, setSpreads] = useState(props.spreads);
    const [paperType, setPaperType] = useState(props.paperType);
    const [pageThickness, setPageThickness] = useState(props.pageThickness);
    const [spreadCountOptions, setSpreadCountOptions] = useState([]);
    const [pageTypeOptions, setPageTypeOptions] = useState([]);
    const [pageThicknessOptions, setPageThicknessOptions] = useState([]);
    const [sizeOptions, setSizeOptions] = useState([]);
    const [size, setSize] = useState(props.size);
    const [show_thinkness_popup, set_show_thinkness_popup] = useState(false);

    let stateHooks={
        setSpreads,
        setPaperType,
        setPageThickness,
        setSize
    }

    function handleSwitchChange(event){
        console.log('event',event,event.target,event.target.name,event.target.checked);
        var name = event.target.name;
        let setter = 'set'+name.charAt(0).toUpperCase() + name.slice(1);
        console.log('setter',setter);
        stateHooks[setter](event.target.checked);
    };
    
    function handleSelectChange(event){
        var name = event.target.name;
        var value = event.target.value;
        if (name == 'pageThickness' && value == 'thick' && size == '4') {
            set_show_thinkness_popup(true);
            stateHooks.setPageThickness("thin");
        }
        else {
            //this.setState({[name]: event.target.value});
            let setter = 'set'+name.charAt(0).toUpperCase() + name.slice(1);
            stateHooks[setter](event.target.value);
        }
    };

    function handleSubmit(e){
        handleApply({
            spreads,
            paperType,
            pageThickness,       
            size     
        })
    }
   

    //on load
    useEffect(()=>{
        var out = [];
        var i = 0;
        for(i;i<31;i++){
            out.push(<MenuItem value={i+10}>{i+10}</MenuItem>);
            
        }
        setSpreadCountOptions(out);
        console.log('setSpreadCountOptions',out);


        var pageTypes = [];
        if (props.product == 'kiss_book') {
            pageTypes.push(<MenuItem value='luster'>Luster</MenuItem>);
            pageTypes.push(<MenuItem value='matte'>Matte</MenuItem>);
        }
        else {
            pageTypes.push(<MenuItem value='press_printed'>Press Printed</MenuItem>);
        }
        setPageTypeOptions(pageTypes)

        var pageThickness = [];
        if (props.product == 'kiss_book') {
            pageThickness.push(<MenuItem value='thick'>Thick</MenuItem>);
            pageThickness.push(<MenuItem value='thin'>Thin</MenuItem>);
        }
        else {
            pageThickness.push(<MenuItem value='thin'>Thin</MenuItem>);
        }
        setPageThicknessOptions(pageThickness)

        var size = [];
        if (props.product == 'kiss_book') {
            size.push(<MenuItem value='4' disabled={pageThickness=='thick'}>4"</MenuItem>);
            size.push(<MenuItem value='8'>8"</MenuItem>);
            size.push(<MenuItem value='10'>10"</MenuItem>);
            size.push(<MenuItem value='12'>12"</MenuItem>);
        }
        else {
            size.push(<MenuItem value='4' disabled={pageThickness=='thick'}>4"</MenuItem>);
            size.push(<MenuItem value='8'>8"</MenuItem>);
            size.push(<MenuItem value='10'>10"</MenuItem>);
        }
        setSizeOptions(size)

    },[]);







    return (

        <Dialog open={true} maxWidth='sm' fullWidth onClose={handleCancel}>
            <DialogTitle id="form-dialog-title">Spread Options</DialogTitle>
            <DialogContent>
                
                <FormControl variant='filled' margin='normal' fullWidth>
                    <InputLabel>How Many Spreads</InputLabel>
                    <Select
                    value={spreads}
                    onChange={handleSelectChange}                    
                    input={<FilledInput name="spreads" labelId='spreads'/>}
                    >                          
                        {spreadCountOptions}                       
                       
                    </Select>
                </FormControl><br/>

                
                <FormControl variant='filled' margin='normal' fullWidth>
                    <InputLabel htmlFor="paperType">Paper Type</InputLabel>
                    <Select
                    value={paperType}
                    onChange={handleSelectChange}
                    input={<FilledInput name="paperType" id="paperType" />}
                    >
                        {pageTypeOptions}
                    </Select>
                </FormControl><br/>

                <FormControl variant='filled' margin='normal' fullWidth>
                    <InputLabel htmlFor="pageThickness">Page Thickness</InputLabel>
                    <Select
                    value={pageThickness}
                    onChange={handleSelectChange}
                    input={<FilledInput name="pageThickness" id="pageThickness" />}
                    >
                        {pageThicknessOptions}
                    </Select>
                </FormControl><br/>

                <FormControl variant='filled' margin='normal' fullWidth>
                    <InputLabel>Album Size</InputLabel>
                    <Select
                    value={size}
                    onChange={handleSelectChange}
                    
                    input={<FilledInput name="size" labelId='size'/>}
                    >                          
                        {sizeOptions}
                    </Select>
                </FormControl><br/>
                


            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} >
                Cancel
                </Button>
                <Button onClick={handleSubmit} disabled={!spreads || !paperType || !pageThickness} variant='contained'>
                Apply
                </Button>
            </DialogActions>
            <PopUp
                show={show_thinkness_popup}
                message={'4”x4” albums are only available with thin pages. We’ve reverted your page selection for you.'}
                setShow={set_show_thinkness_popup}
            >
            </PopUp>
        </Dialog>
        );
    
    }
    
    export default SpreadOptions;