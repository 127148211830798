import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FilledInput from '@material-ui/core/FilledInput';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import { Typography } from '@material-ui/core';
import LargeOption from './LargeOption.js';
import SmallOption from './SmallOption.js';
import companyTheme from '../../theme/companyBaseTheme';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ProductOptions from './ProductOptions';
import CoverOptions from './CoverOptions';
import SpreadOptions from './SpreadOptions';
import BoxOptions from './BoxOptions';
import formatCurrency from '../../utils/formatCurrency';

function BookCard(props){

    const {name, options, handleTotalChange, albumPricing, boxPricing, num, price, handleOptionChange, handleWeightChange, weight} = props;        
    const [coverOptionsOpen, setCoverOptionsOpen] = useState(false);
    const [productOptionsOpen, setProductOptionsOpen] = useState(false);
    const [spreadOptionsOpen, setSpreadOptionsOpen] = useState(false);
    const [boxOptionsOpen, setBoxOptionsOpen] = useState(false);    
    const [costs, setCosts] = useState({});        
    const [msrp, setMsrp] = useState(0);        

    const [bookCoverOptions, setBookCoverOptions] = useState(options ? {
        material: options.material,
        colorLine: options.colorLine,
        cameo: options.cameo,
        debossing: options.debossing,
        debossingSize: options.debossingSize,
        engraving: options.engraving,
        faceoffDebossing: options.faceoffDebossing
     } : {});
    const [bookProductOptions, setBookProductOptions] = useState(options ? {
        product: options.product,
    } : {});
    const [bookSpreadOptions, setBookSpreadOptions] = useState(options ? {
        spreads: options.spreads,
        paperType: options.paperType,
        pageThickness: options.pageThickness,
        size: options.size,   
    } : {});
    const [boxOptions, setBoxOptions] = useState(options && options.addons && options.addons.box ?  options.addons.box : {});
    const [dustCover, setDustCover] = useState(options && options.addons ? options.addons.dustCover : false);
    const [giftWrap, setGiftWrap] = useState(options && options.addons ? options.addons.giftWrap : false);
    const [sample, setSample] = useState(options && options.sample ? options.sample : false);   
    
    function handleCoverAddClick(){       
        setCoverOptionsOpen(!coverOptionsOpen);
    }

    function handleProductAddClick(){
        setProductOptionsOpen(!productOptionsOpen);
    }

    function setBackground(type) {
        let background = '';
        let suf =  (bookProductOptions.product == 'press_printed_book') ? '_pp' : '';

        switch (type) {
            case 'product' : {
                background = (bookProductOptions.product) ? 'url(/images/calculator/calculator_product'+suf+'.png) top left no-repeat' : '';
                break;
            }
            case 'cover' : {
                background = (bookCoverOptions.material) ? 'url(/images/calculator/calculator_cover'+suf+'.png) top left no-repeat' : '';
                break;
            }
            case 'spreads' : {
                background = (bookSpreadOptions.size) ? 'url(/images/calculator/calculator_spreads'+suf+'.png) top left no-repeat' : '';
                break;
            }
        }
        return background;
    }

    function handleSpreadsAddClick(){
        setSpreadOptionsOpen(!spreadOptionsOpen);
    }

    function handleDustCoverAddClick(){
        setDustCover(!dustCover);
    }

    function handleBoxAddClick(){
        setBoxOptionsOpen(!boxOptionsOpen);
    }

    function handleGiftWrapAddClick(){
        setGiftWrap(!giftWrap);
    }

    function handleApplyCover(options){

        //set options
        setBookCoverOptions(options);
        //console.log('setBookCoverOptions',bookCoverOptions, options);
     
        //close dialog
        setCoverOptionsOpen(false);
    }

    function handleCancelCover(){                

        //close dialog
        setCoverOptionsOpen(false);
    }

    function handleApplyProduct(options){
        if (options.product == 'press_printed_book') {
            options.material = 'linen';
            options.colorLine = 'linen';
            options.debossing = false;
            options.faceoffDebossing = false;
            options.paperType = 'press_printed';
            options.pageThickness = 'thin';
        }

        //set options
        setBookProductOptions(options);

        //close dialog
        setProductOptionsOpen(false);
    }

    function handleCancelProduct(){

        //close dialog
        setProductOptionsOpen(false);
    }

    function handleApplySpreads(options){

        //set options
        setBookSpreadOptions(options);
        console.log('setBookSpreadOptions',bookSpreadOptions,options);       

        //close dialog
        setSpreadOptionsOpen(false);
    }

    function handleCancelSpreads(){                

        //close dialog
        setSpreadOptionsOpen(false);
    }
    
    function handleApplyBox(options){

        //set options
        setBoxOptions(options);
        console.log('setBoxOptions',boxOptions,options);       

        //close dialog
        setBoxOptionsOpen(false);
    }

    function handleCancelBox(){                

        //close dialog
        setBoxOptionsOpen(false);
    }

    function calculateCosts(){
        
        let pricing = albumPricing;

        /* PRICING */
        var base = 0;    

        //make sure we have required options
        console.log('bookSpreadOptions',bookSpreadOptions,'bookCoverOptions',bookCoverOptions);
        if(!(bookSpreadOptions.size && bookSpreadOptions.pageThickness && bookCoverOptions.material && bookCoverOptions.material &&bookCoverOptions.colorLine)){
            setCosts({});
            return;
        }
      
        console.log('state options',bookCoverOptions,bookSpreadOptions);
        //console.log('bookCoverOptions.size',bookSpreadOptions.size,'bookSpreadOptions.pageThickness',bookSpreadOptions.pageThickness,'bookCoverOptions.material',bookCoverOptions.material);
        console.log('pricing[bookSpreadOptions.size]',pricing[bookSpreadOptions.size]);
        console.log('pricing[bookSpreadOptions.size][bookSpreadOptions.pageThickness]',pricing[bookSpreadOptions.size][bookSpreadOptions.pageThickness]);
        console.log('pricing[bookSpreadOptions.size][bookSpreadOptions.pageThickness][bookCoverOptions.material]',pricing[bookSpreadOptions.size][bookSpreadOptions.pageThickness][bookCoverOptions.material]);

        if (bookSpreadOptions.paperType == "press_printed") {
            //base price
            var base_price = pricing[bookSpreadOptions.size][bookSpreadOptions.pageThickness]["press_printed_"+bookCoverOptions.material];
        }
        else {
            //base price
            var base_price = pricing[bookSpreadOptions.size][bookSpreadOptions.pageThickness][bookCoverOptions.material];
        }

        
        //add price for special color
        var colorLineName = bookCoverOptions.colorLine.toLowerCase().replace(' ','_');
        console.log('colorLineName', colorLineName);
        var color_price = pricing.color_line[colorLineName];           
        console.log('color_price', color_price);

        //add any base increase for the paper type
        var paper_price = pricing[bookSpreadOptions.paperType];
        console.log('add any base increase for the paper type',paper_price);
        
        //add price of any spreads beyond the 10 that are included
        var spreads_price = pricing[bookSpreadOptions.size][bookSpreadOptions.pageThickness][bookSpreadOptions.paperType] * (bookSpreadOptions.spreads - 10);
        console.log('add price of any spreads beyond the 10 that are included',spreads_price);

        //cameo, engraving, and debossing
        var cameo_price = (bookCoverOptions.cameo === true ? pricing.cameo : 0);    
        console.log('cameo',cameo_price);
        var engraving_price = (bookCoverOptions.engraving === true ? pricing.engraving : 0);       
        console.log('engraving',engraving_price);
        var debossing_price = (bookCoverOptions.debossing === true ? pricing.debossing[bookCoverOptions.debossingSize] : 0);       
        console.log('debossing_price',debossing_price,'bookCoverOptions.debossingSize',bookCoverOptions.debossingSize);
        var faceoff_debossing_price = (bookCoverOptions.faceoffDebossing === true ? pricing.faceoff_debossing : 0);       
        console.log('faceoff_debossing_price',faceoff_debossing_price,'bookCoverOptions.faceoffDebossing',bookCoverOptions.faceoffDebossing);
       
        //add these ^ to the base price
        console.log('add these to the base price',base_price,color_price,paper_price,spreads_price,cameo_price,engraving_price,debossing_price,faceoff_debossing_price);
        base = base_price+color_price+paper_price+spreads_price+cameo_price+engraving_price+debossing_price+faceoff_debossing_price;
        console.log('subtotal',base);
        //if sample, give the discount
        if(sample){                    
            base = base*.75;
            console.log('sample_price',base);
        }
        console.log('total',base);

        //set book cost
        let costs = {
            book:base
        }     

        //Addons
        costs.giftWrap = (giftWrap ? 35 : 0);
        costs.dustCover = 0;

        costs.box = 0;
        if(boxOptions.type=='walnut'){
            console.log('setting box pricing for size',boxOptions.size,'which is',boxPricing['walnut'][boxOptions.size])
            costs.box += boxPricing['walnut'][boxOptions.size];
            costs.box += (boxOptions.engraving ? boxPricing.engraving : 0);
            costs.box += (boxOptions.logoEngraving ? boxPricing.logo_engraving : 0);
        }else if(boxOptions.type=='linen'){
            costs.box += boxPricing['linen'][boxOptions.size];
            costs.box += (boxOptions.engraving ? boxPricing.engraving : 0);
            if(boxOptions.debossing && boxOptions.debossingSize){
                costs.box += boxPricing.debossing[boxOptions.debossingSize];
            }            
            costs.box += (boxOptions.logoDebossing ? boxPricing.logo_debossing : 0);
        }else if(boxOptions.type=='presentation'){
            costs.box += boxPricing['presentation'][boxOptions.size];
            costs.box += (boxOptions.engraving ? boxPricing.engraving : 0);
        }   
       
        
        //if sample, give the discount   
        if(sample){                  
            costs.giftWrap = (costs.giftWrap * .75);
            costs.box = (costs.box * .75);
        }
        console.log('costs',costs);

        //set costs
        setCosts(costs);

        //set msrp     
        let temp_msrp = 0;   
        if(sample){
            temp_msrp += costs.book+costs.giftWrap+costs.dustCover+costs.box;
        }else{
            let book_msm = 3.25;//225%
            let addon_msm = 2;//100%
            temp_msrp += costs.book * book_msm;
            temp_msrp += costs.giftWrap * addon_msm;
            temp_msrp += costs.box * addon_msm;

        }
      
        setMsrp(temp_msrp);        
        
    }

    function calculateWeight(){


        var weight = {
            '4': {
                'cover': {         
                    'leather': .35,
                    'linen': .3
                },
                'spreads': {
                    'luster': {
                        'thin': .0105,
                        'thick': false
                    },
                    'matte': {
                        'thin': .0105,
                        'thick': false 
                    },
                    'press_printed': {
                        'thin': .0105,
                        'thick': false
                    }
                }
            },
            '8': {
                'cover': {         
                    'leather': 1.5,
                    'linen': .95
                },
                'spreads': {
                    'luster': {
                        'thin': .0446,
                        'thick': .1155
                    },
                    'matte': {
                        'thin': .0446,
                        'thick': .1155 
                    },
                    'press_printed': {
                        'thin': .0446,
                        'thick': .1155
                    }
                }       
            },
            '10': {
                'cover': {         
                    'leather': 2.35,
                    'linen': 1.4
                },
                'spreads': {
                    'luster': {
                        'thin': .0709,
                        'thick': .1785
                    },
                    'matte': {
                        'thin': .0709,
                        'thick': .1785 
                    },
                    'press_printed': {
                        'thin': .0709,
                        'thick': .1785
                    }
                }       
            },
            '12': {
                'cover': {         
                    'leather': 3.3,
                    'linen': 2.05
                },
                'spreads': {
                    'luster': {
                        'thin': .1024,
                        'thick': .2572
                    },
                    'matte': {
                        'thin': .1024,
                        'thick': .2572 
                    },
                    'press_printed': {
                        'thin': .1024,
                        'thick': .2572
                    }
                }       
            }
        };
        
    
    
        /* WEIGHT */
    
    
        //make sure we have required options
        console.log('bookSpreadOptions',bookSpreadOptions,'bookCoverOptions',bookCoverOptions);
        if(!(bookSpreadOptions.size && bookSpreadOptions.pageThickness && bookCoverOptions.material && bookCoverOptions.material &&bookCoverOptions.colorLine)){
            handleWeightChange(num, 0);
            return;
        }

        //base pounds
        var pounds = 0;
        pounds += weight[bookSpreadOptions.size]['cover'][bookCoverOptions.material];
        
        //spreads > 10 pounds
        console.log('weight calc',weight[bookSpreadOptions.size]);
        console.log(weight[bookSpreadOptions.size]['spreads']);
        console.log(weight[bookSpreadOptions.size]['spreads'][bookSpreadOptions.paperType]);
        console.log(weight[bookSpreadOptions.size]['spreads'][bookSpreadOptions.paperType][bookSpreadOptions.pageThickness]);
        var wps = weight[bookSpreadOptions.size]['spreads'][bookSpreadOptions.paperType][bookSpreadOptions.pageThickness];
        pounds += wps * bookSpreadOptions.spreads;   
        
        //dust cover, boxes, and gift wrap
        //pounds += (product_options.dust_cover == 'true' ? weight['dust_cover'] : 0);		
        
        handleWeightChange(num, pounds);


    }

    //watch for changes to material
    useEffect(()=>{
        handleTotalChange(num, costs);
    },[costs]);

    //watch for having both cover and spread options chosen
    useEffect(()=>{

        //if the book is linen 4" then disable/remove box option
        if(bookCoverOptions.material=='linen' && bookSpreadOptions.size=='4' && boxOptions.type=='linen'){
            setBoxOptions({});
        }

        //if the book is changing to leather cover and has linen box, remove the box
        if(bookCoverOptions.material=='leather' && boxOptions.type=='linen'){
            setBoxOptions({});
        }

        //if the book is 4" and has walnut box, remove the box
        if(bookSpreadOptions.size=='4' && boxOptions.type=='walnut'){
            setBoxOptions({});
        }

        //if we need to, update the box size to the book size
        if(boxOptions.size && boxOptions.size!=bookSpreadOptions.size){
            setBoxOptions({...boxOptions, size:bookSpreadOptions.size});
        }

        console.log('in changed bookCoverOptions',bookCoverOptions,Object.keys(bookCoverOptions).length,Object.keys(bookSpreadOptions).length);
        if(Object.keys(bookCoverOptions).length>0 && Object.keys(bookSpreadOptions).length>0 && Object.keys(bookProductOptions).length>0){
            console.log('calculateCosts');
            calculateCosts();
            calculateWeight();
        }

        //update parent handler
        handleOptionChange(num, Object.assign(bookCoverOptions,bookSpreadOptions,bookProductOptions,{sample}));
        
    },[bookCoverOptions, bookSpreadOptions, bookProductOptions, sample]);

    //watch
    useEffect(()=>{        
        if(Object.keys(bookCoverOptions).length>0 && Object.keys(bookSpreadOptions).length>0 && Object.keys(bookProductOptions).length>0){
            console.log('calculateCosts');
            calculateCosts();
            calculateWeight();
        }
        
        //update parent handler
        let addons = {dustCover, giftWrap};
        if(boxOptions.type){
            addons.box = boxOptions;
        }
        handleOptionChange(num, {addons});

    },[dustCover, boxOptions, giftWrap]);
   

    return (        
        
        <div style={{border:'1px solid '+companyTheme.palette.primary.main, padding:'20px', marginBottom:'20px'}}>
            <Grid container alignItems='flex-start'>
                <Grid item xs={6}>
                    <Typography variant={'h6'} style={{marginBottom:'10px',paddingTop:'0px', marginTop:'-7px'}}>{name}</Typography>
                </Grid>    
                <Grid item xs={6} style={{textAlign:'right',marginTop:'-15px'}}>
                    <Tooltip title="Studio Samples are discounted 25%">
                        <FormControlLabel
                            control={
                            <Switch checked={sample} onChange={e=>setSample(e.target.checked)} value={true} size='small' />
                            }
                            label="Studio Sample"
                            labelPlacement='start'
                        />
                    </Tooltip>
                </Grid>    
            </Grid>

            <Grid container spacing={16}>
                <Grid item xs={3}>
                    {console.log('bookProductOptions',bookProductOptions)}
                    <LargeOption
                        name='Product'
                        description='Select product.'
                        buttonText='Edit Product'
                        handleButton={handleProductAddClick}
                        added={bookProductOptions.product}
                        background={setBackground('product')}
                    />
                </Grid>
                <Grid item xs={3}>
                    {console.log('bookCoverOptions',bookCoverOptions)}
                    <LargeOption 
                    name='Cover' 
                    description='Choose your book size, the cover material, engraving or debossing, and cameo.' 
                    buttonText={bookCoverOptions.material ? 'Edit Cover' : 'Add a Cover'}
                    handleButton={handleCoverAddClick}
                    added={bookCoverOptions.material}
                    background={setBackground('cover')}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LargeOption 
                    name='Spreads' 
                    description='Choose your spread thickness and paper type.' 
                    buttonText={bookSpreadOptions.size ? 'Edit Spreads' : 'Add Spreads'}
                    handleButton={handleSpreadsAddClick}
                    added={bookSpreadOptions.size}
                    background={setBackground('spreads')}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            <SmallOption 
                            disabled={!options.material || !options.spreads}
                            name='Dust Cover' 
                            note='(Optional)' 
                            buttonText={dustCover ? 'Remove' : 'Add'} 
                            added={dustCover} 
                            handleButton={handleDustCoverAddClick}
                            background={dustCover ? 'url(/images/calculator/calculator_slipcover.png) top left no-repeat' : ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SmallOption 
                            disabled={!options.material || !options.spreads}
                            name='Box' 
                            note='(Optional)' 
                            buttonText={boxOptions.type ? 'Edit' : 'Add'} 
                            added={boxOptions.type} 
                            handleButton={handleBoxAddClick}
                            background={boxOptions.type ? 'url(/images/calculator/calculator_box.png) top left no-repeat' : ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SmallOption 
                            disabled={!options.material || !options.spreads}
                            name='Gift Wrap' 
                            note='(Optional)' 
                            buttonText={giftWrap ? 'Remove' : 'Add'} 
                            added={giftWrap} 
                            handleButton={handleGiftWrapAddClick}
                            background={giftWrap ? 'url(/images/calculator/calculator_gift_wrap.png) top left no-repeat' : ''}
                            />
                        </Grid>                    
                    
                    </Grid>
                </Grid>
            </Grid>

            <Grid container style={{marginTop:'25px'}}>
                <Grid item xs={6}>
                    <Typography variant='caption'>
                        1. Select your product<br/>
                        2. Add a cover and choose your options<br/>
                        3. Add your spreads<br/>
                        4. Add optional add-ons<br/>
                        5. See the price<br/>
                        6. Toggle Pricing Breakdown to view cost, margin, and profit.<br/>
                    </Typography>

                </Grid>
                <Grid item xs={6} style={{textAlign:'right'}}>
                    <Typography variant='h4' style={{margin:'0px',padding:'0px'}}>{formatCurrency(msrp,true)}</Typography>
                    <Typography variant='caption'>{sample ? 'Studio Sample (25% Discount)' : 'Suggested MSRP'}</Typography>
                </Grid>
            </Grid>

            {productOptionsOpen &&
            <ProductOptions
                product={options.product ? options.product : 'kiss_book' }
                handleApply={handleApplyProduct}
                handleCancel={handleCancelProduct}/>
            }

            {coverOptionsOpen &&
            <CoverOptions
            product={options.product ? options.product : 'kiss_book' }
            /* material={options.material ? options.product=='kiss_book' ? options.material : 'linen' : 'leather' }*/
            material={options.material ? options.material : 'leather' }
            colorLine={options.colorLine ? options.colorLine : 'leather' }
            cameo={options.cameo ? options.cameo : false}
            engraving={options.engraving ? options.engraving : false}
            debossing={options.debossing ? options.debossing : false}
            debossingSize={options.debossingSize ? options.debossingSize : '5x2' }
            faceoffDebossing={options.faceoffDebossing ? options.faceoffDebossing : false }
            faceoffDebossingSize={options.faceoffDebossingSize ? options.faceoffDebossingSize : '5x2' }            
            handleApply={handleApplyCover} 
            handleCancel={handleCancelCover}/>
            }

            {spreadOptionsOpen &&
            <SpreadOptions
            product={options.product ? options.product : 'kiss_book' }
            spreads={options.spreads ? options.spreads : '10' }
            paperType={options.paperType ? options.paperType : 'luster' }
            pageThickness={options.pageThickness ? options.pageThickness : 'thick' }            
            size={options.size ? options.size : '10' }
            handleApply={handleApplySpreads} 
            handleCancel={handleCancelSpreads}/>           
            }

            {boxOptionsOpen &&
            <BoxOptions 
            type={options.addons && options.addons.box && options.addons.box.type ? options.addons.box.type : 'walnut'}
            bookCoverMaterial={bookCoverOptions.material ? bookCoverOptions.material : 'leather' }
            size={options.size} 
            engraving={options.addons && options.addons.box && options.addons.box.engraving ? options.addons.box.engraving : false}
            debossing={options.addons && options.addons.box && options.addons.box.debossing ? options.addons.box.debossing : false}
            debossingSize={options.addons && options.addons.box && options.addons.box.debossingSize ? options.addons.box.debossingSize : '5x2'}
            logoDebossing={options.addons && options.addons.box && options.addons.box.logoDebossing ? options.addons.box.logoDebossing : false}
            handleApply={handleApplyBox} 
            handleCancel={handleCancelBox}
            />
            }

            

        </div>
       
       
    );

}

export default BookCard;