import React, { Fragment, useState } from 'react'
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import PrettyLink from './prettyLink';
import formatCurrency from '../utils/formatCurrency';

function ShipMethod(props) {
    console.log(' totals list', props);
    const { methods, handleChange, handleSignatureChange, requireSignature, disabled, onSetSelectedMethod } = props;

    var selected;
    var selected_method;
    methods.map(t => t.methods.map(m => m.selected ? selected_method = { ...m, ...{ carrier: t.type } } : ''));
    console.log('method choices', methods);
    console.log('selected_method', selected_method);

    //handle cases where what they had selected for shipping method last time is not available for this cart contents or address
    const [hasSetDefault, setHasSetDefault] = useState(false);
    if (selected_method) {
        selected = selected_method.name;

        onSetSelectedMethod(selected);
        
    } else {
        if (!hasSetDefault && methods.length > 0) {
            selected = methods[0].methods[0].name;
            console.log('sending handlechange', selected);
            handleChange({ target: { value: selected } });
            setHasSetDefault(true);
        }
        return null;
    }
    console.log('shipping selected', selected);

    return (
        <Fragment>
            <Typography variant='subtitle1'>Shipping Method</Typography>
            <FormControl component="fieldset">

                <RadioGroup
                    aria-label="Shipping Method"
                    name="shipping_method"
                    value={selected}
                    onChange={handleChange}
                >
                    {methods.map(method => (

                        method.methods.map((m, i) => (

                            <FormControlLabel
                                value={m.name}
                                control={
                                    <Radio disabled={disabled} />
                                }
                                label={
                                    <Fragment>
                                        <span>{m.name_pretty + (m.name_pretty == 'Local Pickup' ? ' In Indianapolis' : '')}</span>
                                        <br />
                                        {m.days_estimate &&
                                            <Typography variant='caption'>
                                                {m.days_estimate} Business Day{m.days_estimate > 1 ? 's' : ''} <strong>{formatCurrency(m.cost)}</strong>
                                            </Typography>
                                        }
                                        {(!m.days_estimate) &&
                                            <Typography variant='caption'>
                                                <strong>{formatCurrency(m.cost)}</strong>
                                            </Typography>
                                        }
                                    </Fragment>
                                }
                                style={(selected == m.name ? {} : { opacity: '.25' })}
                            />
                        ))

                    ))}
                </RadioGroup>
                <br />
                {selected_method.carrier == 'Fedex' &&

                    <FormControlLabel
                        labelPlacement='end'
                        control={
                            <Checkbox value="true" />
                        }
                        label={<Typography variant='caption'>Require signature for shipment <strong>$4.75</strong></Typography>}
                        onClick={e => handleSignatureChange(e)}
                        checked={requireSignature}
                    />

                }

            </FormControl>

            {selected == 'LOCAL PICKUP' &&
                <Typography variant='caption'>
                    <b>Pick up at Kiss:</b><br />
                    Kiss Books<br />5470 W 84th St<br />Indianapolis, IN 46268<br />
                    <PrettyLink target='_blank' href='https://www.google.com/maps?hl=en&q=Kiss+Books,+5470+W+84th+St,+Indianapolis,+IN+46268,+USA'><i>View Map</i></PrettyLink>

                </Typography>
            }
        </Fragment>
    )
}

export default ShipMethod;