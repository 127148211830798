import React, {useState, Fragment} from 'react'
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import EditIcon from '@material-ui/icons/Create';
import AddressEditor from './addressEditor';

function ShipTo(props){
    console.log(' totals list',props);
    const {fields, handleSubmit, addresses, handleShipAddressRemove} = props;
    const [addressEditorOpen, setaddressEditorOpen] = useState(false);
    function handleAddressEditorClose(){
        setaddressEditorOpen(false);
    }

    return(
        <Fragment>
            <Typography variant='subtitle1'>Ship To <ButtonBase onClick={setaddressEditorOpen.bind(true)}><EditIcon fontSize='small'/></ButtonBase></Typography>            
            <Typography>
                {fields.name}<br/>
                {fields.company ? <Fragment>{fields.company}<br/></Fragment> : <Fragment></Fragment>}
                {fields.address}<br/>
                {fields.address_2 && 
                    <Fragment>{fields.address_2}<br/></Fragment>
                }
                {fields.city}, {fields.state} {fields.zip}<br/>
                {fields.country}<br/>
                {fields.phone}<br/>
            </Typography>
            <AddressEditor address={fields} addresses={addresses} type='shipping' open={addressEditorOpen} handleClose={handleAddressEditorClose} handleSubmit={handleSubmit} handleShipAddressRemove={handleShipAddressRemove}/>
        </Fragment>
    )
}

export default ShipTo;